import React from 'react';
import { withStyles, Typography} from '@material-ui/core';
import QuestionBoardChip from '../QuestionBoardChip/QuestionBoardChip'
import { getExtractName } from '../Utils/Utils'

const styles = theme => ({
  title:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  selector: {
    minWidth: 185,
    height:16,
    marginLeft:6,
    backgroundColor: '#EEE',
    padding:'10px 14px',
  },
})

const DataManagement = props => {

  const {
    classes,
		state,
		dispatch,
    history
  } = props;

  const dataUsageItems = [
    {
      title:getExtractName('0200_TABLE'),
      subtitle:'Usage metrics for all Tables',
      code:'0200_TABLE'
    },
    {
      title:getExtractName('0100'),
      subtitle:'Find all Tables with usage increasing/decreasing/stable in the last 90 days',
      code:'0100'
     },
    {
      title:getExtractName('0050'),
      subtitle:'Count of Query Signatures by users and teams per month',
      code:'0050'
    }
  ]

  const contentUsageItems = [
    {
      title:getExtractName('0200_CONTENT'),
      subtitle:'Usage metrics for all Reporting and Analytical Content',
      code:'0200_CONTENT'
    },
    {
      title:getExtractName('0350_CONTENT'),
      subtitle:'Reporting and Analytical Content usage by user',
      code:'0350_CONTENT'
    }
  ]
  
  const userUsageItems = [
    {
      title:getExtractName('0070'),
      subtitle:'Select user metrics for a data source',
      code:'0070'
    },
    {
      title:getExtractName('0070_all'),
      subtitle:'All user metrics for a data source',
      code:'0070_all'
    },
    {
      title:getExtractName('0080'),
      subtitle:'User metrics for a defined number of users in a set period of time',
      code:'0080'
    },
  ]

  const teamUsageItems = [
    {
      title:getExtractName('0140'),
      subtitle:'Team metrics for a data source',
      code:'0140'
    },
    {
      title:getExtractName('0005'),
      subtitle:'All Team metrics for a data source',
      code:'0005'
    },
  ]

  const onExtractSubmitted = () => history.push(`/my_tasks?tabName=EXTRACTS&jobName=ASK K`)

  return (
    <div>
      <div>
			  <Typography  className={classes.title}>DATA USAGE</Typography>
      </div>  
      <div style={{marginLeft:16,marginTop:16}}>
        {
          dataUsageItems.map(el=>(
            <QuestionBoardChip
              state={state}
              dispatch={dispatch}
              code={el.code}
              title={el.title}
              subtitle={el.subtitle}
              disableAutoDownload={true}
              onExtractSubmitted={onExtractSubmitted}
            />
          ))
        }
      </div> 

      <div style={{marginTop:40}}>
			  <Typography className={classes.title}>CONTENT USAGE</Typography>
      </div>  
      <div style={{marginLeft:16,marginTop:16}}>
        {
          contentUsageItems.map(el=>(
            <QuestionBoardChip
              state={state}
              dispatch={dispatch}
              code={el.code}
              title={el.title}
              subtitle={el.subtitle}
              disableAutoDownload={true}
              onExtractSubmitted={onExtractSubmitted}
            />
          ))
        }
      </div> 

      <div  style={{marginTop:40}}>
			  <Typography  className={classes.title}>USER USAGE</Typography>
      </div>  
      <div style={{marginLeft:16,marginTop:16}}>
        {
          userUsageItems.map(el=>(
            <QuestionBoardChip
              state={state}
              dispatch={dispatch}
              code={el.code}
              title={el.title}
              subtitle={el.subtitle}
              disableAutoDownload={true}
              onExtractSubmitted={onExtractSubmitted}
            />
          ))
        }
      </div> 

      <div  style={{marginTop:40}}>
			  <Typography className={classes.title}>TEAM USAGE</Typography>
      </div>  
      <div style={{marginLeft:16,marginTop:16}}>
        {
          teamUsageItems.map(el=>(
            <QuestionBoardChip
              state={state}
              dispatch={dispatch}
              code={el.code}
              title={el.title}
              subtitle={el.subtitle}
              disableAutoDownload={true}
              onExtractSubmitted={onExtractSubmitted}
            />
          ))
        }
      </div> 

    </div>
    
  )
}

export default withStyles(styles)(DataManagement);