import axiosSolr from "../../../axios-solr"
import { formatNumber, sendMessage, toTitleCase } from "../../../utilities";

export const loadTiles = () => {
  return axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:ISSUE`,
          rows:0,
          'json.facet':{
            "issue_status": {
              "type": "terms",
              "field": "issue_status_srt",
              "mincount":1,
              "limit":10,
            }
          }
        }
      }
    )
}

export const processTileData = ({facet}) => {
  if(!facet.data.facets.issue_status)return []
  let buckets = facet.data.facets.issue_status.buckets;
  let tiles = ['OPEN','IN_PROGRESS','CLOSED'].filter(o=>buckets.find(b=>b.val===o)).map(o=>({
    id:o,
    clickable:true,
    title:`issues`,
    value:formatNumber(buckets.find(b=>b.val===o)?.count || 0) + ` ${toTitleCase(o.replace(/_/g,' '))} `
  }))
  return tiles
}

export const loadIssueSeverityChart = async ({status}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:ISSUE AND issue_status_srt:"${status}"`,
          rows:0,
          'json.facet':{
            "issue_severity": {
              "type": "terms",
              "field": "issue_severity",
              "mincount":1,
              "limit":50,
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.issue_severity)return;
      let buckets = facet.data.facets.issue_severity.buckets;
      if(buckets.length===0)return;
      chartData = {
        labels:buckets.map(b=>b.val), 
        series:[{
          name:'count',
          data:buckets.map(b=>b.count),
          clickFns:buckets.map(b=>()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:'ISSUE',
              modalFilter:`issue_severity:${b.val} AND issue_status_srt:${status}`
            })
          })
        }]
      }
    })
  return chartData
}

export const loadIssueTypeChart = async ({status}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:ISSUE AND issue_status_srt:"${status}"`,
          rows:0,
          'json.facet':{
            "issue_type": {
              "type": "terms",
              "field": "issue_type_srt",
              "mincount":1,
              "limit":50,
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.issue_type)return;
      let buckets = facet.data.facets.issue_type.buckets;
      if(buckets.length===0)return;
      chartData = {
        labels:buckets.map(b=>b.val),
        series:[{
          name:'count',
          data:buckets.map(b=>b.count),
          clickFns:buckets.map(b=>()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:'ISSUE',
              modalFilter:`issue_type_srt:${b.val} AND issue_status_srt:${status}`
            })
          })
        }]
      }
    })
  return chartData
}

export const loadIssueIsAssignedChart = async ({status}) => {
  let chartData;
  let facetQuery = {
    'assigned': {
      type: 'query',
      q: `assigned_to_msrt:*`
    },
    'not assigned': {
      type: 'query',
      q: `-assigned_to_msrt:[* TO *]`
    },
  }
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:ISSUE AND issue_status_srt:"${status}"`,
          rows:0,
          'json.facet':facetQuery
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets)return;
      let names = Object.keys(facet.data.facets).filter(c=>c!=='count');
      if(names.length===0)return;
      chartData = {
        labels:names.map(el=>el.toUpperCase()), 
        series:[{
          name:'count',
          data:names.map(n=>facet.data.facets[n].count),
          clickFns:names.map(n=>()=>{
            sendMessage({
              modalQuery:`${facetQuery[n].q}`,
              modalObject:'ISSUE',
              modalFilter:`issue_status_srt:${status}`
            })
          })
        }]
      }
    })
  return chartData
}

export const loadIssueSourceChart = async ({status}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:ISSUE AND issue_status_srt:"${status}"`,
          rows:0,
          'json.facet':{
            "issue_source": {
              "type": "terms",
              "field": "source_srt",
              "mincount":1,
              "limit":50,
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.issue_source)return;
      let buckets = facet.data.facets.issue_source.buckets;
      if(buckets.length===0)return;
      chartData = {
        labels:buckets.map(b=>b.val), 
        series:[{
          name:'count',
          data:buckets.map(b=>b.count),
          clickFns:buckets.map(b=>()=>{
            sendMessage({
              modalQuery:`*`,
              modalObject:'ISSUE',
              modalFilter:`source_srt:${b.val} AND issue_status_srt:${status}`
            })
          })
        }]
      }
    })
  return chartData
}

export const loadIssueCreatedChart = async ({status}) => {
  let chartData;
  let facetQuery = {
    'LAST 7 DAYS': {
      type: 'query',
      q: `created_at_srt:[NOW/DAY-7DAYS TO NOW/DAY+1DAY]`
    },
    'LAST 30 DAYS': {
      type: 'query',
      q: `created_at_srt:[NOW/DAY-30DAYS TO NOW/DAY+1DAY]`
    },
    'LAST 90 DAYS': {
      type: 'query',
      q: `created_at_srt:[NOW/DAY-90DAYS TO NOW/DAY+1DAY]`
    },
    'LAST 6 MONTHS': {
      type: 'query',
      q: `created_at_srt:[NOW/DAY-6MONTH TO NOW/DAY+1DAY]`
    },
    'LAST YEAR': {
      type: 'query',
      q: `created_at_srt:[NOW/DAY-1YEAR TO NOW/DAY+1DAY]`
    },
    'ALL TIME': {
      type: 'query',
      q: `created_at_srt:[* TO *]`
    }
  }

  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:ISSUE AND issue_status_srt:"${status}"`,
          rows:0,
          'json.facet':facetQuery
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets)return;
      let names = Object.keys(facet.data.facets).filter(c=>c!=='count');
      if(names.length===0)return;
      chartData = {
        labels:names.map(el=>el.toUpperCase()), 
        series:[{
          name:'count',
          data:names.map(n=>facet.data.facets[n].count),
          clickFns:names.map(n=>()=>{
            sendMessage({
              modalQuery:`*`,
              modalObject:'ISSUE',
              modalFilter:`${facetQuery[n].q} AND issue_status_srt:${status}`
            })
          })
        }]
      }
    })
  return chartData
}

export const loadIssueOpenRateChart = async ({status}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:ISSUE AND issue_status_srt:"${status}"`,
          rows:0,
          'json.facet':{
            "openRate":{
              "type": "range",
              "field": "created_at_srt",
              "start": 'NOW/DAY-30DAYS',
              "end": 'NOW',
              "gap": '+1DAY'
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.openRate)return;
      let buckets = facet.data.facets.openRate.buckets;
      if(buckets.length===0)return;
      chartData = {
        labels:buckets.map(b=>b.val), 
        series:[{
          name:'new issues',
          data:buckets.map(b=>b.count),
        }]
      }
    })
  return chartData
}

export const loadIssueAssignedToChart = async ({status}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:ISSUE AND issue_status_srt:"${status}"`,
          rows:0,
          'json.facet':{
            "assigned_to": {
              "type": "terms",
              "field": "assigned_to_msrt",
              "mincount":1,
              "limit":5,
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.assigned_to)return;
      let buckets = facet.data.facets.assigned_to.buckets;
      if(buckets.length===0)return;
      chartData = {
        labels:buckets.map(b=>b.val), 
        series:[{
          name:'count',
          data:buckets.map(b=>b.count),
          clickFns:buckets.map(b=>()=>{
            sendMessage({
              modalQuery:`*`,
              modalObject:'ISSUE',
              modalFilter:`assigned_to_msrt:${b.val} AND issue_status_srt:${status}`
            })
          })
        }]
      }
    })
  return chartData
}

export const loadIssueCreatedByChart = async ({status}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:ISSUE AND issue_status_srt:"${status}"`,
          rows:0,
          'json.facet':{
            "created_by": {
              "type": "terms",
              "field": "created_by_msrt",
              "mincount":1,
              "limit":5,
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.created_by)return;
      let buckets = facet.data.facets.created_by.buckets;
      if(buckets.length===0)return;
      chartData = {
        labels:buckets.map(b=>b.val), 
        series:[{
          name:'count',
          data:buckets.map(b=>b.count),
          clickFns:buckets.map(b=>()=>{
            sendMessage({
              modalQuery:`*`,
              modalObject:'ISSUE',
              modalFilter:`created_by_msrt:${b.val} AND issue_status_srt:${status}`
            })
          })
        }]
      }
    })
  return chartData
}

export const loadRecommendations = async ({status}) => {
  let recommendations = [undefined,undefined,undefined];
  let promises = [];

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:ISSUE AND issue_status_srt:"${status}" AND -assigned_to_msrt:[* TO *]`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;

        recommendations[0] = {
          title:`Allocate ${status.toLowerCase().replace(/_/g,' ')} issues with no assignee`,
          countTxt:formatNumber(response.data.response.numFound) + ' issues',
          onClick:()=>{
            sendMessage({
              modalQuery:`*`,
              modalObject:'ISSUE',
              modalFilter:`issue_status_srt:${status} AND assigned_to_msrt:NO ASSIGNEDTO`
            })
          }
        }
      })
  )

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:ISSUE AND issue_severity:HIGH AND issue_status_srt:"${status}" AND created_at_srt:[* TO NOW/DAY-90DAY]`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;

        recommendations[1] = {
          title:`Review high severity ${status.toLowerCase()} issues created more than 90 days ago`,
          countTxt:formatNumber(response.data.response.numFound) + ' issues',
          onClick:()=>{
            sendMessage({
              modalQuery:'created_at_srt:[* TO NOW/DAY-90DAY]',
              modalObject:'ISSUE',
              modalFilter:`issue_status_srt:${status} AND issue_severity:HIGH`
            })
          }
        }
      })
  )

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:ISSUE AND issue_severity:(HIGH OR MEDIUM) AND issue_status_srt:"${status}" AND created_at_srt:[* TO NOW/DAY-6MONTH]`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;

        recommendations[2] = {
          title:`Review high & medium severity ${status.toLowerCase()} issues created more than 6 months ago`,
          countTxt:formatNumber(response.data.response.numFound) + ' issues',
          onClick:()=>{
            sendMessage({
              modalQuery:'created_at_srt:[* TO NOW/DAY-6MONTH]',
              modalObject:'ISSUE',
              modalFilter:`issue_status_srt:${status} AND issue_severity:HIGH,MEDIUM`
            })
          }
        }
      })
  )

  await Promise.all(promises)
  return recommendations.filter(r=>r)
}

export const sortByTotalUsage = (metricA, metricsB) => {
  const sum = arr => arr.reduce((a,b) => a + b, 0)
  return  sum(metricsB.dataset.y)-sum(metricA.dataset.y)
}

