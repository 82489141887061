import axiosCerebrum from '../../../../axios-cerebrum';
import { removeUrlQueryArg } from '../../../../utilities';
import {  loadAllUsageTilesByObjectType, loadBySourceRecommendations, loadByTrustChart, loadDailyByFrequencyChart, loadSizebyUsage, loadUnusedByFrequencyChart, loadbyFrequencyChart, processAllUsageTileData} from '../loadUtils';
import 'url-search-params-polyfill';

export const loadTileData = async ({data, setData,source}) => {
  if(!data.tileData && !data.tileDataLoading){
    setData({...data,tileDataLoading:true,tileDataError:false});
    let sourceType = source.source_template.type;
    try{
      let objectTypes = [];
      if(sourceType==='DATABASE')objectTypes = ['DATABASE','SCHEMA','TABLE','COLUMN'];
      if(sourceType==='TOOL')objectTypes = ['DATA_PIPELINE','REPORT','DATASET','ML_MODEL'];
      let tileData = [];
      
      await loadAllUsageTilesByObjectType({objectTypes, source}).then(facet=>{
        tileData = processAllUsageTileData({facet, objectTypes})
        // tileData.forEach(t=>{
        //   if(sourceType==='DATABASE' &&  !['TABLE','COLUMN'].includes(t.id)){
        //     t.clickable = false;
        //   }
        // })
      });


      const urlSearch = new URLSearchParams(window.location.search);
      const presetTile = urlSearch.get('usageSelectedTile') || data.selectedTile;
      let defaultTile;
      if(presetTile && tileData.find(t=>t.id===presetTile)?.clickable){
        defaultTile = presetTile;
      }else{
        defaultTile = sourceType==='DATABASE'?tileData.find(t=>t.id==='TABLE')?'TABLE':undefined:tileData[0]?.id
      }
      window.history.replaceState(null, null,removeUrlQueryArg({url:window.location.toString(),keys:['usageSelectedTile']}));
      setData({
        ...data,
        tileDataLoading:false,
        tileDataError:false,
        tileData,
        selectedTile:defaultTile
      })
      
    }catch(error){
      console.log(error);
      setData({...data,tileDataError:true,titleDataLoading:false,tileData:undefined});
    }

  }
}

export const loadGraphData = async ({data,setData,history,source, state,apiSeq,apiSeqRef}) => {
  setData({
    ...data,
    [`${data.selectedTile}_graphDataLoading`]:true,
    [`${data.selectedTile}_graphDataError`]:false,
    [`${data.selectedTile}_recommendationLoading`]:true,
    [`${data.selectedTile}_recommendationError`]:false,
  });
  let chartsData = {};
  let recommendations;
  try{
    
    let objectType = data.selectedTile;

    let promsies = [
      loadbyFrequencyChart({objectType,history,source, state, isExcludeView:objectType==='TABLE'?true:false}).then(d=>chartsData[`${objectType}_BY_USAGE`] = d),
      objectType==='TABLE'?loadbyFrequencyChart({objectType,history,source, state, isView:true}).then(d=>chartsData[`VIEWS_BY_USAGE`] = d):undefined,
      loadUnusedByFrequencyChart({objectType,history,source, state}).then(d=>chartsData[`${objectType}_UNUSED_BY_FREQUENCY`] = d),
      loadDailyByFrequencyChart({objectType,history,source, state}).then(d=>chartsData[`${objectType}_DAILY_BY_FREQUENCY`] = d),
      objectType==='TABLE'?loadSizebyUsage({objectType,source,state}).then(d=>chartsData[`TABLE_SIZE_BY_USAGE`] = d):undefined,
      loadByTrustChart({objectType,history,source, state}).then(d=>chartsData[`${objectType}_BY_TRUST`] = d),
      loadBySourceRecommendations({objectType, source}).then(d=>recommendations = d)
    ]
    
    await Promise.all(promsies)
    
    if(objectType!=='ML_MODEL' && objectType!=='SCHEMA' && objectType!=='DATABASE'){
      let perDayMeasure;
      let metricsObjectName = objectType+'S';
      if(objectType==='REPORT')metricsObjectName = 'CONTENT'

      await axiosCerebrum
        .get(
          `/api/metrics`,
          {params:{
            measures:`${metricsObjectName}_USED_1D`,
            timeseries_flag:true,
            source_ids:source.id,
            period:30,
            per_page:1
          }}
        )
        .then(response=>{
          perDayMeasure = response.data.items
        })
        
      if(perDayMeasure.length>0){
        chartsData[`${objectType}_USED_PER_DAY`] = {
          series:perDayMeasure.map(el=>({
            name:source.name,
            source_id:el.source_id,
            data:el.dataset.y,
            labels:el.dataset.x
          })).sort((a,b)=>a.name<b.name?-1:1)
        }
      }
    }
    if(apiSeq!==apiSeqRef.current)return;
    let newDataObj = {}
    Object.keys(data).filter(el=>!el.includes('_graphDataLoading') &&  !el.includes('_graphDataError') && !el.includes('_recommendationError') && !el.includes('_recommendationLoading') )
      .forEach(k=>{
        newDataObj[k] = data[k]
      })
      
    setData({
      ...newDataObj,
      [`${data.selectedTile}_graphDataLoading`]:false,
      [`${data.selectedTile}_graphDataError`]:false,
      [`${data.selectedTile}_graphData`]:chartsData,
      [`${data.selectedTile}_recommendationLoading`]:false,
      [`${data.selectedTile}_recommendationError`]:false,
      [`${data.selectedTile}_recommendationData`]:recommendations
    })
  }catch(error){
    console.log(error);
    if(apiSeq!==apiSeqRef.current)return;
    setData({
      ...data,
      [`${data.selectedTile}_graphDataLoading`]:false,
      [`${data.selectedTile}_graphDataError`]:true,
      [`${data.selectedTile}_graphData`]:undefined,
      [`${data.selectedTile}_recommendationLoading`]:false,
      [`${data.selectedTile}_recommendationError`]:true,
      [`${data.selectedTile}_recommendationData`]:undefined
    })
  }
}
