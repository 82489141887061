import React from 'react';
import { withStyles} from '@material-ui/core';
import Grid from '../Grid/Grid';
import Discover from '../Discover/Discover';
import Sources from '../Sources/Sources';

const styles = theme => ({
  root: {
  }
})

const Body = props => {

  const {
    classes,
    history,
    sessionData,
    state,
    dispatch,
    tabOptions,
  } = props;

  
  let bodyContent;

  switch (tabOptions[state.tabState]) {
    case 'SOURCES':
      bodyContent=<Sources state={state} dispatch={dispatch} history={history} sessionData={sessionData}/>
      break;
    case 'MAP':
      bodyContent=<Grid state={state} dispatch={dispatch} history={history} sessionData={sessionData}/>
      break;
    case 'DISCOVER':
      bodyContent=<Discover state={state} dispatch={dispatch} history={history} sessionData={sessionData}/>
      break;
    default:
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

export default withStyles(styles)(Body);