import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, CircularProgress, Select, MenuItem,Paper, MenuList, Popper, ClickAwayListener, InputBase, IconButton, Checkbox } from '@material-ui/core';
import axiosSolr from '../../../axios-solr';
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import ResultItem from '../../UI/SearchResults/ResultItem2'
import {onClickResultItem,getDispFields,getIconComponent, mapSearchObjectName, isInViewport } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';


const styles = theme => ({
  root: {
    marginBottom: '5rem'
  },
  body:{
    flexGrow:1,
    marginLeft:80,
    overflow:'hidden'
  },
  mainText: {
    fontSize: '1.25rem',
    marginTop: '1.5rem',
    color:theme.palette.header.main
  },
  subText: {
    marginBottom: '2.5rem',
    fontsize: '1.1rem',
    color: theme.palette.primaryText.light
  },
  left: {
    float: 'left',
    width: '35%',
    marginRight: '5%'
  },
  right: {
    float: 'left',
    width: '50%'
  },
  smallTitle: {
    marginBottom: '1rem',
    fontSize: '1rem'
  },
  title: {
    display: 'inline-block',
    color: theme.palette.header.main,
    fontSize: '1.25rem'
  },
  subtitle: {
    height: '16px',
    color: theme.palette.primaryText.light,
    fontSize: '11.78px',
  },
  titleSelector:{
    ...theme.components.titleSelector,
    maxWidth:300,
  },
  selector: {
    ...theme.components.selector,
    width: 160,
  },
  icon: {
    display: 'inline-block',
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
	searchFilter:{
    ...theme.components.inputBase,
    marginRight:24,
    width:160,
		height:40,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
  listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },
  checkbox:{
    paddingLeft:0,
  },
  collapseHeader:{
    display:'flex',
    alignItems:"center",
    marginBottom:8
  },
  sectionText:{
    fontSize:12,
    color:theme.palette.primary.main,
    marginRight:8
    // letterSpacing:1,
  }
})

const UsedList = props => {

  const {
    classes,
    theme,
    history,
    variant,
    state,
    dispatch,
    tabOptions,
    tabOptionsLoading,
    tabOptionsError,
    loadTabOptions,
    tabState,
    setTabState
  } = props;

	const [anchor, setAnchor] = useState()
	const [listActionOpen, setListActionOpen] = useState(false);
  const scrollRef = useRef()
  
  let selectedType, data;
  if(tabOptions){
    selectedType = tabOptions[tabState]
    data = state.listData[selectedType]
  }
 
  const searchStr = state.listSearch || '';
  const setSearchStr = value => dispatch({ type: 'set_list_search', listSearch: value});

  const sortByItems = [
    { dispName: 'Frequently used', value: 'total_usage_srt desc' },
    { dispName: 'Recently used', value: 'last_used_srt desc' },
    { dispName: 'Trust score', value: 'trust_srt desc' },
  ]

  const loadData = ({sort=state.listSort, type=selectedType, showDeleted=state.listShowDeleted, start=0}) => {
    dispatch({ 
      type: 'set_list_data', 
      listData: {
        ...state.listData,
        [type]:{
          ...(start===0?{}:state.listData[type]),
          loading:true
        }
      }
    });
    let perPage = 20;
  
    if(type.includes('KEY_')){
      let objectType = mapSearchObjectName(type.split('KEY_')[1])
      axiosCerebrum
        .get(
          `/api/groups/${state.basicData.id}/related`,{
            params:{
              relationship:'KEY_IN_AUTO',
              object_name:objectType,
              per_page:perPage,
              page:Math.floor(start/perPage)+1,
              sort:'END_DESC',
              active_flag:showDeleted?undefined:true
            }
          }
        )
        .then(response=>{
          if(response.data.total===0){
            dispatch({ 
              type: 'set_list_data', 
              listData: {
                ...state.listData,
                [type]:{numFound:0,docs:[]}
              }
            });
          }else{
            let ids = response.data.items.map(el=>el.id)
            let fq = `id:(${ids.join(' ')})`;
            axiosSolr.get(
              '/solr/search/select',
              {
                params: {
                  "q": "*",
                  fq,
                  "rows": ids.length,
                }
              }
            )
            .then((solrResponse) => {
              solrResponse.data.response.docs.sort((a,b)=>{
                return ids.indexOf(a.id)-ids.indexOf(b.id)
              })
              dispatch({ 
                type: 'set_list_data', 
                listData: {
                  ...state.listData,
                  [type]:{
                    ...solrResponse.data.response,
                    docs:start===0?solrResponse.data.response.docs:[...state.listData[type].docs,...solrResponse.data.response.docs]
                  }
                }
              });
            })
            .catch(error => {
              console.log('Error loading the data', error)
              dispatch({ 
                type: 'set_list_data', 
                listData: {
                  ...state.listData,
                  [type]:{error:true}
                }
              });
            })
          }
        })
        .catch(error => {
          console.log('Error loading the data', error)
          dispatch({ 
            type: 'set_list_data', 
            listData: {
              ...state.listData,
              [type]:{error:true}
            }
          });
        })
    }else{
      let fq = `object_type_srt:"${type}" AND teams_msrt:"${state.basicData.name}"`;
      if(!showDeleted)fq += ` AND active_srt:YES`
      axiosSolr.get(
        '/solr/search/select',
        {
          params: {
            q: "*",
            fq,
            sort,
            start,
            rows: perPage,
          }
        }
      )
      .then((response) => {
        dispatch({ 
          type: 'set_list_data', 
          listData: {
            ...state.listData,
            [type]:{
              ...response.data.response,
              docs:start===0?response.data.response.docs:[...state.listData[type].docs,...response.data.response.docs]
            }
          }
        });
      })
      .catch(error => {
        console.log('Error loading the data', error)
        dispatch({ 
          type: 'set_list_data', 
          listData: {
            ...state.listData,
            [type]:{error:true}
          }
        });
      })
    }
  }

  useEffect(()=>{
    if(!tabOptions)loadTabOptions()
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(!tabOptions || tabOptions.length===0)return;
    let newSelectedType = tabOptions[tabState]
    if(!state.listData[newSelectedType] || state.listData[newSelectedType].error ){
      loadData({type:newSelectedType})
    }
  // eslint-disable-next-line
  },[tabState, tabOptions])


  const handleSeeMore = () => {
    let presetFilter = `teams_msrt:${state.basicData.name}`;
    history.push(`/basic_search?query=*&object=${selectedType}&presetFilter=${presetFilter}`)
  }

  const onChangeFilter = value => {
    loadData({sort:value})
    dispatch({ type: 'set_list_sort', listSort: value });
  }

  const onActionClick = event => {
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}

  const onClickShowDeleted = showDeleted => {
    loadData({type:selectedType, showDeleted:showDeleted})
    dispatch({ type: 'set_list_show_deleted', listShowDeleted: showDeleted });
  }

  const shouldLoadMore = () => {
    return isInViewport(scrollRef) && data && !data.loading && data.docs.length<data.numFound
  }

  window.onscroll = () => {
    if(shouldLoadMore()){
      loadData({start:data.docs.length})
    }
  }

  useEffect(()=>{
    if(shouldLoadMore()){
      loadData({start:data.docs.length})
    }
    // eslint-disable-next-line
  },[data])

  if(tabOptionsLoading)return <div style={{display:'flex',justifyContent:'center',marginTop:24}}><CircularProgress color='secondary'/></div>
  if(tabOptionsError)return <Typography style={{fontSize:13.75}}>Error occurred loading items</Typography>
  if(!tabOptions)return <div></div>
  if(tabOptions.length===0)return <Typography style={{fontSize:13.75}}>No data found</Typography>

  return (
    <div className={classes.root}>
      <div style={{display:'flex'}}>
        <div style={{flex:"0 0 200px"}}>
          {
            tabOptions.find(el=>el.includes('KEY_')) &&
            <div style={{marginBottom:16}}>
              <div className={classes.collapseHeader}>
                <Typography className={classes.sectionText}>KEY</Typography>
              </div>
              <div className={classes.collapseBody}>
                <VerticalTabBar
                  minWidth={200}
                  maxWidth={200}
                  tabOptions={tabOptions.filter(el=>el.includes('KEY_')).map(el=>el.replace(/KEY_/,'').replace(/_/g,' '))}
                  tabState={tabState}
                  setTabState={setTabState}
                  disableUnderline={true}
                />
              </div>
            </div>
          }
          <div>
            <div className={classes.collapseHeader}>
              <Typography className={classes.sectionText}>ALL {variant.toUpperCase()}</Typography>
            </div>
            <div className={classes.collapseBody}>
              <VerticalTabBar
                minWidth={200}
                maxWidth={200}
                tabOptions={tabOptions.filter(el=>!el.includes('KEY_')).map(el=>el.replace(/_/g,' '))}
                tabState={tabState-tabOptions.filter(el=>el.includes('KEY_')).length}
                setTabState={value=>setTabState(value+tabOptions.filter(el=>el.includes('KEY_')).length)}
                disableUnderline={true}
              />
            </div>
          </div>
        </div>
        <div className={classes.body}>
          <div>
            <div style={{ display: 'flex', justifyContent:'space-between', alignItems: "flex-start",marginBottom:16}}>
              <div style={{ display: "flex", flexGrow:1, alignItems:'center' }}>
                <Typography className={classes.title}>
                {data?(data.numFound||0):0} {selectedType.replace(/_/g,' ')}(S)
                </Typography>
              </div>
              <div style={{flexGrow:0,flexShrink:0, display:'flex', alignItems:'center'}}>
                <InputBase
                  value={searchStr}
                  onChange={event => setSearchStr(event.target.value)}
                  variant={'filled'}
                  placeholder={'Search'}
                  className={classes.searchFilter}
                  endAdornment={
                    <IconButton 
                      disabled={searchStr===''} 
                      onClick={()=>setSearchStr('')}
                      style={{width:32,height:32,marginRight:6}}
                    >
                      {getIconComponent({label:searchStr===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
                    </IconButton>
                  }
                />
                {
                  !selectedType.includes('KEY_') &&
                  <Select
                    className={classes.selector}
                    value={state.listSort}
                    disableUnderline
                    onChange={event => onChangeFilter(event.target.value)}
                  >
                    {
                      sortByItems.map(el => (
                        <MenuItem value={el.value}>
                          <span>{el.dispName}</span>
                        </MenuItem>
                      ))
                    }
                  </Select>
                }
                <div style={{marginLeft:24,width:24,height:24,cursor:'pointer'}} onClick={onActionClick}>
                  {getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
                </div>
                <Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
                  <ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
                    <Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
                      {
                        !selectedType.includes('KEY_') && 
                        <>
                          <Typography className={classes.listActionSectionTitle}>ACTIONS</Typography>
                          <MenuList className={classes.listContainer}>
                            <MenuItem onClick={()=>{handleSeeMore()}} className={classes.menuItem} >
                              <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>View in search</Typography>
                            </MenuItem>
                          </MenuList>
                        </>
                      }
                      <Typography className={classes.listActionSectionTitle}>FILTERS</Typography>
                      <MenuItem data-test-id="child-list-show-deleted" onClick={()=>{onClickShowDeleted(!state.listShowDeleted)}} className={classes.menuItem} >
                        <Checkbox key={state.listShowDeleted} className={classes.checkbox} color='primary' checked={!state.listShowDeleted}/>
                        <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show active only</Typography>
                      </MenuItem>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </div>
            </div>

            {
              data && data.docs && data.docs.filter(el=>el.name_txt.toLowerCase().includes(searchStr.toLowerCase())||searchStr.trim()==='').map(item=>(
                <ResultItem
                  key={item.id}
                  item={item}
                  dispBody={getDispFields(item,'dispBody')}
                  dispSubtitle={getDispFields(item,'dispSubtitle')}
                  dispTitle={getDispFields(item,'dispTitle')}
                  label={item.object_type_txt}
                  showIcon
                  showUnderline
                  onClick={ () => onClickResultItem({ id:item.id,label: item.object_type_txt, history, item: item })}
                />
              ))
            }
            {
              data && data.numFound===0 &&
              <Typography className={classes.normalText} style={{marginTop:16}}>No item to be displayed.</Typography> 
            }
            {
              data && data.error &&
              <Typography className={classes.normalText} style={{marginTop:16}}>Error occurred loading Tables</Typography> 
            }
            <div style={{textAlign:'center',marginTop:16,marginBottom:24}} ref={scrollRef}>
              {
                data && data.loading && 
                <CircularProgress color='secondary'/>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


UsedList.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(UsedList));