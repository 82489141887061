import React, {} from 'react';
import { withTheme, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import SearchSelector from '../../../UI/SearchSelector/SearchSelector';
import { getIconComponent, mapObjectName } from '../../../../utilities';


const ObjectSelector = props => {

  const {
    classes,
    theme,
    objectType='TABLE',
    object,
    setObject,
    objectList,
    setObjectList,
    isMulti,
    objectSearchValue,
    setObjectSearchValue,
    dataSource,
    schema='none',
    schemaList,
    required,
  } = props;


  return (
    <div className={classes.modalBlock}>
      <Typography color='primary' className={classes.modalBlockTitle}>
        {'Object'}
      </Typography>
      <div style={{flexGrow:1}}>
        <SearchSelector
          url='/solr/search/select'
          params={{
            q:`${objectSearchValue}*`,
            fq: `object_type_srt:${objectType==='none'?'*':mapObjectName(objectType)}` + 
              (dataSource==='none'?'':` AND source_id_srt:${dataSource}`) +
              (schema==='none'?'':` AND schema_srt:${schemaList.find(s=>s.id===schema).name_txt}`) + 
              (isMulti && objectList.length>0?` AND -id:(${objectList.map(el=>el.id).join(' OR ')})`:'')
              ,
            rows: 10
          }}
          autoSuggestion
          // removeFLModify={false}
          searchValue={objectSearchValue}
          setSearchValue={setObjectSearchValue}
          placeholder={`Search for an object`}
          onResultClick={el=>{
            if(isMulti){
              if(!objectList.find(o=>o.id===el.id)){
                setObjectList([...objectList,el])
              }
            }else{
              setObject(el)
            }
          }}
        />
        <Typography style={{fontSize:12,marginLeft:18,marginBottom:16,color:theme.palette.primaryText.light,marginTop:6}}>{required?'Required':'Optional'}</Typography>
        <div style={{display:'flex',overflow:'hidden',flexWrap:'wrap'}}>
          {
            (isMulti?objectList:[object]).filter(el=>el).map(o=>(
              <div className={classes.userChip}>
                <div style={{marginRight:20,marginLeft:10, display:'inline-block', verticalAlign:'middle'}}>
                  {getIconComponent({label:o.object_type_txt, size:24,colour:theme.palette.primary.main})}
                </div>
                <Typography style={{display:'inline-block', verticalAlign:'middle', color:theme.palette.primaryText.main}}>{o.name_txt}</Typography>
                {
                  <div style={{marginLeft: 20, display:'inline-block', verticalAlign: 'middle'}}>
                    <Button 
                      style={{padding:0,width:24,minWidth:24}} 
                      onClick={()=>{
                        if(isMulti){
                          setObjectList(objectList.filter(el=>el.id!==o.id))
                        }else{
                          setObject()
                        }
                      }}
                    >
                      {getIconComponent({label:'clear', size:20, colour:theme.palette.primaryText.light})}
                    </Button>
                  </div>
                }	
              </div>
            ))
          }
        </div>
        {
          (isMulti?objectList:[object]).filter(el=>el).length===0 && 
          <div className={classes.userChip}> 
            <Typography style={{display:'inline-block', verticalAlign:'middle', paddingRight: '1rem', color:theme.palette.primaryText.main,paddingLeft:12}}>No object selected</Typography>
          </div>
        }
      </div>
    </div>
  )
}

ObjectSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  object: PropTypes.object,
  setObject: PropTypes.func.isRequired,
  objectSearchValue: PropTypes.string.isRequired,
  setObjectSearchValue: PropTypes.func.isRequired,
  dataSource: PropTypes.string.isRequired,
  schema: PropTypes.string.isRequired,
  schemaList: PropTypes.array.isRequired
}

export default withTheme()(ObjectSelector);