import React,{useEffect,useRef,useState} from 'react';
import { withTheme, withStyles, Typography, CircularProgress,Button, MenuItem, Paper, Popper, MenuList, ClickAwayListener, InputBase, IconButton } from '@material-ui/core';
import ResultItem2 from '../../UI/SearchResults/ResultItem2';
import { onClickResultItem,getDispFields, isInViewport, getIconComponent, toTitleCase, getSearchMode, mapObjectName, formatNumber} from '../../../utilities'
import axiosSolr from '../../../axios-solr'
import axiosCerebrum from '../../../axios-cerebrum'
import ChipWrapper from '../../UI/ChipWrapper/ChipWrapper'
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';


const styles = theme => ({
  root:{
    display:'flex'
  },
  selector: {
    ...theme.components.selector,
    width: 160,
  },
  divButton:{
    height:36,
    width:36,
    display:'flex',
    alignItems:'center',
    justifyContent:'space-around',
    borderRadius:18,
    cursor:'pointer',
    '&:hover':{
      backgroundColor:'#EEE'
    }
  },
  title:{
    display: 'inline-block',
    // fontSize: '1.1rem',
    position: 'relative',
    top: '0.1rem',
    color: theme.palette.header.main,
    fontWeight: '400',
    fontSize: '1.25rem',
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },
	menuItem:{
		padding:'10px 10px 10px 16px',
		'&:hover':{
      background: theme.palette.buttonHovered.main 
    }
	},
  searchFilter:{
    ...theme.components.inputBase,
    marginRight:24,
    width:160,
		height:40,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
})

function Linked(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    history,
  } = props;

  let scrollRef = useRef();
  const [editing, setEditing] = useState(false);
	const [anchor, setAnchor] = useState()
	const [listActionOpen, setListActionOpen] = useState(false);
	const searchTimeoutRef = useRef()
  
  let label, isCollection, data, loading;
  let search = ''
  if(state.linkedTabs && state.linkedTabs.length>0 ){
    label = state.linkedTabs[state.linkedTabState].label;
    isCollection =  state.linkedTabs[state.linkedTabState].isCollection;
    data = state.linkedData[label];
    loading = data?data.loading:false;
    search = data?(data.search||''):''
  }
  let editable = label==='USER'||state.basicData.parent.category==='PLATFORM';

  const shoudLoadMore = () => {
    return data && !loading && data.page<data.pages && isInViewport(scrollRef)
  }

  const loadData = ({page=1,search}) => {

    dispatch({
      type:'set_linked_data',
      linkedData:{
        ...state.linkedData,
        [label]:{
          ...data,
          search,
          items:page===1?undefined:data.items,
          loading:true,
          error:false
        }
      }
    })
    let url, params;
    if(isCollection){
      url = `/api/collectioninstances/${state.basicData.id}/related/collections`;
      params = {
        page:page,
        per_page:10,
        relationship:'MEMBERS,MEMBERS_AUTO,VERIFIES,CLASSIFIES',
        'search.name':search && search.trim()!==''?search:undefined,
        parent_name:label
      }
    }else{
      url = `/api/collectioninstances/${state.basicData.id}/related`;
      params = {
        object_name:label,
        page:page,
        relationship:'MEMBERS,MEMBERS_AUTO,VERIFIES,CLASSIFIES',
        'search.name':search && search.trim()!==''?search:undefined,
        per_page:10
      }
    }
    axiosCerebrum
      .get(
        url,
        {params}
      )
      .then(response=>{
        if(response.data.total===0){
          dispatch({
            type:'set_linked_data',
            linkedData:{
              ...state.linkedData,
              [label]:{
                ...response.data,
                search,
                loading:false,
                error:false
              }
            }
          })
          return;
        }
        axiosSolr.get(
          '/solr/search/select',
          {params:{
            q:'*',
            fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
            fl:"*",
            rows:10
          }}
        ).then(solrRes=>{
          let arr = [];
          response.data.items.forEach(el=>{
            arr.push({...el, ...(solrRes.data.response.docs.find(s=>s.id===el.id)||{})})
          }) 
          dispatch({
            type:'set_linked_data',
            linkedData:{
              ...state.linkedData,
              [label]:{
                ...response.data,
                items:page===1?arr:[...data.items,...arr],
                search,
                loading:false,
                error:false
              }
            }
          })
        }).catch(error=>{
          console.log(error)
          dispatch({
            type:'set_linked_data',
            linkedData:{
              ...state.linkedData,
              [label]:{
                ...data,
                search,
                loading:false,
                error:true
              }
            }
          })
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_linked_data',
          linkedData:{
            ...state.linkedData,
            [label]:{
              ...data,
              search,
              loading:false,
              error:true
            }
          }
        })
      })
  }


  useEffect(()=>{
    if(!state.linkedTabs||state.linkedTabs.length===0)return;
    if(!data)loadData({page:1,search});
    if(shoudLoadMore())loadData({page:data.page+1,search})
  // eslint-disable-next-line
  },[state.linkedTabs,state.linkedTabState,state.linkedData])

  window.onscroll=()=>{
    if(!shoudLoadMore())return;
    loadData({page:data.page+1,search})
  }

  const onDelete = item => {
    axiosCerebrum.delete(
      `/api/collectioninstances/${state.basicData.id}/related?relationship=${item.relationship}&object_id=${item.id}`,
    ).then(()=>{
      let object = {...data,total:data.total-1,items:data.items.filter(el=>el.id!==item.id)}
      dispatch({type:'set_linked_data',linkedData:{...state.linkedData,[label]:object}})
    }).catch(error=>{
      console.log(error);
    })
  }

  const removeDup = arr => {
    if(label==='QUERY'){return arr;}
    let finalArr = [];
    arr.forEach(el=>{
      if(finalArr.filter(t=>el&&t.id===el.id).length===0)finalArr.push(el);
    })
    return finalArr;
  }

  const onListActionClick = event => {
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}

	const onClickViewInSearch = () => {
    if(getSearchMode()==='basic_search'){
      let parentName = state.basicData.parent.short_name;
      if(!parentName)return;
      let presetFilter = `${parentName.toLowerCase().replace(/\s+/g,'_')}_kc_msrt:${state.basicData.name}`;
      history.push(`/basic_search?query=*&object=${mapObjectName(label)}&presetFilter=${presetFilter}`)
    }
	}


  const onSearchChange = value => {
    dispatch({
      type:'set_linked_data',
      linkedData:{
        ...state.linkedData,
        [label]:{
          ...data,
          search:value
        }
      }
    })
    clearTimeout(searchTimeoutRef.current);
    searchTimeoutRef.current = setTimeout(()=>{
      loadData({page:1,search:value})
    },250)
  }

  if(state.linkedTabsLoading){
    return (
      <div style={{width:'100%',marginTop:80,display:'flex',justifyContent:'space-around'}}>
        <CircularProgress color='secondary' />
      </div>
    )
  }
  if(state.linkedTabsError){
    return <Typography className={classes.normalText}>Error occurred loading linked items</Typography>
  }
  if(!state.linkedTabs){
    return <div></div>
  }

  if(state.linkedTabs.length===0){
    return <Typography className={classes.normalText}>No item linked to this instance</Typography>
  }

  return (
    <div className={classes.root}>
      <VerticalTabBar
        tabOptions={state.linkedTabs.map(el=>el.dispName)}
        tabState={state.linkedTabState}
        setTabState={value => dispatch({ type: 'set_linked_tab_state', linkedTabState: value })}
      />
      <div style={{flexGrow:1,marginLeft:80,overflow:'hidden'}}>
        <div style={{marginBottom:16,marginTop:8}}>
          <div style={{display:'flex',alignItems:'flex-start',marginTop:8}}>
            <Typography color='primary' className={classes.title}>
              {`${data&&data.total?formatNumber(data.total):'0'} ${mapObjectName(label,true).replace(/_/g,' ').toUpperCase()}(S) LINKED`}
            </Typography>
            {!editing && editable && <Button color='primary' style={{marginLeft:16}} onClick={()=>setEditing(true)}>EDIT</Button>}
            {editing && editable && <Button color='secondary' style={{marginLeft:16}} onClick={()=>setEditing(false)}>CLOSE</Button>}
            <div style={{flexGrow:1}}></div>
            {
              state.linkedTabs[state.linkedTabState].dispName!=='QUERY' && 
              <InputBase
                value={search}
                onChange={event => onSearchChange(event.target.value)}
                variant={'filled'}
                placeholder={'Search'}
                className={classes.searchFilter}
                endAdornment={
                  <IconButton 
                    disabled={search===''} 
                    onClick={()=>onSearchChange('')}
                    style={{width:32,height:32,marginRight:6}}
                  >
                    {getIconComponent({label:search===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
                  </IconButton>
                }
              />
            }
            
            { (getSearchMode()!=='basic_search' || (!['TABLE CLUSTER','QUERY CLUSTER'].includes(state.basicData.parent.name.toUpperCase()) && state.basicData.parent.short_name)) && 
              <div style={{marginTop:8,width:24,height:24,cursor:'pointer'}} onClick={onListActionClick}>
                {getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
              </div>
            }
            <Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
              <ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
                <Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
                  <Typography className={classes.listActionSectionTitle}>ACTIONS</Typography>
                  <MenuList className={classes.listContainer}>
                    <MenuItem onClick={()=>{onClickViewInSearch()}} className={classes.menuItem} >
                      <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>View in search</Typography>
                    </MenuItem>
                  </MenuList>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </div>
          <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginTop:4}}>{toTitleCase(label.replace(/_/g,' '))}(s) linked to this instance{editable?'. Click EDIT to remove links':''}</Typography>
        </div>
        <div>
          {
            data && data.items && removeDup(data.items).map(item=>(
              <ChipWrapper
                key={item.id}
                showUnderline={true}
                wrappedComponent={
                  <ResultItem2
                    item={item}
                    key={item.id}
                    dispBody={getDispFields(item,'dispBody')}
                    dispSubtitle={getDispFields(item,'dispSubtitle')}
                    dispTitle={getDispFields(item,'dispTitle')}
                    label={item.object_type_txt||item.type}
                    showIcon
                    onClick={() => onClickResultItem({id:item.id,label:item.object_type_txt||item.type,history:history,item:item})}
                  />
                }
                icon={editing?getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light}):undefined}
                onIconClick={()=>onDelete(item)}
              />
            ))
          }
        </div>
        {
          data && data.items && removeDup(data.items).length===0 && 
          <Typography className={classes.normalText}>No item found</Typography>
        }
        <div ref={scrollRef} style={{width:'100%',display:'flex',justifyContent:'center',marginBottom:10}}>
          { loading && <CircularProgress style={{marginTop:16}} color='secondary'/>}
        </div>
      </div>
    </div>
  )
}


export default withTheme()(withStyles(styles)(Linked));