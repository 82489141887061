import React, { useReducer,useEffect } from 'react';
import { withTheme, withStyles, Typography, LinearProgress} from '@material-ui/core';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/ImpactAssessment/Body/Body';
import { getKnowledgeBaseUrl, getUserRoles, handleShareClick, onClickResultItem} from '../../utilities'
import useGetCerebrum from '../../hooks/useGetCerebrum'
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import moment from 'moment'
import { addHistory } from '../../HistoryManager';
import 'url-search-params-polyfill';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import DownloadModal from '../../components/ImpactAssessment/Results/DownloadModal/DownloadModal';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import { checkEmailConfigured } from '../../permissionChecker';
import DropdownButton from '../../components/UI/Buttons/DropdownButton';

const styles = theme => ({
  subTitle:{
    fontSize:13.75,
    color:theme.palette.primaryText.main
  },
  description:{
    fontSize:13.75,
    color:theme.palette.primaryText.light
  },
  underlineOnHover: {
    cursor: 'pointer',
    color: theme.palette.primaryText.main,
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  button: {
    margin: '8px 0px 8px 16px',
    height: '3rem',
    minWidth:'5.5rem',
    paddingLeft:'8px',
    paddingRight:'8px'
  },
})

const initialState = {
  tabState:0,
  multiIAStep:0,
  multiIASelectedIds:[],
  resultTabState:'DASHBOARD',
  targetObject:null,
  objectType:'none',
  depthLimit:-1,
  usageWindow:-1,
  additionalCriteria:[],
  sourceList:undefined,
  source:'none',
  datasourceId:'',
  settingStep:0,
  isRerun:false,
  forceTargetObject:false,
  summaryChartData:{},
  detailData:{},
  detailFilterData:{},
  detailFilterValueData:{},
  // notificationTitle:'',
  // notificationDescription:'',
  notifyStep:0,
  notificationInput:{title:'',description:'',impact:''},
  notificationUsers:[],
  notificationEmailsManual:[],
  notificationUsersManual:[],
  // extractIdLinkedToChange:undefined, // stores latest extract id linked to change
  changeData:undefined,
  selectedDashboard:'data',
  impactBarChartType:'impacted_only',
  impactDepthChartType:'true_scale',
  emailTemplates:[],
  selectedEmailTemplate:'none',
  lineageData:{}
}

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState
      }
    case 'set_is_rerun':
      return {
        ...state,
        isRerun:action.isRerun
      }
    case 'set_result_tab_state':
      return {
        ...state,
        resultTabState:action.resultTabState
      }
    case 'set_source_list':
      return {
        ...state,
        sourceList:action.sourceList
      }
    case 'set_force_target_object':{
      return {
        ...state,
        forceTargetObject:action.forceTargetObject
      }
    }
    case 'reset_setting':
      return {
        ...state,
        settingStep:0,
        source:'none',
        objectType:'none',
        targetObject:null,
        depthLimit:-1,
        usageWindow:-1,
        additionalCriteria:[],
        forceTargetObject:false,
      }
    //////// multi ia
    case 'set_multi_ia_step':{
      return {
        ...state,
        multiIAStep:action.multiIAStep
      }
    }
    case 'set_multi_ia_objects':{
      return {
        ...state,
        multiIAObjects:action.multiIAObjects,
        multiIAObjectsLoading:action.multiIAObjectsLoading,
        multiIAObjectsError:action.multiIAObjectsError
      }
    }
    case 'set_multi_ia_selected_ids':{
      return {
        ...state,
        multiIASelectedIds:action.multiIASelectedIds
      }
    }
    case 'set_is_clear_cart':{
      return {
        ...state,
        isClearCart:action.isClearCart
      }
    }
    ///////
    /////
    case 'set_setting_step':
      return {
        ...state,
        settingStep:action.settingStep
      }
    case 'set_source':
      return {
        ...state,
        source:action.source
      }
    case 'set_object_type':
      return {
        ...state,
        objectType:action.objectType
      }
    case 'set_target_object':
      return {
        ...state,
        targetObject:action.targetObject
      }
    case 'set_usage_window':
      return {
        ...state,
        usageWindow:action.usageWindow
      }
    case 'set_depth_limit':
      return {
        ...state,
        depthLimit:action.depthLimit
      }
    case 'set_additional_criteria':
      return {
        ...state,
        additionalCriteria:action.additionalCriteria
      }
    case 'set_history_object':
      return {
        ...state,
        historyObject:action.historyObject
      }    
    case 'set_multi_history_objects':
      return {
        ...state,
        multiHistoryObjects:action.multiHistoryObjects
      }
    case 'set_extract_data':
      return {
        ...state,
        extractData:action.extractData,
        extractLoading:action.extractLoading,
        extractError:action.extractError
      }
      
    ////////////////////////
    /// chart data
    case 'reset_result':
      return {
        ...state,
        summaryChartData:{},
        summaryData:undefined,
        lineageData:{},
        detailData:{},
        detailFilterData:{},
        detailFilterValueData:{},
        selectedDashboard:'data',
      }
    case 'set_selected_dashboard':
      return {
        ...state,
        selectedDashboard:action.selectedDashboard
      }
    case 'set_summary_data':
      return {
        ...state,
        summaryData:action.summaryData,
        summaryLoading:action.summaryLoading,
        summaryError:action.summaryError
      }
    case 'set_extract_collection_details':
      return {
        ...state,
        extractCollectionDetails:action.extractCollectionDetails,
      }
    case 'set_summary_chart_data':
      return {
        ...state,
        summaryChartData:action.summaryChartData
      }
    case 'set_result_data_object_type':
      return {
        ...state,
        resultDataObjectType:action.resultDataObjectType
      }
    case 'set_result_content_object_type':
      return {
        ...state,
        resultContentObjectType:action.resultContentObjectType
      }
    case 'set_result_user_type':
      return {
        ...state,
        resultUserType:action.resultUserType
      }
    case 'set_show_reference':
      return {
        ...state,
        showReference:action.showReference
      }
    case 'set_detail_data':
      return {
        ...state,
        detailData:action.detailData
      }
    case 'set_detail_filter_data':
      return {
        ...state,
        detailFilterData:action.detailFilterData
      }
    case 'set_detail_filter_value_data':
      return {
        ...state,
        detailFilterValueData:action.detailFilterValueData
      }
    case 'set_lineage_data':
      return {
        ...state,lineageData:action.lineageData
      }
    case 'set_download_modal_open':
      return {
        ...state,
        downloadModalOpen:action.downloadModalOpen
      }
    ///////////////////////////////////
    ///// notification steps
    case 'set_email_templates':
      return {
        ...state,
        emailTemplates:action.emailTemplates
      }
    case 'set_notify_step':
      return {
        ...state,
        notifyStep:action.notifyStep
      }
    case 'set_notification_input':{
      return {
        ...state,
        notificationInput:action.notificationInput
      }
    }
    case 'set_selected_email_template':
      return {
        ...state,
        selectedEmailTemplate:action.selectedEmailTemplate
      }
    case 'set_notification_users':
      return {
        ...state,
        notificationUsers:action.notificationUsers
      }
    case 'set_notification_emails_manual':
      return {
        ...state,
        notificationEmailsManual:action.notificationEmailsManual
      }
    case 'set_notification_users_manual':
      return {
        ...state,
        notificationUsersManual:action.notificationUsersManual
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

function ImpactAssessment(props) {
  const {
    classes,
    theme,
    history,
    sessionData,
    direction
  } = props;

  const appName = direction==='downstream'?'Impact Assessment':'Dependency Assessment'
  const roles = getUserRoles(sessionData.user_role)

  const [state, dispatch] = useReducer(reducer, initialState);

  const onDownload = () => {
    dispatch({
      type:'set_download_modal_open',
      downloadModalOpen:true
    })
  }

  const onNotify = () => {
    dispatch({type:'set_tab_state',tabState:3})
  }

  const {
    data:sourceList,
    loading: sourcelistLoading,
    error: sourceListError
  } = useGetCerebrum({
    url:'/api/sources',
    params:{
      per_page:200,
      sort:'ALPHABETICAL'
    }
  })

  useEffect(()=>{
    if(!sourceList)return;
    dispatch({type:'set_source_list',sourceList:sourceList.items})
  },[sourceList])
  
  useEffect(()=>{
    if(state.forceTargetObject)return;
    dispatch({type:'set_target_object'})
    dispatch({type:'set_usage_window',usageWindow:-1})
    // eslint-disable-next-line
  },[state.source])

  useEffect(()=>{
    if(state.tabState!==2){
      // dispatch({type:'set_history_object'})
      dispatch({type:'set_result_tab_state',resultTabState:'DASHBOARD'})
    }
    if(state.tabState===1){
      addHistory({url:window.location.pathname+`?tabName=HISTORY`, iconLabel:'data_solution', title: 'History', subTitle:appName,type:'application'})
    }
  // eslint-disable-next-line
  },[state.tabState])

  useEffect(()=>{
    addHistory({url:window.location.pathname, iconLabel:'data_solution', title: 'Assessment Criteria', subTitle:appName,type:'application'})
    const urlSearch = new URLSearchParams(window.location.search);
    const tabName = urlSearch.get('tabName')
    if(tabName && tabName==='HISTORY')dispatch({type:'set_tab_state',tabState:1})
    // eslint-disable-next-line
  },[])

  const onClickRerun = () => {

    let filters = [];
    if(state.multiHistoryObjects){
      filters.push(`multiTargetId=${state.multiHistoryObjects.map(el=>el.id).join(',')}`)
    }else{
      filters.push(`targetObjectId=${state.historyObject.id}`)
    }
    filters.push(`assessmentStep=5`)
    filters.push(`assessmentUsageWindow=${state.extractData.args.filters.period}`)
    // filters.push(`assessmentDepth=${state.extractData.args.filters.max_depth}`)
    if(state.extractCollectionDetails?.length>0){
      filters.push(`criteriaIds=${state.extractCollectionDetails.map(el=>el.id).join(',')}`)
    }
    history.push(`${window.location.pathname}?${filters.join('&')}`)
  }

  if(sourcelistLoading){
    return (
      <div style={{ marginTop: 100,width:'100%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center' }}>
        <Typography style={{color:theme.palette.primaryText.main}}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if(sourceListError){
    return <DeadEnd/>
  }

  if(!sourceList || !state.sourceList)return <div></div>


  let buttons = [];
  let title,description,subTitle;
  if(state.tabState===0 || state.tabState===1){
    title=appName;
    description=(
      direction==='upstream'?
      'Use this application to understand upstream data asset dependencies':
      'Use this application to assess the impact of changing data and content to downstream data, content and users'
    )
    if(state.isRerun && state.extractData){
      description = `${state.sourceList.find(el=>el.id===state.historyObject.source_id_srt).name} (Last ${state.extractData.args.filters.period} days). Generated on ${moment(state.extractData.user_created_at).format('LLLL')}.`
    }
  }
  if(state.tabState===2){
    title=`${appName} Results`
   
    if(state.multiHistoryObjects){
      title = appName  
    }else if(state.historyObject){
      title = state.historyObject.name_txt
    }
    buttons.push(
      <ProfileButton
        onClick={() => onClickResultItem({label:state.historyObject.object_type_txt,id:state.historyObject.id,item:state.historyObject,history,newWindow:true})}
        iconLabel='open'
        text='OPEN PROFILE'
        testID='open-profile-button'
        disabled={!state.historyObject}
        tooltip={state.historyObject?'':"Disabled. Select a single target in the assessment scope"}
      />
    )

    buttons.push(
      <ProfileButton
        onClick={() => onDownload()}
        iconLabel='download'
        text='DOWNLOAD'
        testID='download-button'
      />
    )

    if(roles.includes('10')){
      buttons.push(
        <ProfileButton
          onClick={() => onNotify()}
          iconLabel='email'
          text='NOTIFY'
          testID='notify-button'
          disabled={!checkEmailConfigured() || !state.historyObject}
          tooltip={state.historyObject?(checkEmailConfigured()?'':'Email needs to be configured to send notifications'):'Disabled. Select a single target in the assessment scope'}
        />
      )
    }

    buttons.push(
      <ProfileButton
        onClick={() => handleShareClick(undefined,'Link to this Assessment copied to Clipboard')}
        text='SHARE'
        iconLabel='share'
        tooltip={'Share link to this assessment'}
      />
    )

    buttons.push(
      <DropdownButton
        iconLabel='dot_menu'
        tooltip="More actions"
        iconOnly={true}
        iconColour={theme.palette.primaryText.light}
        title={[direction==='downstream'?'IA options':'DA options']}
        optionList={[
          {onClick:onClickRerun,text:`Rerun ${appName}`,group:direction==='downstream'?'IA options':'DA options'}
        ].filter(el=>el)}
      />
    )
    
    subTitle=(
      <span>
        <span 
          className={classes.underlineOnHover} 
          onClick={()=>{
            let jobName = direction==='downstream'?'IMPACT ASSESSMENT':'DEPENDENCY ASSESSMENT'
            history.push(`/my_tasks?tabName=JOBS&jobName=${jobName}`)
          }}
        >
          {appName.toUpperCase()}
        </span>
      </span>
    )
    if(!state.multiHistoryObjects && state.historyObject && state.sourceList.find(s=>s.id===state.historyObject.source_id_srt)){
      description=state.historyObject.location_txt
    }
  }
  if(state.tabState===3){
    subTitle=(
      <span>
        <span 
          className={classes.underlineOnHover} 
          onClick={()=>{
            let jobName = direction==='downstream'?'IMPACT ASSESSMENT':'DEPENDENCY ASSESSMENT'
            history.push(`/my_tasks?tabName=JOBS&jobName=${jobName}`)
          }}
        >
          {appName.toUpperCase()}
        </span>
      </span>
    );
    title = `Notify impacted users of ${state.historyObject.name_txt}`;
    description=state.historyObject.location_txt
  }

  let bannerVisibility, bannerCause, bannerdisplayText;
  
  if (state.multiIAObjects?.length > 0 && state.tabState===0) {
    bannerdisplayText = `Multi ${appName} is limited to 25 objects. ${state.multiIASelectedIds.length} objects selected.${getKnowledgeBaseUrl()?' See more about limitations':''}`;
    bannerCause = 'ia_limit';
    bannerVisibility = 'visible'
  }

  return (
    <div className={classes.root}>
      {
        state.extractData && (state.historyObject || state.multiHistoryObjects )&&
        <DownloadModal
          state={state}
          extractData={state.extractData}
          targetObject={state.historyObject}
          targetObjects={state.multiHistoryObjects}
          setModalOpen={value=>dispatch({
            type:'set_download_modal_open',
            downloadModalOpen:value
          })}
          appName={appName}
          direction={direction}
          modalOpen={state.downloadModalOpen}
        />
      }
      <ProfileLayout
        noPadding
        disableHeaderAutoHeight={true}
        header={(
          <ProfileHeader
            title={title}
            history={history}
            subtitle={subTitle}
            // label='impact_assessment'
            buttons={buttons}
            description={description}
            minHeight={100}
            iconLabel={`ia_${direction}`}
            bannerVisibility={bannerVisibility}
            bannerCause={bannerCause}
            bannerdisplayText={bannerdisplayText}
          />
        )}
        body={
          <Body
            history={history}
            state={state}
            direction={direction}
            appName={appName}
            dispatch={dispatch}
          />
        }
      /> 
    </div>
  )

}

export default withTheme()(withStyles(styles)(ImpactAssessment));