import React,{useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withTheme, Typography, withStyles, Button, Modal, Paper, CircularProgress, InputBase } from '@material-ui/core';
import { getLabelPlural, handleShareClick, getIconComponent} from '../../../utilities'
import axiosCerebrum from '../../../axios-cerebrum'
import 'url-search-params-polyfill';
import KTooltip from '../KTooltip/KTooltip';

const styles = theme => ({
  formBody:{
    // minHeight:'30vh',
    paddingLeft:24,
    paddingBottom:40,
    paddingRight:24,
    paddingTop:0,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    overflow:'hidden',
    maxHeight:'80vh',
    width:600,
    maxWidth:'90vw',
  },
  customScroll:{
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.light
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb':{
      background: theme.palette.primaryText.light,
      borderRadius:4,
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover':{
      
    },
    '&::-webkit-scrollbar': {
      width: 5
    },
  },
  modalTitle:{
    color:theme.palette.header.main,
    fontSize:20,
  },
  inputBase:{
    ...theme.components.inputBase,
    width:"100%",
    height:56
  },
  sectionTitle:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:1,
    marginBottom:8
  },
  inlineIcon:{
    display:'inline-block',
    position:'relative',
    top:3,
    marginLeft:6
  }
})

function EmbedUrlModal(props) {

  const {
    theme,
    classes,
    state,
    modalOpen, 
    setModalOpen
  } = props;

  const [data, setData] = useState({})

  const getUrl = () => {
    setData({loading:true})
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(state.basicData.object.name)}/${state.basicData.id}/embeddedprofile/link`
      )
      .then(response=>{
        setData({link:response.data.embedded_profile_link})
      })
      .catch(error=>{
        console.log(error)
        setData({error:true})
      })
  }

  useEffect(()=>{
    if(!data.link && !data.loading && modalOpen){
      getUrl()
    }
  // eslint-disable-next-line
  },[modalOpen])

  const generateIframe = link => {
    return `<iframe src="${link}" style="position: fixed; width: 100%; height: 100%; border:none;" title="${state.basicData.name}"></iframe>`
  }

  let codeTooltip = 'Use this code where the profile is embedded using a URL only. Applicable to Tableau etc. Click See More above to learn more'
  let iframeTooltip = 'Use this link where the profile is embedded using an iframe. Applicable to Power BI etc. Click See More above to learn more'

  return (
    <Modal 
      open={modalOpen} 
      onClose={()=>setModalOpen(false)}
      disableBackdropClick={true}
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
      <Paper className={classes.formBody + ' ' + classes.customScroll}>
        <div style={{display:'flex',alignItems:'flex-start',paddingTop:24,position:'sticky',top:0,background:theme.palette.background.main}}>
          <div style={{flexGrow:1,marginRight:16,marginBottom:24}}>
            <Typography className={classes.modalTitle}>Embedded Profile code</Typography>
          </div>
          <Button color='primary' variant='outlined' style={{width:96}} onClick={()=>setModalOpen(false)}>CLOSE</Button>
        </div>
        <div>
          {
            data.loading && 
            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
              <CircularProgress color='secondary'/>
            </div>
          }
          {
            data.error && 
            <Typography>Error occurred generating the link</Typography>
          }
          {
            data.link && 
            <>
              <Typography style={{fontSize:16,marginBottom:24,whiteSpace:'pre-wrap'}}>
                Add the below code to your report or dashboard to embed a K profile. 
                {'\n'}
                The profile will display governance details from K.
                {'\n'}
                <span style={{textDecoration:'underline',cursor:"pointer"}}>See more</span> information about the embedded profile. 
                {'\n'}
                The profile requires a min width of 150px and a min height of 175px.
              </Typography>
              <Typography className={classes.sectionTitle}>
                URL ONLY <KTooltip title={codeTooltip}><div className={classes.inlineIcon}>{getIconComponent({label:"info",size:16,colour:theme.palette.primaryText.main})}</div></KTooltip>
              </Typography>
              <InputBase
                className={classes.inputBase}
                value={data.link}
                endAdornment={
                  <Button  
                    onClick={()=>{
                      handleShareClick(data.link, "Link copied to clipboard")
                    }}
                    color='primary'
                    style={{marginRight:8,marginLeft:8}}
                  >
                    COPY
                  </Button>
                }
              />
              <Typography className={classes.sectionTitle} style={{marginTop:24}}>
                HTML CODE <KTooltip title={iframeTooltip}><div className={classes.inlineIcon}>{getIconComponent({label:"info",size:16,colour:theme.palette.primaryText.main})}</div></KTooltip>
              </Typography>
              <InputBase
                className={classes.inputBase}
                value={generateIframe(data.link)}
                endAdornment={
                  <Button  
                    onClick={()=>{
                      handleShareClick(generateIframe(data.link), "Link copied to clipboard")
                    }}
                    color='primary'
                    style={{marginRight:8,marginLeft:8}}
                  >
                    COPY
                  </Button>
                }
              />
            </>
          }
        </div>
      </Paper>
    </Modal>
  )
}

EmbedUrlModal.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(EmbedUrlModal));