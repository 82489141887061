import React, { useState , useEffect, useRef} from 'react';
import { withTheme,withStyles, Typography, CircularProgress, Button, IconButton} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import moment from 'moment'
import KTooltip from '../../UI/KTooltip/KTooltip';
import { getIconComponent, isInViewport, mapObjectName, removeUrlQueryArg, toTitleCase } from '../../../utilities'
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import { getExtractResult } from '../../../utilities';
import ErrorModal from './ErrorModal';
import { getExtractDetails, getExtractName, getIdsToLoad } from '../../QuestionBoard/Utils/Utils';
import CancelModal from '../../PlatformManagement/Monitor/CancelModal';
import 'url-search-params-polyfill';
import DetailModal from './DetailModal';
import axiosSolr from '../../../axios-solr';
import useAlert from '../../../hooks/useAlert';
import WarningConfirmModal from '../../UI/ConfirmModals/WarningConfirmModal';

const styles = theme => ({
  // root: {
  //   display:'flex',
  //   overflow:'hidden'
  // },
  statusChip:{
    width:64,
    textAlign:'center',
    padding:'8px 12px',
    borderRadius:3,
    fontSize:13.75
  },
  tooltip:{
    fontSize:13.75
  },
  columnRow:{
    height:48,
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    display:'flex',
    alignItems:'center',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  titleRow:{
    display:'flex',
    overflow:'hidden'
  },
  tableTitle:{
    fontSize:12,
    letterSpacing:2,
    overflow:'hidden',
    color:theme.palette.primary.main,
  },
  iconButton:{
    padding:6,
  },
})

const Jobs = props => {

  const {
    classes,
    theme,
    state,
    sessionData,
    options,
    history,
    tabState, 
    setTabState
  } = props;

  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [detailsModalOpen, setDetailsModalOpen] = useState(false)

  const [rerunModalOpen, setRerunModalOpen] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)

  const [buttonHovered, setButtonHovered] = useState(false)

  const [idMap, setIdMap] = useState({})

  const [historyData, setHistoryData] = useState({loading:false,error:false});
  const unmounted = useRef()

  const urlSearch = new URLSearchParams(window.location.search);
  const jobName = urlSearch.get('jobName')

  const [autoRefreshCount, setAutoRefreshCount] = useState(0)
  const refreshInterval = useRef()
  const scrollRef = useRef();

  const {
    sendAlert
  } = useAlert({})

  const loadIDMap = async ids => {
    await axiosSolr
      .get(
        `/solr/search/select`,{
          params:{
            q:'*',
            fq:`id:(${ids.join(' OR ')})`,
            rows:ids.length
          }
        }
      )
      .then(response=>{
        let map = {}
        response.data.response.docs.forEach(doc=>{
          map[doc.id] = doc
        })
        setIdMap({...idMap,...map})
      })
      .catch(error=>{
        console.log(error)
      })
  }

  const loadHistory = ({page=1,autoRefresh=false,name=options[tabState].name}) => {
    if(!autoRefresh)setHistoryData({loading:true})
    if(page===1){
      setAutoRefreshCount()
      clearInterval(refreshInterval.current)
    }
    
    let promise;
    let type = options.find(el=>el.name===name).type
    if(['BULK EDIT','BULK UPDATE','COLLECTION BULK EDIT'].includes(name)){
      promise = (
        axiosCerebrum
          .get(
            `/api/jobexecutions`, 
            { params: {
              page,
              per_page:10,
              platform_job:type,
              submitted_by_id:sessionData.id,
              sort:'START_DESC'
            }}
          )
      )
    }else{
      promise = (
        axiosCerebrum
          .get(
            `/api/me/extracts`,
            {params:{
              per_page:10,
              sort:'START_DESC',
              page:page,
              status:'COMPLETE,FAILED,RUNNING,SUBMITTED,KILLED',
              type,
            }}
          )
      )
    }

    promise
      .then(async response=>{
        if(response.data.total===0){
          setHistoryData({loading:false,data:response.data})
        }else{
          let list = response.data.items

          // load id map
          let ids = [];
          list.forEach(l=>{
            ids.push(...getIdsToLoad({filters:l.args?.filters||{},currentMap:idMap}))
          })

          if(ids.length>0)await loadIDMap(ids)
          //
          setHistoryData({loading:false,data:{...response.data,items:page===1?list:[...historyData.data.items,...list]}})
          if(!unmounted.current && page===1){
            clearTimeout(refreshInterval.current)
            setAutoRefreshCount(30)
            let count = 30;
            refreshInterval.current = setInterval(()=>{
              count-=1;
              setAutoRefreshCount(count)
              if(count===0){
                setAutoRefreshCount()
                clearInterval(refreshInterval.current)
                loadHistory({page:1,autoRefresh:true,name})
              }
            },1000)
          }
        }
      })
      .catch(error=>{
        console.log(error);
        if(error.response && error.response.status && error.response.status===403){
          setHistoryData({loading:false,error:'You do not have permission to view this data'})
        }else{
          setHistoryData({loading:false,error:true})
        }
      })
  }

  useEffect(()=>{
    if(jobName){
      let jobIndex = options.findIndex(el=>el.name===jobName)
      if(jobIndex!==-1){
        setTabState(jobIndex)
        loadHistory({name:jobName})
        window.history.replaceState(null, null,removeUrlQueryArg({url:window.location.toString(),keys:['jobName']}));
      }else{
        loadHistory({})
      }
    }else{
      loadHistory({})
    }
    return ()=>{
      unmounted.current = true;
      clearInterval(refreshInterval.current)
    }
     // eslint-disable-next-line
  },[])

  const getIcon = extract => {
    let status = extract.job_status || extract.status
    if(status==='RUNNING'){
      return <div className={classes.statusChip} style={{background:'#FC642D',color:'white'}}>Running</div>
    }
    if(status==='COMPLETE'){
      return <div className={classes.statusChip} style={{background:'#48D46A',color:'black'}}>Complete</div>
    }
    if(status==='SUBMITTED'){
      return <div className={classes.statusChip} style={{background:'#FFAB00',color:'black'}}>Submitted</div>
    }
    if(status==='FAILED'){
      return <div className={classes.statusChip} style={{background:'#B00020',color:'white'}}>Failed</div>
    }
    if(status==='KILLED'){
      return <div className={classes.statusChip} style={{background:'#B00020',color:'white'}}>Cancelled</div>
    }
  }

  const getName = el => {
    if(options[tabState].name==='ASK K'){
      if(!el?.args?.lookup_code)return el.extract_template?.name || 'N/A';
      return getExtractName(el.args.lookup_code,{...el.args?.filters})
    }
    if(['COLLECTION BULK EDIT','BULK EDIT'].includes(options[tabState].name)){
      let totalMetrics = el.metrics?.find(m=>m.job_metric==='BULK_EDIT_EXCEL');
      let filename = totalMetrics?.args?.filename;
      if(!filename)filename = el.job_args?.[1]?.filename
      return filename || ''
    }
    if(options[tabState].name==='BULK EDIT FILE'){
      return el.args?.filters?.name || 'BULK EDIT FILE'
    }
    if(options[tabState].name==='COLLECTION BULK EDIT FILE'){
      let collectionId = el.args?.filters?.collection_id;
      let name = idMap[collectionId]?.name_txt || collectionId || 'COLLECTION BULK EDIT FILE'
      return name
    }
    if(options[tabState].name==='BULK UPDATE'){
      let name;
      try{
        name = el.job_args[1].object_ids.length + ' ' + toTitleCase(mapObjectName(el.job_args[1].object_type).replace(/_/g,' ')) + '(s)'
      }catch(error){
        name = 'BULK UPDATE'
      }
      return name
    }
    if(['IMPACT ASSESSMENT','DEPENDENCY ASSESSMENT'].includes(options[tabState].name)){
      let name;
      try{
        let filters = el.args.filters
        name = filters.object_ids.map(i=>idMap[i]?.name_txt || i).join(', ')
      }catch(error){
        name = ''
      }
      return name
    }
    if(options[tabState].name==='CODE SEARCH'){
      let name;
      try{
        name = 'Sources: ' + (el.args?.filters?.source_ids?.map(id=>state.sources?.find(s=>s.id===id)?.name || id).join(', ')) + ', Object Types: ' + el.args.filters.object_types.map(el=>toTitleCase(el)).join(', ') + ', Limit: ' + el.args.filters.limit + ', Search term: ' + el.args.filters.search_term 
      }catch(error){
        name = 'Code Search'
      }
      return name
    }
    return options[tabState].name
  }

  const getJobName = (el) => {
    let jobOptions = ['ASK K','CODE SEARCH','COLLECTION BULK EDIT FILE','BULK EDIT FILE'];
    if(['IMPACT ASSESSMENT','DEPENDENCY ASSESSMENT'].includes(options[tabState].name)){
      let isMultiObjecs = el.args.filters.object_ids.length>1;
      return `${isMultiObjecs?'multi target':'single target'} ${options[tabState].name.toLowerCase()}`
    }
    return `${options[tabState].name.toLowerCase()} ${jobOptions.includes(options[tabState].name)?'job':'extract'}`
  }

  const getDetails = (el, ellipsis) => {
    if(['IMPACT ASSESSMENT','DEPENDENCY ASSESSMENT'].includes(options[tabState].name)){
      let details;
      try{
        let filters = el.args.filters
        details = filters.period + ' Days'
        if(filters.collection_ids.length>0){
          details += ', Additional criteria(s): ' + filters.collection_ids.map(i=>idMap[i]?.name_txt || i).join(', ')
        }
      }catch(error){
        details = ''
      }
      return details
    }
    if(options[tabState].name==='ASK K'){
      return getExtractDetails(el.args?.filters || {}, ellipsis, el, state.sources||[], idMap)
    }
  }

  const getItemsUpdated = job => {
    let processedMetric = job.metrics?.find(m=>m.job_metric==='BULK_EDIT_PROCESSED_RECORDS');
    if(!processedMetric)processedMetric = job.metrics?.find(m=>m.job_metric==='COLLECTION_BULK_EDIT_PROCESSED_RECORDS');
    return processedMetric?.value || 0;
  }
  
  window.onscroll = () => {
    if(isInViewport(scrollRef) && historyData.data && historyData.data.page<historyData.data.pages && !historyData.loading){
      loadHistory({page:historyData.data.page+1})
      clearInterval( refreshInterval.current)
      setAutoRefreshCount()
    } 
  }

  useEffect(()=>{
    if(isInViewport(scrollRef) && historyData.data && historyData.data.page<historyData.data.pages && !historyData.loading){
      loadHistory({page:historyData.data.page+1})
    } 
  // eslint-disable-next-line
  },[historyData.data,historyData.loading])

  const onDownload = (id, fileName) => {
    sendAlert({type:'info',message:'Download will start soon'})
    getExtractResult({
      id,
      onSuccess:()=>{},
      onError:()=>{
        sendAlert({type:'error',message:'Failed to download the file'})
      },
      defaultFileName:fileName
    })
  }

  const onReRun = extract => {
    const payload = {
      ...extract.args,
      "ignore_cache":true
    }
    axiosCerebrum
      .post('/api/extracts',payload)
      .then(extractResponse=>{
        loadHistory({})
        setRerunModalOpen(false)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({type:'error',message:'Error occurred submitting the request, please try again'})
      })
  }

  const generateNavigationButton = () => {
    let buttonConfig;
    if(options[tabState].name==='ASK K'){
      buttonConfig = {
        text:'OPEN ASK K',
        onClick:() => history.push(`/question_board`)
      }
    }
    if(options[tabState].name==='CODE SEARCH'){
      buttonConfig = {
        text:'OPEN CODE SEARCH',
        onClick:() => history.push(`/code_search`)
      }
    }
    if(options[tabState].name==='IMPACT ASSESSMENT'){
      buttonConfig = {
        text:'OPEN IA',
        onClick:() => history.push(`/impact_assessment`)
      }
    }
    if(options[tabState].name==='DEPENDENCY ASSESSMENT'){
      buttonConfig = {
        text:'OPEN DA',
        onClick:() => history.push(`/dependency_assessment`)
      }
    }
    if(['COLLECTION BULK EDIT FILE','COLLECTION BULK EDIT'].includes(options[tabState].name)){
      buttonConfig = {
        text:'OPEN COLLECTIONS',
        onClick:() => history.push(`/admin/collection?tabName=IMPORT`)
      }
    }

    if(buttonConfig){
      return <Button style={{height:42}} onClick={buttonConfig.onClick} color='primary' variant='outlined'>{buttonConfig.text}</Button>
    }
  }

  const generateAction = el => {
    let actions = [];
    if(options[tabState].name==='ASK K'){
      if(el.status==='COMPLETE'){
        actions.push({
          label:'download',
          tooltip:'Download results',
          onClick:()=>{onDownload(el.id,`Ask_K_${getExtractName(el.args.lookup_code,{...el.args.filters})}_${moment().format('DD_MM_YYYY')}.csv`)}
        })
      }
    }
    if(['BULK UPDATE','BULK EDIT','COLLECTION BULK EDIT'].includes(options[tabState].name)){
      if(el.job_status==='COMPLETE' && el.job_args[1]){
        actions.push({
          label:'info',
          tooltip:'View details',
          onClick:()=>setDetailsModalOpen({details:el.job_args[1]})
        })
      }
    }
    if(options[tabState].name==='IMPACT ASSESSMENT'){
      if(el.status==='COMPLETE'){
        actions.push({
          label:'OPEN',
          tooltip:'View results',
          onClick:()=>window.open(`/impact_assessment?extractId=${el.id}`,'_blank')
        })
      }
    }
    if(options[tabState].name==='DEPENDENCY ASSESSMENT'){
      if(el.status==='COMPLETE'){
        actions.push({
          label:'OPEN',
          tooltip:'View results',
          onClick:()=>window.open(`/dependency_assessment?extractId=${el.id}`,'_blank')
        })
      }
    }
    if(options[tabState].name==='CODE SEARCH'){
      if(el.status==='COMPLETE'){
        actions.push({
          label:'download',
          tooltip:'Download results',
          onClick:()=>{onDownload(el.id,`code_search_results_${el.args?.filters?.search_term}.csv`)}
        })
      }
    }
    if(['COLLECTION BULK EDIT FILE','BULK EDIT FILE'].includes(options[tabState].name)){
      let name = `K_bulk_edit_template_${moment().format('DD_MM_YYYY')}.xlsx`
      if(options[tabState].name==='COLLECTION BULK EDIT FILE'){
        let collectionName = idMap[el.args.filters.collection_id]?.name_txt || el.args.filters.collection_id
        name = `K_${collectionName}_collection_bulk_edit_template_${moment().format('DD_MM_YYYY')}.xlsx`
      }
      actions.push({
        label:'download',
        tooltip:'Download file',
        disabled:el.status!=='COMPLETE',
        onClick:()=>{onDownload(el.id,name)}
      })
      if(options[tabState].name==='COLLECTION BULK EDIT FILE'){
        actions.push({
          label:'open',
          tooltip:'Open profile',
          onClick:()=>history.push(`/profile/collection/${el.args.filters.collection_id}`)
        })
      }
    }
    ////////////////
    if(el.job_status==='FAILED' && el.error ){
      actions.push({
        label:'info',
        tooltip:'Error details',
        onClick:()=>setErrorModalOpen({error:el.error })
      })
    }
    else if(el.status==='FAILED' && el.job_execution?.error){
      actions.push({
        label:'info',
        tooltip:'Error details',
        onClick:()=>setErrorModalOpen({error:el.job_execution.error})
      })
    }

    if(options[tabState].name==='COLLECTION BULK EDIT'){
      actions.push({
        label:'open',
        tooltip:'Open profile',
        onClick:()=>history.push(`/profile/collection/${el.job_args?.[1]?.id}`)
      })
    }
    ////////////////
    if(
      ['FAILED','KILLED'].includes(el.status) || 
      ['ASK K','CODE SEARCH','IMPACT ASSESSMENT','DEPENDENCY ASSESSMENT'].includes(options[tabState].name)
    ){
      if(!['RUNNING','SUBMITTED'].includes(el.status) && !['COLLECTION BULK EDIT FILE','BULK EDIT FILE'].includes(options[tabState].name)){
        actions.push({
          label:'refresh',
          tooltip:'Rerun Job',
          onClick:()=>setRerunModalOpen({extract:el})
        })
      }
    }
    /////////////
    if(el.status==='RUNNING' || el.job_status==='RUNNING'){
      actions.push({
        label:'clear_circle',
        tooltip:'Cancel Job',
        onClick:()=>setCancelModalOpen({type:'single',item:el})
      })
    }
    ///////////
    return actions
  }

  let isIA = ['IMPACT ASSESSMENT','DEPENDENCY ASSESSMENT'].includes(options[tabState].name)
  let isCodeSearch = options[tabState].name==='CODE SEARCH'
  let isAskK = options[tabState].name==='ASK K'
  let isTemplateBulkEdit = ['COLLECTION BULK EDIT','BULK EDIT'].includes(options[tabState].name)

  let taskNaming = 'tasks';
  if(['ASK K','CODE SEARCH','BULK EDIT FILE','COLLECTION BULK EDIT FILE'].includes(options[tabState].name))taskNaming = 'extracts';
  if(['IMPACT ASSESSMENT','DEPENDENCY ASSESSMENT'].includes(options[tabState].name))taskNaming = 'assessments';
  if(['IMPACT ASSESSMENT','DEPENDENCY ASSESSMENT'].includes(options[tabState].name))taskNaming = 'assessments';

  return (
    <div className={classes.root}>
      <div style={{float:'left'}}>
        <VerticalTabBar
          tabOptions={options.map(el=>el.name)}
          tabState={tabState}
          setTabState={value=>{
            setAutoRefreshCount()
            loadHistory({page:1,name:options[value].name})
            setTabState(value)
          }}
        />
      </div>
      <div style={{marginLeft:280,}}>
        <div style={{zIndex:9,background:theme.palette.background.main,paddingBottom:16,position:'sticky',top:221}}>
          <div style={{display:'flex',alignItems:'flex-start',marginBottom:24,marginTop:16}}>
            <div style={{flexGrow:1}}>
              <Typography  style={{fontSize:20,color:theme.palette.header.main,}}>
                MY {options[tabState].name}
              </Typography>
              <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:4}}>
                Recent {toTitleCase(options[tabState].name.toLowerCase())}s generated in K
              </Typography>
            </div>
            {
              generateNavigationButton()
            }
            <Button color='primary' style={{height:42,marginLeft:24,flexShrink:0,background:autoRefreshCount?theme.palette.success.main:undefined,color:autoRefreshCount?'#000':undefined}} variant='contained' onClick={()=>loadHistory({})}>
              REFRESH {autoRefreshCount && `(${autoRefreshCount})`}
            </Button>
          </div>
          {
            historyData.data && historyData.data.total>0 &&
            <div className={classes.titleRow}>
              <div className={classes.tableTitle} style={{width:120,flexShrink:0}}>
                STATUS
              </div>
              <div className={classes.tableTitle}  style={{flexGrow:1,width:250,minWidth:120,paddingRight:16}}>
                {
                  isIA?
                  'TARGET':
                  isCodeSearch?
                    'DETAILS':
                    'NAME'
                }
              </div>
              {
                (isIA || isAskK) &&
                <div className={classes.tableTitle}  style={{flexGrow:1,width:300,minWidth:120,paddingRight:16}}>
                  DETAILS
                </div>
              }
              <div className={classes.tableTitle}  style={{width:230,flexShrink:0}}>
                STARTED ON
              </div>
              {
                isTemplateBulkEdit &&
                <div className={classes.tableTitle}  style={{width:100,flexShrink:0}}>
                  ITEMS UPDATED
                </div>
              }
              <div className={classes.tableTitle}  style={{width:100,flexShrink:0}}>
                ACTIONS
              </div>
            </div>
          }
        </div>
        {
          historyData.error && 
          <Typography style={{color:theme.palette.primaryText.main}}>
            {
              typeof(historyData.error)==='string'?
              historyData.error:
              `Error occurred loading ${taskNaming}`
            }
            
          </Typography>
        }
        {
          historyData.data && historyData.data.total===0 && 
          <Typography style={{color:theme.palette.primaryText.main}}>No {taskNaming} found</Typography>
        }
        {
          historyData.data && historyData.data.items.map(el=>(
            <div className={classes.columnRow} style={{background:buttonHovered?theme.palette.background.main:undefined}} data-test-classname="job-list-item">
              <KTooltip classes={{tooltip:classes.tooltip}} title={(el.status || el.job_status).toLowerCase()}>
                <div style={{width:120,display:'flex',alignItems:'center',flexShrink:0}}>
                  {getIcon(el)}
                </div>
              </KTooltip>
              <KTooltip title={getName(el)}>
                <div style={{flexGrow:1,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',paddingRight:16,minWidth:120,width:250}}>  
                  {getName(el)}
                </div>
              </KTooltip>
              {
                (isIA || isAskK) &&
                <KTooltip title={getDetails(el,false)}>
                  <div style={{flexGrow:1,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',paddingRight:16,minWidth:120,width:300}}>
                    {getDetails(el,true)}
                  </div>
                </KTooltip>
              }
              <div style={{width:230,flexShrink:0,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                {moment(el.user_created_at || el.created_at).format('llll')}
              </div>
              {
                isTemplateBulkEdit &&
                <div style={{width:100,flexShrink:0,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                  {getItemsUpdated(el)}
                </div>
              }
              <div style={{width:100,flexShrink:0,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                {
                  generateAction(el).map(a=>(
                    <KTooltip title={a.tooltip}>
                      <IconButton
                        className={classes.iconButton}
                        onClick={a.onClick}
                        data-test-classname={a.label}
                        disabled={a.disabled}
                        onMouseEnter={()=>setButtonHovered(true)}
                        onMouseLeave={()=>setButtonHovered(false)}
                      >
                        {getIconComponent({label:a.label,size:20,colour:theme.palette.primaryText.light})}
                      </IconButton>
                    </KTooltip>
                  ))
                }
              </div>
            </div>
          ))
        }
        <div ref={scrollRef} style={{marginBottom:40,display:'flex',justifyContent:'center'}}>
          {
            historyData.loading && 
            <CircularProgress color='secondary'/>
          }
        </div>
      </div>
      <ErrorModal
        modalOpen={errorModalOpen}
        setModalOpen={setErrorModalOpen}
      />
      <CancelModal
        modalOpen={cancelModalOpen}
        setModalOpen={setCancelModalOpen}
        loadMoreHistory={loadHistory}
      />
      <DetailModal
        modalOpen={detailsModalOpen}
        setModalOpen={setDetailsModalOpen}
      />
      <WarningConfirmModal
        description={
          `You are about to rerun the ${rerunModalOpen.extract?getJobName(rerunModalOpen.extract):''}.\n\nThis will remove the previous result.`
        }
        setModalOpen={setRerunModalOpen}
        modalOpen={rerunModalOpen}
        onConfirm={()=>onReRun(rerunModalOpen.extract)}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
        alertMessage={'Error occurred running the job, please try again'}
        confirmText={'YES'}
      />
    </div>  
  )
}

export default withTheme()(withStyles(styles)(Jobs));