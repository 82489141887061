import React, { useEffect, useRef } from 'react';
import { withStyles, withTheme, Button} from '@material-ui/core';
import AssessmentDetails from '../AssessmentDetails/AssessmentDetails'
import Results from '../Results/Results'
import axiosCerebrum from '../../../axios-cerebrum'
import axiosSolr from '../../../axios-solr'
import TabBar from '../../UI/TabBar/TabBar'
import History from '../History/History'
import 'url-search-params-polyfill';
import NotifyBody from '../Notify/Body/Body'
import { mapSearchObjectName, removeUrlQueryArg, sendMessage } from '../../../utilities';
import { addHistory } from '../../../HistoryManager';
import MultiAssessmentDetails from '../MultiAssessmentDetails/MultiAssessmentDetails';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root: {

  },
  buttons:{
    zIndex:999,
    position:'fixed',
    backgroundColor:theme.palette.background.main,
    borderTop:`1px solid ${theme.palette.border.main}`,
    height:80,
    bottom:0,
    left:40,
    width:'100vw',
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end',
    boxShadow:'0px -1px 5px 0px #DDD'
  },
})

const Body = props => {

  const {
    classes,
    history,
    theme,
    state,
    dispatch,
    direction,
    appName
  } = props;


  const urlSearch = new URLSearchParams(window.location.search);
  // const changeId = urlSearch.get('changeId');
  const targetObjectId = urlSearch.get('targetObjectId');
  const multiTargetId = urlSearch.get('multiTargetId');
  const extractId = urlSearch.get('extractId');
  const criteriaIds = urlSearch.get('criteriaIds')
  const assessmentStep = urlSearch.get('assessmentStep')
  const assessmentUsageWindow = urlSearch.get(`assessmentUsageWindow`)
  // const assessmentDepth = urlSearch.get('assessmentDepth')

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  useEffect(()=>{
    if(assessmentStep)dispatch({type:'set_is_rerun',isRerun:true})
    // eslint-disable-next-line
  },[])

  const onRun = () => {
    const payload = {
      "domain": document.location.protocol + "//" + document.location.hostname,
      "filters": {
        "object_ids": state.multiIASelectedIds?.length>0?state.multiIASelectedIds:[state.targetObject.id],
        "period": state.usageWindow,
        "collection_ids": state.additionalCriteria.map(el=>el.id)
      },
      // "ignore_cache":state.isRerun || Boolean(changeId && targetObjectId),
      "ignore_cache":state.isRerun,
      "lookup_code": `ia_${direction}`,
      "type":`IMPACT_ANALYSIS_${direction.toUpperCase()}`,
    }
    axiosCerebrum
      .post('/api/extracts',payload)
      .then(extractResponse=>{
        if(state.isClearCart && state.multiIASelectedIds){
          axiosCerebrum 
            .delete(
              `/api/me/cart?object_ids=${state.multiIASelectedIds.join(',')}`
            )
            .then(()=>{
              sendMessage({reloadCart:true})
            })
        }
        window.history.replaceState(null, null,removeUrlQueryArg({url:window.location.toString(),keys:['assessmentStep','assessmentUsageWindow','criteriaIds','multiTargetId','targetObjectId']}))
        dispatch({type:'set_is_rerun',isRerun:false})
        // dispatch({type:'set_tab_state',tabState:1})
        let jobName = direction==='downstream'?'IMPACT ASSESSMENT':'DEPENDENCY ASSESSMENT'
        history.push(`/my_tasks?tabName=JOBS&jobName=${jobName}`)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({message:'Error occurred submitting the request, please try again',type:'error'})
      })
  } 

  useEffect(()=>{
    if(targetObjectId){
      dispatch({type:'set_force_target_object',forceTargetObject:true});
      axiosSolr
        .get(
          `/solr/search/select`,
          {params:{q:"*",fq:`id:${targetObjectId}`,fl:'*'}}
        )
        .then(solrRes=>{
          if(solrRes.data.response.docs.length===1){
            let item = solrRes.data.response.docs[0];
            dispatch({type:'set_source',source:item.source_id_srt});
            dispatch({type:'set_target_object',targetObject:item});
            dispatch({type:'set_object_type',objectType:mapSearchObjectName(item.object_type_txt)});
            dispatch({type:'set_setting_step',settingStep:assessmentStep?Number(assessmentStep).valueOf():3})
          }else{
            throw new Error('Object not found')
          }
        })
        .catch(error=>{
          console.log(error);
          sendAlert({message:'Target object not found',type:'error'})
        })
    }
    if(criteriaIds){
      axiosSolr
        .get(
          `/solr/search/select`,
          {params:{q:"*",fq:`id:(${criteriaIds.split(',').join(' OR ')})`,fl:'*'}}
        )
        .then(solrRes=>{
          dispatch({type:'set_additional_criteria',additionalCriteria:solrRes.data.response.docs})
        })
        .catch(error=>{
          console.log(error);
        })
    }
    // if(assessmentDepth){
    //   try{
    //     dispatch({type:'set_depth_limit',depthLimit:Number(assessmentDepth).valueOf()})
    //   }catch(error){
    //     console.log(error)
    //   }
    // }
    if(assessmentUsageWindow){
      try{
        dispatch({type:'set_usage_window',usageWindow:Number(assessmentUsageWindow).valueOf()})
      }catch(error){
        console.log(error)
      }
    }
    else if(extractId){
      dispatch({type:'reset_result'})
      dispatch({type:'set_extract_data',extractLoading:true})
      let url = window.location.pathname+`?extractId=${extractId}`
      if(url!==window.location.pathname+window.location.search){
        history.push(url);
        dispatch({type:'set_history_object'})
      }
      dispatch({type:'set_tab_state',tabState:2})
      axiosCerebrum
        .get(
          `/api/extracts`,
          {params:{
            id:extractId,
            per_page:1,
            status:'COMPLETE',
            type:`IMPACT_ANALYSIS_${direction.toUpperCase()}`,
          }}
        )
        .then(async response=>{
          let extract = response.data.items[0]
          if(!extract){
            sendAlert({message:'Extract not found or unavailable',type:'error'})
            dispatch({type:'set_tab_state',tabState:1})
            return;
          }
          let objectIds = extract.args.filters.object_ids;
          let targetDetail;
          if(objectIds){
            await axiosSolr
              .post(
                `/solr/search/select`,
                {params:{
                  q:"*",
                  fq:`id:(${objectIds.join(' OR ')})`,
                  rows:objectIds.length,
                  start:0,
                  fl:'*',
                }}
              )
              .then(solrRes=>{
                targetDetail = solrRes.data.response.docs
              })
              .catch(error=>{
                console.log(error)
              })
          }
          let finalExtractData = {...extract, target_objects:targetDetail}
          if(targetDetail.length<=1){
            dispatch({type:'set_history_object',historyObject:targetDetail[0]})
          }else{
            dispatch({type:'set_multi_history_objects',multiHistoryObjects:targetDetail})
          }
          dispatch({type:'set_extract_data',extractData:finalExtractData})
          addHistory({url, iconLabel:'data_solution', title: targetDetail.map(el=>el.name_txt).join(', '), subTitle:`${appName} Result`,type:'application'})
        })
        .catch(error=>{
          console.log(error)
          dispatch({type:'set_extract_data'})
          sendAlert({message:'Extract not found or unavailable',type:'error'})
          dispatch({type:'set_tab_state',tabState:1})
        })
      }
    // eslint-disable-next-line
  },[targetObjectId, extractId])

  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.tabState])

  let bodyContent;
  switch (state.tabState) {
    case 0: // DETAILS
      if(multiTargetId){
        bodyContent = (
          <MultiAssessmentDetails
            history={history}
            state={state}
            dispatch={dispatch}
            presetObjectIDs={multiTargetId.split(',')}
            onRun={onRun}
          />
        )
      }else{
        bodyContent = (
          <AssessmentDetails
            history={history}
            state={state}
            dispatch={dispatch}
            onRun={onRun}
          />
        )
      }
      break;
    case 1:
      bodyContent = (
        <History
          history={history}
          state={state}
          dispatch={dispatch}
          direction={direction}
        />
      )
      break;
    case 2: // RESULTS
      bodyContent = (
        <Results
          history={history}
          state={state}
          dispatch={dispatch}
          direction={direction}
        />
      )
      break;
    case 3: // notify
      bodyContent = (
        <NotifyBody
          state={state}
          dispatch={dispatch}
          history={history}
        />
      )
      break;
    default:
  }

  const onReset = () => {
    dispatch({type:'reset_setting'})
    dispatch({type:'set_is_rerun',isRerun:false})
  }


  return (
    <div className={classes.root}>
      {
        [0,1].includes(state.tabState) && (!multiTargetId || state.tabState!==0) &&
        <div style={{marginLeft:-56}}>
          <TabBar
            tabOptions={['NEW','HISTORY']}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        </div>
      }
      {bodyContent}
      {
        state.tabState===0 && !multiTargetId &&
        <div style={{ left:0,right: 0,zIndex:999, height:80,borderTop:`1px solid ${theme.palette.listItemDivider.main}`, backgroundColor: theme.palette.background.main, position: 'fixed', bottom: 0,display:'flex',alignItems:'center',justifyContent:'flex-end' }}>
          {
            !targetObjectId && 
            <Button style={{marginLeft:'60%',width:120,height:48,color:theme.palette.primaryText.light}} data-testid = 'reset' onClick={onReset}>
              RESET
            </Button>
          }
          <Button color='primary' variant='contained' style={{marginLeft:30,width:120,height:48,marginRight:'15%'}} data-testid = 'close' onClick={()=>state.onClose?state.onClose():history.push('/data_solutions')}>
            CLOSE
          </Button>
        </div>
      }
      
    </div>
  )
}

export default withTheme()(withStyles(styles)(Body));