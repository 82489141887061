import React, { useEffect } from 'react';
import { Route, Switch, Redirect, withRouter} from 'react-router-dom';
import Layout from './components/Layout/Layout';
import TableProfile from './containers/TableProflie/TableProfile';
import DatabaseProfile from './containers/DatabaseProfile/DatabaseProfile';
import ProcedureProfile from './containers/ProcedureProflie/ProcedureProfile';
import UserProfile from './containers/UserProfile/UserProfile';
import ColumnProfile from './containers/ColumnProfile/ColumnProfile';
import QueryProfile from './containers/QueryProfile/QueryProfile';
import ImpactAssessment from './containers/ImpactAssessment/ImpactAssessment'
import UserHomeProfile from './containers/UserHomeProfile/UserHomeProfile';
import IssueProfile from './containers/IssueProfile/IssueProfile';
import KeyCloakLogin from './containers/KeyCloakLogin/KeyCloakLogin';
import UserOnboard from './containers/UserOnboard/UserOnboard';
import SchemaProfile from './containers/SchemaProflie/SchemaProfile';
import OnBoardSource from './containers/OnBoardSource/OnBoardSource';
import Teams from './containers/Teams/Teams';
import ToolProfile from './containers/ToolProfile/ToolProfile';
import QuestionBoard from './containers/QuestionBoard/QuestionBoard'
//import CreateTeam from './containers/CreateTeam/CreateTeam';
import CreateCollection from './containers/CreateCollection/CreateCollection';
import AutoCollectionProfile from './containers/AutoCollectionInstanceProfile/AutoCollectionInstanceProfile';
import Settings from './containers/Settings/Settings'
import Grid from './containers/Grid/Grid';
import CollectionAdmin from './containers/CollectionAdmin/CollectionAdmin';
import ManualCollectionProfile from './containers/ManualCollectionInstanceProfile/ManualCollectionInstanceProfile';
// import UserIDs from './containers/UserIDs/UserIDs'
import AddKnowledge from './containers/AddKnowledge/AddKnowledge';
import PlatformManagement from './containers/PlatformManagement/PlatformManagement';
// import CreateSurvey from './containers/CreateSurvey/CreateSurvey'
import NoRoleDeadEnd from './components/Generic/Page/NoRoleDeadEnd'
import SourceProfile from './containers/SourceProfile/SourceProfile'
import FileProfile from './containers/FileProfile/FileProfile'
import StorageProfile from './containers/StorageProfile/StorageProfile'
import ContentProfile from './containers/ContentProfile/ContentProfile'
import ContentChildProfile from './containers/ContentChildProfile/ContentChildProfile'
import DataPipelineProfile from './containers/DataPipelineProfile/DataPipelineProfile'
import DatasetProfile from './containers/DatasetProfile/DatasetProfile'
import DeadEnd from './components/Generic/Page/DeadEnd'
import BulkEdit from './containers/BulkEdit/BulkEdit'
import InstanceLinking from './containers/InstanceLinking/InstanceLinking'
import MyEcosystem from './containers/MyEcosystem/MyEcosystem'
import Integrations from './containers/Integrations/Integrations'

import Alert from 'react-s-alert';

import { connect } from 'react-redux';

import TeamProfile from './containers/TeamProfile/TeamProfile';
import MachineLearningModelProfile from './containers/MachineLearningModelProfile/MachineLearningModelProfile';
import MacroProfile from './containers/MacroProflie/MacroProfile';
import BlackList from './containers/BlackList/BlackList';
import NewTagProfile from './containers/NewTagProfile/NewTagProfile';
import CollectionProfile from './containers/CollectionProfile/CollectionProfile'
import BasicSearch from './containers/BasicSearch/BasicSearch'

import keycloakInstance from './keycloak-instance';

import { getUserRoles, setHelpWdigetVisibility } from './utilities'
import AccessKey from './containers/AccessKey/AccessKey';
import LineageExplorer from './containers/LineageExplorer/LineageExplorer';
import MergeItems from './containers/MergeItems/MergeItems';
import KPlatformSetup from './containers/KPlatformSetup/KPlatformSetup';
import Discover from './containers/Discover/Discover';

// import { IntroManagerMain } from './components/UI/IntroManager/IntroManager'
import DQTestProfile from './containers/DQTestProfile/DQTestProfile';

import CollectionInstanceProfile from './containers/CollectionInstanceProfile/CollectionInstanceProfile';
import { clearDetail } from './components/UI/InteractiveInput/Templates';
import ContentAppProfile from './containers/ContentAppProfile/ContentAppProfile';
import RoleManager from './containers/RoleManager/RoleManager';
import DatasetTableProfile from './containers/DatasetTableProfile/DatasetTableProfile';
import DatasetFieldProfile from './containers/DatasetFieldProfile/DatasetFieldProfile';
import Inventory from './containers/Inventory/Inventory';
import Cart from './containers/Cart/Cart'
import { checkCanAddToCart, checkCanUploadBulkFile, checkIsBusinessUserOnly } from './permissionChecker';
import BulkEditTemplated from './containers/BulkEditTemplated/BulkEditTemplated';
import CustomerEcosystem from './containers/CustomerEcosystem/CustomerEcosystem';
import { globalListenerRef } from './GlobalListenerRef';
import OwnerInsightsDashboard from './containers/OwnerInsightsDashboard/OwnerInsightsDashboard';
import { palette } from './theme';
import FilterList from './containers/FilterList/FilterList';
import DataQualityInsights from './containers/DataQualityInsights/DataQualityInsights';
import CodeSearch from './containers/CodeSearch/CodeSearch';
import WorkSpaceProfile from './containers/WorkSpaceProfile/WorkSpaceProfile';
import DataProducts from './containers/DataProducts/DataProducts';
import MyTask from './containers/MyTask/MyTask';
import UserManagement from './containers/UserManagement/UserManagement';
import MyAlerts from './containers/MyAlerts/MyAlerts';
import DataRiskDashboard from './containers/DataRiskDashboard/DataRiskDashboard';
import DataClassificationDashboard from './containers/DataClassificationDashboard/DataClassificationDashboard';
import UserRiskDashboard from './containers/UserRiskDashboard/UserRiskDashboard';

const App = (props) => {

  const [isDarkStatus, setIsDarkStatus] = React.useState();

  useEffect(()=>{
    // if(window.location.pathname==='/basic_search')return;
    window.scrollTo({top: 0, left:0, behavior: 'smooth'});
    clearDetail();
  })
  
  // useEffect(()=>{
  //   if(!props.session_user)return;
  //   if(!localStorage.hasOwnProperty('intro_finished')){
  //     let roles = [];
  //     if(hasToken && props.session_user && props.session_user.user_role){
  //       roles = getUserRoles(props.session_user.user_role);
  //     }
  //     if(roles.length>0){
  //       IntroManagerMain(props.history, props.session_user)
  //     }
  //   }
  // // eslint-disable-next-line
  // },[props.session_user])

  useEffect(()=>{
    setHelpWdigetVisibility(false)
    document.body.style.background = palette.background.main;
    setIsDarkStatus(localStorage.hasOwnProperty('dark')?true:false)
  // eslint-disable-next-line
  },[])
  
  useEffect(()=>{
    window.removeEventListener('focus',globalListenerRef.rootOnLoad)
    globalListenerRef.rootOnLoad = () => {
      let currentIsDark = localStorage.hasOwnProperty('dark')?true:false;
      if(isDarkStatus!==currentIsDark){
        setIsDarkStatus(currentIsDark)
        window.location.reload();
      }
    }
    window.addEventListener('focus',globalListenerRef.rootOnLoad)
    return ()=>{
      window.removeEventListener('focus',globalListenerRef.rootOnLoad)
    }
  },[isDarkStatus])

  let hasToken = Boolean(keycloakInstance.token);
  let roles = [];
  if(hasToken && props.session_user && props.session_user.user_role){
    roles = getUserRoles(props.session_user.user_role);
  }
  
  return (
    hasToken
      ? 
      <Layout session_user={props.session_user} >
        {
            roles.length>0 ?
            <Switch>
            {
              (props.session_user.new_user || localStorage.hasOwnProperty('demo')) && 
              <Route path="/user_onboard" render={(p) => <UserOnboard history={props.history} match={p.match}  sessionData={props.session_user} />} />
            }
            {
              props.session_user.new_user && 
              <Redirect from="*" to="/user_onboard" />
            }
            
            {/* { */}
            <Route path="/inventory" render={(p) => <Inventory history={props.history} sessionData={props.session_user} />} />
            {/* } */}
            <Route path="/profile/data_quality_test/:id" render={(p) => <DQTestProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path="/profile/table/:id" render={(p) => <TableProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path="/profile/schema/:id" render={(p) => <SchemaProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path="/profile/column/:id" render={(p) => <ColumnProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path="/profile/user/:id" render={(p) => <UserProfile key={window.location.pathname} history={props.history} match={p.match} />} />
            <Route path="/profile/database/:id" render={(p) => <DatabaseProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path="/profile/tag/:id" render={(p) => <NewTagProfile key={window.location.pathname} label='basictag' history={props.history} match={p.match} sessionData={props.session_user}/>} />
            <Route path="/profile/domain/:id" render={(p) => < NewTagProfile key={window.location.pathname} label='domain' history={props.history} match={p.match} sessionData={props.session_user}/>} />
            <Route path="/profile/query/:id" render={(p) => <QueryProfile key={window.location.pathname} history={props.history} label='query' match={p.match} sessionData={props.session_user} />} />
            <Route path="/profile/tool/:id" render={(p) => <ToolProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path="/profile/workspace/:id" render={(p) => <WorkSpaceProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path="/profile/group/:id" render={(p) => <TeamProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            {
              !checkIsBusinessUserOnly({sessionData:props.session_user}) &&
              <Route path="/impact_assessment" render={(p) => <ImpactAssessment key={window.location.href} direction="downstream" history={props.history} sessionData={props.session_user} />} />
            }
            {
              !checkIsBusinessUserOnly({sessionData:props.session_user}) &&
              <Route path="/dependency_assessment" render={(p) => <ImpactAssessment key={window.location.href} direction="upstream" history={props.history} sessionData={props.session_user} />} />
            }
            <Route path="/code_search" render={(p) => <CodeSearch key={window.location.pathname} history={props.history} sessionData={props.session_user} />} />
            <Route path="/profile/ml_model/:id" render={(p) => <MachineLearningModelProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path="/profile/content_app/:id" render={(p) => <ContentAppProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path="/profile/macro/:id" render={(p) => <MacroProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path="/profile/procedure/:id" render={(p) => <ProcedureProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path='/profile/issue/:id' render={(p) => <IssueProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path='/profile/source/:id' render={(p) => <SourceProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path='/profile/file/:id' render={(p) => <FileProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path='/profile/storage/:id' render={(p) => <StorageProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path='/profile/content/:id' render={(p) => <ContentProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path='/profile/content_child/:id' render={(p) => <ContentChildProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path='/profile/data_pipeline/:id' render={(p) => <DataPipelineProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path='/profile/dataset/:id' render={(p) => <DatasetProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path='/profile/dataset_table/:id' render={(p) => <DatasetTableProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path='/profile/dataset_field/:id' render={(p) => <DatasetFieldProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path="/my_ecosystem" render={(p) => <CustomerEcosystem history={props.history} match={p.match}  sessionData={props.session_user}/>} />
            <Route path='/my_data' render={(p) => <MyEcosystem key={window.location.pathname} history={props.history} sessionData={props.session_user} />} />
            <Route path='/my_tasks' render={(p) => <MyTask key={window.location.href} history={props.history} sessionData={props.session_user} />} />
            <Route path='/discover' render={(p) => <Discover key={window.location.pathname} history={props.history} sessionData={props.session_user} />} />
            <Route path='/my_alerts' render={(p) => <MyAlerts key={window.location.pathname} history={props.history} sessionData={props.session_user} variant={'user'}/>} />
            {
              checkCanAddToCart({sessionData:props.session_user,ignoreObjectType:true}) && 
              <Route path='/cart' render={(p) => <Cart key={window.location.pathname} history={props.history} sessionData={props.session_user} />} />
            }
            {
              checkCanUploadBulkFile({sessionData:props.session_user}) && 
              <Route path='/bulk_edit_templated' render={(p) => <BulkEditTemplated key={window.location.pathname} history={props.history} sessionData={props.session_user} />} />
            }
            <Route path='/bulk_edit' render={(p) => <BulkEdit key={window.location.pathname} history={props.history} sessionData={props.session_user} />} />
            <Route 
              path='/profile/collection/:category/:label/:id' 
              render={(p) => {             
                if(window.location.pathname.includes('/collection/automated/')){
                  return <AutoCollectionProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />
                }
                else{
                  return <ManualCollectionProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />
                }  
              }} 
            />
            <Route path='/filters' render={(p) => <FilterList key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path='/create_filter' render={(p) => <BasicSearch history={props.history} match={p.match} isCreateFilter={true} sessionData={props.session_user} />} />
            <Route path='/profile/collection_instance/:id' render={(p) => <CollectionInstanceProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path='/lineage_explorer' render={(p) => <LineageExplorer key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path='/profile/collection/:id' render={(p) => <CollectionProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path="/settings" component={(p) => <Settings key={window.location.pathname} history={props.history} sessionData={props.session_user}/>} />
            <Route path='/basic_search' render={(p) => <BasicSearch  key={window.location.pathname + (window.location.href.includes('&isReload=true')?`reload_${new Date().getTime()}`:'')} history={props.history} match={p.match} sessionData={props.session_user} />} />
            <Route path="/dashboard/data_owner" render={(p) => <OwnerInsightsDashboard key={'owner_dashboard'} history={props.history} match={p.match} sessionData={props.session_user} variant="owner"/>} />
            <Route path="/dashboard/data_steward" render={(p) => <OwnerInsightsDashboard key={'steward_dashboard'} history={props.history} match={p.match} sessionData={props.session_user} variant="steward"/>} />
            {
              ['10','20','40','90'].find(el=>roles.includes(el)) &&
              <Route path="/add_knowledge" render={(p) => <AddKnowledge history={props.history} match={p.match} sessionData={props.session_user} />} />
            }  
            {
              ['00','10','40'].find(el=>roles.includes(el)) &&
              <Route path="/question_board" render={(p) => <QuestionBoard history={props.history} match={p.match} sessionData={props.session_user} />} />
            }
            {
              ['00','10','40'].some(el=>roles.includes(el)) &&
              <Route path="/create_collection" render={(p) => <CreateCollection history={props.history} match={p.match} sessionData={props.session_user} />} />
            } 
            {
              ['00','10'].find(el=>roles.includes(el)) &&
              <Route path="/admin/merge" render={(p) => <MergeItems history={props.history} sessionData={props.session_user} />} />
            }
            {
                ['00','10','40'].some(el=>roles.includes(el)) &&
              <Route path="/admin/instance_linking" render={(p) => <InstanceLinking history={props.history} sessionData={props.session_user} />} />
            } 
            {
                ['00','10','40'].some(el=>roles.includes(el)) &&
              <Route path="/admin/collection" render={(p) => <CollectionAdmin history={props.history} match={p.match} sessionData={props.session_user} />} />
            } 
            {
              roles.includes('00') &&
              <Route path="/admin/sources" render={(p) => <OnBoardSource history={props.history} sessionData={props.session_user} />} />
            } 
            {
              ['00'].find(el=>roles.includes(el)) &&
              <Route path='/admin/alerts' render={(p) => <MyAlerts key={window.location.pathname} history={props.history} sessionData={props.session_user} variant={'admin'}/>} />
            }
            {
              ['00'].find(el=>roles.includes(el)) &&
              <Route path="/admin/manage_role" render={(p) => <RoleManager history={props.history} match={p.match} sessionData={props.session_user} />} /> 
            }
            {
              ['00','10'].find(el=>roles.includes(el)) &&
              <Route path="/admin/users" render={(p) => <UserManagement history={props.history} match={p.match} sessionData={props.session_user} />} /> 
            }
            {
              ['00','10'].find(el=>roles.includes(el)) &&
              <Route path="/admin/teams" render={(p) => <Teams history={props.history} match={p.match} sessionData={props.session_user} />} /> 
            }
            {
              ['00','10'].find(el=>roles.includes(el)) &&
              <Route path="/admin/blacklist" render={(p) => <BlackList history={props.history} match={p.match} sessionData={props.session_user} />} /> 
            }
            {
              ['00','10'].find(el=>roles.includes(el)) &&
              <Route path="/admin/access_key" render={(p) => <AccessKey history={props.history} match={p.match} sessionData={props.session_user} />} /> 
            }
            {
              ['00','10'].find(el=>roles.includes(el)) &&
              <Route path="/admin/integrations" render={(p) => <Integrations history={props.history} match={p.match} sessionData={props.session_user} />} /> 
            }
            {
                ['00','10'].find(el=>roles.includes(el)) &&
              <Route path="/admin/platform_management" render={(p) => <PlatformManagement history={props.history} match={p.match} sessionData={props.session_user} />} /> 
            }
            {
              ['00','10','40'].find(el=>roles.includes(el)) &&
              <Route path='/admin/k_platform_setup' render={(p) => <KPlatformSetup key={window.location.pathname} history={props.history} sessionData={props.session_user} />} />
            }
            <Route path="/data_products" render={(p) => <DataProducts key={window.location.pathname} history={props.history} sessionData={props.session_user}/>} />
            <Route path="/home" 
              component={
                (p) =>< UserHomeProfile key={window.location.pathname} history={props.history} match={p.match} sessionData={props.session_user} />
              } 
            />
            <Route path="/insights/data_quality" render={(p) => <DataQualityInsights key="dq_insights" history={props.history} match={p.match} sessionData={props.session_user} />} />
            {
              ['10'].find(el=>roles.includes(el)) &&
              <Route path="/insights/usage" render={(p) => <Grid key="usage" history={props.history} match={p.match} sessionData={props.session_user} variant="usage"/>} />
            }
            {
              ['10','40'].find(el=>roles.includes(el)) &&
              <Route path="/insights/governance" render={(p) => <Grid key="governance" history={props.history} match={p.match} sessionData={props.session_user} variant="governance"/>} />
            }
            {
              ['10','40'].find(el=>roles.includes(el)) &&
              <Route path="/dashboard/issue" render={(p) => <Grid key="issue" history={props.history} match={p.match} sessionData={props.session_user} variant="issue"/>} />
            }
            {
              ['00','10'].find(el=>roles.includes(el)) &&
              <Route path="/dashboard/data_load" render={(p) => <Grid key="data_load" history={props.history} match={p.match} sessionData={props.session_user} variant="data_load"/>} />
            }

            {
              localStorage.hasOwnProperty('demo') && 
              <Route path="/insights/user_access" render={(p) => <UserRiskDashboard history={props.history} match={p.match} sessionData={props.session_user} />}/>
            }
            {
              localStorage.hasOwnProperty('demo') && 
              <Route path="/insights/data_risk" render={(p) => <DataRiskDashboard history={props.history} match={p.match} sessionData={props.session_user} />}/>
            }
            {
              localStorage.hasOwnProperty('demo') && 
              <Route path="/insights/data_classification" render={(p) => <DataClassificationDashboard history={props.history} match={p.match} sessionData={props.session_user} />}/>
            }
            <Redirect from="*" to={"/home"}/>
            
          </Switch>
          :
          <Switch>
            <Route path="/missing_role" render={() => <NoRoleDeadEnd />} />   
            <Route path="/user_data_error" render={() => <DeadEnd />} />
            <Redirect from="*" to="/missing_role" />
          </Switch>
        }
        <Alert stack={{ limit: 1, spacing: 5 }} />
      </Layout>
      : 
      <Route path="*" exact={true} render={() => <KeyCloakLogin history={props.history} />} />
  );
}

const mapStateToProps = state => {
  return {
    //logged_in: state.auth.logged_in,
    session_user: state.auth.session_user,
    sessionData: state.auth.sessionData,
    authLoading: state.auth.authLoading
  };
}

export default withRouter(connect(mapStateToProps)(App));
