import React, { Component } from 'react';
import ResultItem2 from '../SearchResults/ResultItem2';
import {  formatNumber, getIconComponent, onClickResultItem } from '../../../utilities';
import VerticalTabBar from '../VerticalTabBar/VerticalTabBar';
import {getDispFields} from '../../../utilities';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import Button from '@material-ui/core/Button';
import { Typography, Select, MenuItem, Popper, ClickAwayListener, Paper, Checkbox } from '@material-ui/core';
import theme from '../../../theme';


const styles = theme => ({
  card: {
    ...theme.containers.card,
    minWidth: 150,
    flexBasis: 210,
    flexGrow: 1,
    marginBottom: 24,
  },
  divider: {
    paddingTop: 1,
    backgroundColor: theme.palette.listItemDivider.main,
  },
  root: {
    display:'flex'
  },
  showing: {
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing.unit * 3,
  },
  resultsLoader: {
    paddingLeft: 226,
    marginBottom: 20,
  },
  title:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  subTitle:{
    fontSize:13.75,
    color:theme.palette.primaryText.light,
    marginBottom:16,
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  selector: {
		...theme.components.selector,
		width: 180,
	},
  listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },
  checkbox:{
    paddingLeft:0,
  },
});

class Results extends Component {

  renderItems = (data) => {
    const { classes, history, activeTab, additionalTab } = this.props;

    if(data.tabs.length<=activeTab){
      return additionalTab[activeTab-data.tabs.length].component;
    }

    const items = data.data[data.tabs[activeTab]].docs
      .reduce((acc, item) => {
          acc.push(
            <ResultItem2
              item={item}
              key={item.id}
              dispBody={getDispFields(item,'dispBody')}
              dispSubtitle={getDispFields(item,'dispSubtitle')}
              dispTitle={getDispFields(item,'dispTitle')}
              label={item.object_type_txt}
              showIcon
              onClick={() => onClickResultItem({id:item.id,label: item.object_type_txt, history, item: item })}
            />
          );
          acc.push(<div key={`${item.id}_div`} className={classes.divider} />);
        return acc;
      }, [])

    return (<div>
      {items}
      {
        this.props.showSeeMore && data.data[data.tabs[activeTab]].numFound>50 &&
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 24 }}>
          <div className={classes.normalText} style={{ paddingBottom: 12 }}>See more in Search</div>
          <Button color='primary' variant='outlined' onClick={() => this.props.handleSeeMore()}><SearchIcon />Go To Search</Button>
        </div>
      }
    </div>);
  }

  renderMenu = (data) => {
    const { activeTab, setActiveTab,additionalTab } = this.props;
    
    let tabOptions = data.tabs.map(el=>`${el} (${data.data[el].numFound})`);
    if(additionalTab){
      tabOptions = [...tabOptions,...additionalTab.map(el=>el.name)]
    }
    
    return (
      <VerticalTabBar
        tabState={activeTab}
        tabOptions={tabOptions}
        setTabState={setActiveTab}
        disableUnderline={true}
        minWidth={150}
        maxWidth={200}
      />
    )
  }

  render() {
    const {
      classes,
      data,
      activeTab,
      sort,
      setSort,
      onListActionClick,
      listActionOpen,
      setListActionOpen,
      anchor,
      showDeleted,
      onClickShowDeleted
    } = this.props;


    return (
      <div className={classes.root}>
        {this.renderMenu(data)}
        <div style={{flexGrow:1,marginLeft:80,overflow:'hidden'}}>
          {
            data.tabs.length>activeTab &&
            <div style={{display:'flex',flexWrap:'wrap',marginBottom:16}}>
              <div style={{flexGrow:1,marginRight:12}}>
                <Typography data-test-id="related-section-title" className={classes.title}>{formatNumber(data.data[data.tabs[activeTab]].numFound)} {data.tabs[activeTab].toUpperCase()}(S)</Typography>
              </div>
              <div style={{display:'flex',alignItems:'center'}}>
                <Select
                  className={classes.selector}
                  value={sort}
                  onChange={event => setSort(event.target.value) }
                >
                  {
                    [
                      {value:'trust_srt desc', dispName:'Trust score'},
                      {value:'total_usage_srt desc', dispName:'Usage'},
                    ].map(el => (
                      <MenuItem  className={classes.menuItem} value={el.value}>
                        <span>{el.dispName}</span>
                      </MenuItem>
                    ))
                  }
                </Select>

                <div style={{marginLeft:24,width:24,height:24,cursor:'pointer'}} onClick={onListActionClick}>
                  {getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
                </div>
                
                <Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
                  <ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
                    <Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
                      <Typography className={classes.listActionSectionTitle}>FILTERS</Typography>
                      <MenuItem onClick={()=>{onClickShowDeleted(!showDeleted)}} className={classes.menuItem} >
                        <Checkbox key={showDeleted} className={classes.checkbox} color='primary' checked={!showDeleted}/>
                        <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show active only</Typography>
                      </MenuItem>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </div>
            </div>
          }
          {this.props.renderItems ? this.props.renderItems(data) : this.renderItems(data)}
        </div>
      </div>
    )
  }


}

Results.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  activeTab: PropTypes.number.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  onClickItem: PropTypes.func.isRequired,
  renderItems: PropTypes.func,
  history: PropTypes.object,
}

export default withStyles(styles)(Results);