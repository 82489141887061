import React, {} from 'react';
import { withTheme, withStyles, Typography, Select, MenuItem, Button } from '@material-ui/core';
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import CollectionList from '../CollectionList/CollectionList';
import { formatNumber, mapObjectName } from '../../../utilities';
import BasicSearch from '../../../containers/BasicSearch/BasicSearch';
import theme from '../../../theme';
import ByDomain from '../../DataQualityInsights/ByDomain/ByDomain';
import Domain from '../Domain/Domain';
import { checkIsDataGov } from '../../../permissionChecker';

const styles = theme => ({
  selector:{
    ...theme.components.titleSelector
  }
})

const Body = props => {

  const {
    classes,
    history,
    sessionData,
    // theme,
    variant,
    state,
    dispatch,
    tabOptions,
  } = props;

  let subTabs = tabOptions[state.tabState]?.tabs;
  let subTabState = state.subTabState[state.tabState] || 0
  let bodyContent;

  if(!tabOptions[state.tabState])return <></>

  const columnMap = {
    dataGov:'usage_srt,trust,domain_kc_msrt,verified_kc_msrt,classification_kc_msrt,documented_srt,steward_count,active_lineage_inc_reference_srt,active_srt',
    dq:'dq_score_overall_srt,number_of_dq_tests_srt,number_of_issues_srt,active_srt'
  }
  
  switch(tabOptions[state.tabState].name){
    case 'DOMAIN':
      subTabs = ['GOVERNANCE','QUALITY']
      let subTabIndex = state.subTabState[state.tabState] || 0
      if(subTabIndex===0){
        bodyContent = <Domain state={state} dispatch={dispatch} isDataGovUser={checkIsDataGov({sessionData})} sessionData={sessionData} variant={variant}/>
      }
      if(subTabIndex===1){
        bodyContent = (
          <ByDomain
            key={variant+(state.viewedUser||sessionData)?.id}
            state={state}
            dispatch={dispatch}
            sessionData={state.viewedUser||sessionData}
            history={history}
            domainRole={variant}
            combineTabs
          />
        )
      }
      break;
    case 'COLLECTION':
      bodyContent = (
        <CollectionList
          key={variant+subTabs[subTabState]}
          state={state}
          history={history}
          dispatch={dispatch}
          sessionData={sessionData}
          objectName={subTabs[subTabState]}
          variant={variant}
        />
      )
      break;
    default:
      let objectType = subTabs[subTabState]
      let propFilter = (variant==='owner'?`owners_msrt:${(state.viewedUser||sessionData).name}`:`stewards_msrt:${(state.viewedUser||sessionData).name}`) + ' AND active_srt:YES'
      let propObjectType = mapObjectName(objectType,true)
      let view = state.viewType[objectType]||'dataGov'
      let propColumn = columnMap[view]
      let propSelectedFilters = columnMap[view]
      bodyContent = (
        <>
          <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap',alignItems:'flex-start'}}>
            <Select
              className={classes.selector}
              style={{marginBottom:16}}
              value={state.viewType[objectType]||'dataGov'}
              disableUnderline
              onChange={e=>{
                dispatch({type:'set_view_type',viewType:{...state.viewType,[objectType]:e.target.value}})
              }}
            >
              <MenuItem value='dataGov'>{mapObjectName(objectType)} GOVERNANCE METRICS</MenuItem>
              <MenuItem value='dq'>{mapObjectName(objectType)} QUALITY METRICS</MenuItem>
            </Select>
            <Button 
              color='primary' 
              variant="outlined"
              style={{marginBottom:16}}
              onClick={()=>{
                // sendMessage({'owner_dashboard_search_open_search':true})
                history.push(
                  `/basic_search?query=*&object=${propObjectType}&presetFilter=${propFilter}`
                )
              }}
            >
              OPEN IN SEARCH
            </Button>
          </div>
          <BasicSearch
            customID={'owner_dashboard_search'+objectType+'-'+view}
            key={variant+objectType+view} 
            initialView={'main_search'}
            history={history} 
            propObjectType={propObjectType} 
            propQuery={'*'} 
            propFilter={propFilter} 
            propCache={state.listData[objectType+view]}
            propStoreCache={data=>{
              dispatch({
                type:'set_list_data',
                listData:{...state.listData,[objectType+view]:data}
              })
            }}
            propColumn={propColumn}  
            propSelectedFilters={propSelectedFilters}  
            removeContainerStyle={true}
            customHeaderFormatter={
              ({objectType, resultsTotal})=>{
                return <Typography style={{fontSize:20,color:theme.palette.header.main}}>{formatNumber(resultsTotal)} {objectType.replace(/_/g,' ').toUpperCase()}(S)</Typography>
              }
            }
            resultItemVariant={"simplified"}
            hiddenComponents={['columnSelector','filterEdit','searchBar','header','tab','cartButton','downloadButton']}
            addtioonalComponents={['filterHide']}
          />
        </>
      )
  }
  
  return (
    <div style={{display:'flex',overflow:'hidden'}}>
      {
        subTabs &&
        <VerticalTabBar
          tabOptions={subTabs.map(el=>mapObjectName(el,true))}
          tabState={state.subTabState[state.tabState] || 0}
          setTabState={value=>{
            dispatch({ type: 'set_sub_tab_state', subTabState: {...state.subTabState,[state.tabState]:value}})
          }}
        />
      }
      <div className={classes.root} style={{flexGrow:1,maxWidth:'100%',overflow:'hidden',marginLeft:subTabs?80:0}}>
        {bodyContent}
      </div>
    </div>
  )
}

export default withTheme()(withStyles(styles)(Body));