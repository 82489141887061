import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, Button, Paper, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosSolr from '../../../../axios-solr';
import { formatBusinessnName, getIconComponent, getTestScoreColor, getUserRoles, isInViewport, mapSearchObjectName, onClickResultItem } from '../../../../utilities';
import { getIconLabel } from '../../SearchResults/utils';
import KTooltip from '../../KTooltip/KTooltip';
import moment from 'moment';
import { ContextMenuTrigger, ContextMenu } from 'react-contextmenu';
import CustomMenu from '../../ContextMenu/ContextMenu'
import MoreIcon from '@material-ui/icons/MoreHoriz'
import LabeledSelector from '../../LabeledSelector/LabeledSelector';
import { useStore } from 'react-redux';

const listItemHeight = 64;
// const expandedHeight = 360;

const styles = theme => ({
  columnHeader:{
    fontSize:12,
    color:theme.palette.primary.main,
    letterSpacing:1.5,
    marginRight:16,
    overflow:"hidden",
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  listItem:{
    display:'flex',
    overflow:'hidden',
    alignItems:'center',
    cursor:'pointer',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    height:listItemHeight-1,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  expandContainer:{
    transition:'height 0.1s ease-in-out',
  },
  detailList:{
    marginTop:16,
    boxSizing:'border-box',
    overflow:'auto',
    ...theme.components.customScroll,
  },
  dqListItem:{
    display:'flex',
    overflow:'hidden',
    alignItems:'center',
    cursor:'pointer',
    // borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    height:56,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  pageBtn:{
    height:36,
    minWidth:40
  },
  dqListText:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    marginRight:16
  },
  selector:{
    ...theme.components.titleSelector,
  },
  sectionHeader:{
    fontSize:12,
    marginTop:20,
    marginLeft:16,
    marginBottom:8,
    letterSpacing:1,
    color:theme.palette.primaryText.main
  }
})


function DetailList(props) {

  const {
    classes,
    history,
    theme,
    list,
    selectedTile,
    dimensions,
    setExpandedItem, 
    expandedItem,
    object,
    headerTopOffset,
  } = props;

  const scrollRef = useRef()

  const store = useStore();
  const sessionData = store.getState().auth.session_user;
  const roles = getUserRoles(sessionData.user_role)

  let label =  object.object?.name || mapSearchObjectName(object.object_type_srt,object.code_type_srt)
  let isColumn = ['COLUMN','DATASET_FIELD','QUERY'].includes(label)
  let isDataset = ['DATASET'].includes(label)

  const [detailData, setDetailData] = useState()
  const [detailPage, setDetailPage] = useState(1)
  const [selectedDimension, setSelectedDimension] = useState('all')
  const [dimensionFilters, setDimensionFilters] = useState()
  const [detailNumFound, setDetailNumFound] = useState()

  const [buttonHovering, setButtonHovering] = useState(false)

  const loadDimensionFilters = ({objectId}) => {
    axiosSolr
      .get(
        `/solr/data_quality_test_result/select`,{params:{
          q:'*',
          fq:`object_id_srt:${objectId}`,
          rows:0,
          'json.facet':{
            dimensions:{
              type:'terms',
              field:'data_quality_dimension_srt',
              limit:dimensions.length,
            }
          }
        }}
      )
      .then(response=>{
        let filters = response.data.facets.dimensions.buckets.map(el=>el.val)
        setDimensionFilters(filters.sort((a,b)=>a<b?-1:1))
      })
      .catch(error=>{
        console.log(error)
      })
  }

  const loadDetail = ({objectId, dimension=selectedDimension, start}) => {
    if(isColumn){
      setDetailData({...(start===0?{}:detailData||{}),loading:true})
    }else{
      setDetailData({loading:true})
    }
    axiosSolr
      .get(
        `/solr/data_quality_test_result/select`,{params:{
          q:'*',
          fq:`object_id_srt:${objectId}` + (dimension!=='all'?` AND data_quality_dimension_srt:${dimension}`:'') + (selectedTile!=='all'?` AND data_quality_dimension_id_srt:${selectedTile}`:''),
          rows:10,
          start,
          sort:'last_run_date_srt desc'
        }}
      )
      .then(response=>{
        if(isColumn){
          setDetailData({
            ...response.data.response,
            docs:[...(start===0?[]:detailData?.docs||[]),...response.data.response.docs]
          })
        }else{
          setDetailData(response.data.response)
        }
        if(dimension==='all')setDetailNumFound(response.data.response.numFound)
      })
      .catch(error=>{
        console.log(error)
        setDetailData({error:true})
      })
  }
  
  const getScore = (d, item) => {
    let itemDimension = item.dimensions?.find(el=>el.val.toUpperCase()===d.name.toUpperCase())
    if(itemDimension){
      return (
        <div style={{width:'max-content',fontSize:13.75,maxWidth:'100%',boxSizing:'border-box',borderRadius:2,color:'#000',padding:'2px 8px',background:getTestScoreColor(itemDimension.avg)}}>
          {Math.round(itemDimension.avg*100)/100}
        </div>
      )
    }
    return <Typography>-</Typography>
  }

  useEffect(()=>{
    if(isColumn){
      loadDimensionFilters({objectId:object.id})
      loadDetail({objectId:object.id, start:0})
    }else{
      if(expandedItem){
        loadDimensionFilters({objectId:expandedItem})
        loadDetail({objectId:expandedItem, start:0})
      }
    }
  // eslint-disable-next-line
  },[isColumn])

  const shouldLoadMoreList = () => {
    return isInViewport(scrollRef) && detailData && detailData?.numFound>detailData?.docs?.length && !detailData?.loading 
  }

  useEffect(()=>{
    if(!isColumn)return;
    if(shouldLoadMoreList()){
      loadDetail({objectId:object.id, dimension:selectedDimension, start:detailData?.docs?.length})
    }
  // eslint-disable-next-line
  },[detailData])

  window.onscroll = () => {
    if(!isColumn)return;
    if(shouldLoadMoreList()){
      loadDetail({objectId:object.id, dimension:selectedDimension, start:detailData?.docs?.length})
    }
  }

  const generatePageList = (numFound) => {
    if(Number.isNaN(numFound))return []
    let totalPages = numFound>0?Math.ceil(numFound/10):1;
    let pages = [];
    if(totalPages<=5){
      for(let i=1; i<=totalPages; i++){
        pages.push(i)
      }
      return pages;
    }
    if([1,2].includes(detailPage)){
      return [1,2,3,'...',totalPages]
    }
    if([totalPages, totalPages-1].includes(detailPage)){
      return [1,'...',totalPages-2,totalPages-1,totalPages];
    }
    return [1,'...',detailPage-1, detailPage, detailPage+1,'...',totalPages]
  }

  const onChangePage = page => {
    setDetailPage(page)
    loadDetail({objectId:isColumn?object.id:expandedItem, dimension:selectedDimension, start:(page-1)*10})
  }

  const getTestDetails = el => (
    <>
      <div 
        style={{
          display:'flex',
          alignItems:'center',
          justifyContent:'space-between',
          paddingBottom:isColumn?16:8,
          background:theme.palette.background.main,
          height:46,
          marginTop:isColumn?-60:0,
          ...(isColumn?{}:{position:'sticky',top:0,paddingTop:20,zIndex:100})
        }}>
        {
          isColumn ?
          <div></div>:
          <Typography style={{fontSize:20,color:theme.palette.header.main}}>
            {detailNumFound} DQ Tests for {el.object_name_srt}
          </Typography>
        }
        {
          !isColumn && 
          <>
            <Button 
              color='primary'
              variant='outlined'
              onClick={()=>{
                setDetailPage(1)
                setExpandedItem()
              }}
            >
              CLOSE
            </Button>
          </>
        }
      </div>
      {
        dimensionFilters?.length>0 && !isColumn && selectedTile==='all' && 
        <>
          <LabeledSelector
            title='DQ Dimension'
            value={selectedDimension}
            onChange={event=>{
              setSelectedDimension(event.target.value)
              setDetailPage(1)
              loadDetail({objectId:el.object_id_srt||el.id, dimension:event.target.value, start:0})
            }}
            options={dimensionFilters.map(d=>({name:d,value:d}))}
            placeholder='Search for a Dimension'
          />
        </>
      }
      {
        (detailData?.numFound>0 || detailData?.loading) &&
        <div style={{display:'flex',paddingBottom:8,paddingTop:isColumn?8:24,background:theme.palette.background.main,}}>
          <div style={{flex:'0 0 24px',marginLeft:16,marginRight:16}}></div>
          <Typography className={classes.columnHeader} style={{flex:'1 1 200px',minWidth:30}}>NAME</Typography>
          <Typography className={classes.columnHeader} style={{flex:'1 1 200px',minWidth:30}}>DQ DIMENSION</Typography>
          <Typography className={classes.columnHeader} style={{flex:'1 1 200px',minWidth:30}}>LATEST SCORE</Typography>
          <Typography className={classes.columnHeader} style={{flex:'1 1 200px',minWidth:30}}>LAST RUN</Typography>
        </div>
      }
      <div style={{height:isColumn?undefined:detailNumFound>10?56*10:(56*detailNumFound)}}>
        {
          detailData?.docs?.map(el=>(
            <>
              <ContextMenuTrigger id={el.data_quality_test_id_srt}>
                <div data-test-classname="summary-detail-list-item" className={classes.dqListItem} onClick={()=>onClickResultItem({item:el,id:el.data_quality_test_id_srt,label:'data_quality_test',history,newWindow:true})}>
                  <div style={{flex:'0 0 24px',height:24,marginLeft:16,marginRight:16}}>
                    {getIconComponent({label:'DATA_QUALITY_TEST',size:24,colour:theme.palette.primaryText.light})}
                  </div>
                  <KTooltip title={formatBusinessnName({roles, dispTitle:el.data_quality_test_name_srt,businessName:el.data_quality_alternate_name_srt,item:{...el,object_type_srt:'DATA_QUALITY_TEST'}})} placement={'top-start'}>
                    <Typography className={classes.dqListText} style={{flex:'1 1 200px',minWidth:30}}>
                      {formatBusinessnName({roles, dispTitle:el.data_quality_test_name_srt,businessName:el.data_quality_alternate_name_srt,item:{...el,object_type_srt:'DATA_QUALITY_TEST'}})  }
                    </Typography>
                  </KTooltip>
                  <Typography className={classes.dqListText} style={{flex:'1 1 200px',minWidth:30}}>
                    {el.data_quality_dimension_srt || '-'}
                  </Typography>
                  <Typography className={classes.dqListText} style={{flex:'1 1 200px',minWidth:30}}>
                    <div style={{width:'max-content',fontSize:13.75,maxWidth:'100%',color:'#000 ',boxSizing:'border-box',borderRadius:2,padding:'2px 8px',background:getTestScoreColor(el.last_data_quality_score_srt)}}>
                      {Math.round(el.last_data_quality_score_srt*100)/100}
                    </div>
                  </Typography>
                  <Typography className={classes.dqListText} style={{flex:'1 1 200px',minWidth:30}}>
                    {moment(el.last_run_date_srt).format('DD MMM YYYY')}
                  </Typography>
                </div>
              </ContextMenuTrigger>
              <ContextMenu id={el.data_quality_test_id_srt}>
                <CustomMenu
                  itemUrl={onClickResultItem({item:el,id:el.data_quality_test_id_srt,label:'data_quality_test',urlOnly:true})}
                  actions={[
                    'open_new_tab'
                  ]}
                  item={{id:el.data_quality_test_id_srt}}
                  itemLabel="DATA_QUALITY_TEST"
                />
              </ContextMenu>
            </>
          ))
        }
        {
          !isColumn && 
          <div style={{marginBottom:16,marginLeft:16,marginTop:16}}>
            {
              detailData?.loading &&
              <CircularProgress color='secondary'/>
            }
          </div>
        }
      </div>
      {
        isColumn &&
        <div ref={scrollRef} style={{marginBottom:16,marginLeft:16,marginTop:4}}>
          {
            detailData?.loading &&
            <CircularProgress color='secondary'/>
          }
        </div>
      }
      {
        detailData?.numFound===0 &&
        <Typography style={{marginLeft:isColumn?0:16}}>No data quality tests found</Typography>
      }
      {
        detailData?.error && 
        <Typography>Error occurred loading tests</Typography>
      }
      {
        !isNaN(detailNumFound) && detailNumFound>10 && !isColumn && 
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:24}}>
          {
            generatePageList(detailNumFound).map((el,index)=>{
              if(typeof(el)=='number'){
                return (
                  <Button 
                    variant={el===detailPage?'contained':'outlined'} 
                    color='primary' 
                    data-test-id={`detail-pagination-button-${el}`}
                    className={classes.pageBtn} 
                    style={{marginRight:index===generatePageList(detailNumFound).length-1?0:16}}
                    onClick={()=>onChangePage(el)}
                  >
                    {el}
                  </Button>
                )
              }
              else{
                return <MoreIcon style={{width:24,height:24,marginRight:16,color:theme.palette.primaryText.light}}/>
              }
            })
          }
        </div>
      }
    </>
  )

  if(['COLUMN','DATASET_FIELD','QUERY'].includes(label)){
    return getTestDetails(object)
  }

  const getList = el => {
    return (
      <div 
        className={classes.expandContainer} 
      >
        <ContextMenuTrigger id={el.object_id_srt}>
          <div 
            data-test-classname="summary-list-item"
            className={classes.listItem} 
            style={{background:buttonHovering?theme.palette.background.main:undefined}}
            onClick={(event)=>{
              // event.stopPropagation();
              // event.preventDefault()
              if(isDataset && el.object_type_srt==='dataset_table'){
                onClickResultItem({item:el,id:el.object_id_srt,label:el.object_type_srt,history,query:['tabName=QUALITY']})
                return;
              }
              setSelectedDimension('all')
              setDetailData()
              setDimensionFilters()
              if(expandedItem!==el.object_id_srt){
                setDetailPage(1)
                loadDetail({objectId:el.object_id_srt, start:0})
                loadDimensionFilters({objectId:el.object_id_srt})
              }
              setExpandedItem(expandedItem===el.object_id_srt?undefined:el.object_id_srt)
            }}
          >
            <div style={{flex:'0 0 24px',height:24,marginLeft:16,marginRight:16}}>
              {getIconComponent({label:getIconLabel({label:el.object_type_srt,item:el}),size:24,colour:theme.palette.primaryText.light})}
            </div>
            <KTooltip title={el.object_name_srt} placement="bottom-start">
              <Typography style={{flex:'1 1 200px',minWidth:30,marginRight:16, textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}>
                {el.object_name_srt}
              </Typography>
            </KTooltip>
            {
              dimensions.map(d=>(
                <div style={{flex:'1 1 200px',minWidth:30,marginRight:16,color:theme.palette.primaryText.main}}>
                  {getScore(d,el)}
                </div>
              ))
            }
            <div style={{flex:'0 0 56px',marginRight:8,}}>
              {
                object.id!==el.object_id_srt && (!isDataset || !el.object_type_srt==='dataset_table') && 
                <Button
                  onMouseEnter={()=>{
                    setButtonHovering(true)
                  }}
                  onMouseLeave={()=>{
                    setButtonHovering(false)
                  }}
                  style={{padding:0,minWidth:56}}
                  onClick={()=>{
                    onClickResultItem({item:el,id:el.object_id_srt,label:el.object_type_srt,history})
                  }}
                  color='primary'
                  variant='outlined'
                >
                  {/* {getIconComponent({label:'open',size:24,colour:theme.palette.primaryText.light})} */}
                  OPEN
                </Button>
              }
            </div>
          </div>
        </ContextMenuTrigger>
        <ContextMenu id={el.object_id_srt}>
          <CustomMenu
            // item={item}
            itemUrl={onClickResultItem({item:el,id:el.object_id_srt,label:el.object_type_srt,urlOnly:true})}
            // customActions={customActions}
            actions={[
              'open_new_tab'
            ]}
          />
        </ContextMenu>
      </div>
    )
  }
  
  return (
    <div className={classes.root}>
      <div style={{display:'flex',paddingBottom:8,transform:`translate(0px,${headerTopOffset}px)`,background:theme.palette.background.main,}}>
        <div style={{flex:'0 0 24px',marginLeft:16,marginRight:16}}></div>
        <Typography className={classes.columnHeader} style={{flex:'1 1 200px',minWidth:30}}>NAME</Typography>
        {
          dimensions.map(el=>(
            <Typography className={classes.columnHeader} style={{flex:'1 1 200px',minWidth:30}}>{el.name==='OVERALL'?'AVERAGE':el.name}</Typography>
          ))
        }
        <div style={{flex:"0 0 56px",marginRight:8}}></div>
      </div>
      {
        list?.find(el=>el.object_type_srt==='dataset') && 
        <>
          <Typography className={classes.sectionHeader}>DQ SCORE FOR THIS DATASET</Typography>
          {
            list?.filter(el=>el.object_type_srt==='dataset').map(getList)
          }
        </>
      }
      {
        list?.find(el=>el.object_type_srt==='table') && 
        <>
          <Typography className={classes.sectionHeader}>DQ SCORE FOR THIS TABLE</Typography>
          {
            list?.filter(el=>el.object_type_srt==='table').map(getList)
          }
        </>
      }
      {
        list?.find(el=>el.object_type_srt==='dataset_table') && 
        <>
          <Typography className={classes.sectionHeader}>DQ SCORE FOR THIS DATASET TABLE</Typography>
          {
            list?.filter(el=>el.object_type_srt==='dataset_table').map(getList)
          }
        </>
      }
      {
        list?.find(el=>el.object_type_srt==='column') && 
        <>
          <Typography className={classes.sectionHeader}>DQ SCORE FOR COLUMNS IN THIS TABLE</Typography>
          {
            list?.filter(el=>el.object_type_srt==='column').map(getList)
          }
        </>
      }
      {
        list?.find(el=>el.object_type_srt==='dataset_field') && 
        <>
          <Typography className={classes.sectionHeader}>DQ SCORE FOR DATASET FIELDS IN THIS DATASET TABLE</Typography>
          {
            list?.filter(el=>el.object_type_srt==='dataset_field').map(getList)
          }
        </>
      }
      <Modal
        open={expandedItem} 
        onClose={()=>setExpandedItem()}
        disableBackdropClick={true}
      >
        <div style={{width:'100vw',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <Paper style={{width:1000,maxWidth:'90vw',maxHeight:'90vh',height:detailNumFound>10?824:(824-50-56*(10-detailNumFound)), overflow:'auto',padding:"0px 24px 20px",boxSizing:'border-box',border:`1px solid ${theme.palette.border.main}`}}>
            {list.find(el=>el.object_id_srt===expandedItem)?getTestDetails(list.find(el=>el.object_id_srt===expandedItem)):undefined}
          </Paper>
        </div>
      </Modal>
    </div>
  )
}

DetailList.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  selectedTile: PropTypes.string.isRequired,
  dimensions: PropTypes.array.isRequired,
  object: PropTypes.object.isRequired,
  setExpandedItem: PropTypes.func.isRequired,
  expandedItem: PropTypes.string.isRequired,
}

export default withTheme()(withStyles(styles)(DetailList));