import React, { useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Typography, IconButton, ListItem, Divider, Popper, Paper } from '@material-ui/core';
import SearchSelector from '../../UI/SearchSelector/SearchSelector';
import { getIconComponent } from '../../../utilities';
import KTooltip from '../../UI/KTooltip/KTooltip';

const styles = theme => ({
  block: {
    marginBottom: '5rem',
  },
  body:{
    width:'100%'
  },
  headerSubText: {
    marginBottom: 16,
    width: 640,
    color: theme.palette.primaryText.light,
    fontSize: '11.78px',
  },
  headerTextOverflow: {
    fontSize: 20,
    color: theme.palette.primary.main,
    marginBottom: 2,
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap'
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  descriptionTitle:{
    fontSize:16,
    color:theme.palette.primaryText.main,
    marginBottom:8
  },
  welcomeText:{
    fontSize:13.75,
    color:theme.palette.primaryText.light,
    whiteSpace:'pre-wrap',
  },
  itemChip:{
    width:'max-content',
    maxWidth:'100%',
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    borderRadius:16,
    padding:'4px 0',
    '& span':{
      padding:'0 8px',
      fontSize:13.75
    }
  },
  targetItemChip:{
    background:theme.palette.primary.dark,
    color:theme.palette.background.main
  },
  tooltip:{
    fontSize:13.75
  },
  descriptionBox:{
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    borderRadius:4,
    padding:16,
    paddingBottom:40,
    marginBottom:40
  },
  helperText:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    flexShrink:1,
  },
  seeMoreText:{
    marginLeft:6,
    color:theme.palette.primaryText.light,
    fontSize:12,
    flexShrink:0,
    flexGrow:0,
    textDecoration:'underline', 
    cursor:'default'
  },
  sourcePopperContainer:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    width:400,
    padding:'8px 0px 8px 16px',
    marginLeft:478,
    boxSizing:'border-box',
  },
  sourceItem:{
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    height:48
  },
  sourceListContainer:{
    maxHeight:350,
    overflow:"auto",
    paddingRight:16,
    ...theme.components.customScroll
  }
})

const Identifiers = props => {

  const {
    theme,
    classes,
    state,
    dispatch,
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [popperAnchor, setPopperAnchor] = useState();
  const popperFadeTimeout = useRef()

  const onClickResult = user => {
    setSearchValue('');
    dispatch({type:'set_alias',alias:[...state.alias,user]})
  }

  const onDeleteAlias = user => {
    dispatch({type:'set_alias',alias:state.alias.filter(a=>a.id!==user.id)})
  }

  // const constructReferenceSources = sources => {
  //   if(!sources || sources.length===0)return 'NA';
  //   return sources.map(el=>el.reference_source.name).join(', ')
  // }

  const processSourceText = (item) => {
    let str = item.source.name;
    if(item.reference_sources.length>0){
      str += ` / ${item.reference_sources.map(el=>el.reference_source.name).join(', ')}`
    }
    return str;
  }

  const getSourceNamesString = () => {
    let str = state.sourceData.map(el=>el.name).join(', ');
    let isOverflow = false;
    if(str.length>75){
      isOverflow = true;
      str = str.slice(0,72)+'...'
    }
    return {isOverflow, str}
  }


  return (
    <div className={classes.block}>
      <div className={classes.descriptionBox}>
        <Typography className={classes.descriptionTitle}>Why should you link your Accounts</Typography>
        <Typography className={classes.welcomeText}>
        You may use different accounts for different sources (databases, platforms and tools). Search for accounts you use (e.g. jdoe1, jdoe@company.com) and link them to you in K. Only accounts from sources integrated with K will appear.
        {`\n\n`}
        Be careful to not link Account(s) that do not belong to you. You do not want to accidentally claim someone else's Account!
        </Typography>
      </div>

      <Typography className={classes.headerTextOverflow}>LINK ACCOUNTS</Typography>
      <Typography className={classes.headerSubText}>
        Search for the identifiers you use across the data ecosystem
      </Typography>
      <div style={{width:640}}>
        <SearchSelector
          searchValue={searchValue}
          setSearchValue={value=>setSearchValue(value)}
          url={`/api/merges/search`}
          params={{'object_name':searchValue,type:'CANDIDATE',per_page:20,object_type:'USER'}}
          placeholder={'Search for your accounts that you use across the data ecosystem'}
          isCerebrum={true}
          renderResults={(items)=>{
            let suggests = items.filter(el=>!state.alias.find(a=>a.id===el.id));
            if(suggests.length===0){
              return [
                <div>
                  <ListItem>
                    <div style={{display:'flex',alignItems:'center'}}>
                      <Typography style={{marginLeft:12,color:theme.palette.primaryText.main}}>No suggestions found</Typography>
                    </div>
                  </ListItem>
                </div>
              ]
            }
            return suggests.map((item,index)=>(
              <div>
                <ListItem button onClick={() => {onClickResult(item)}}>
                  <div style={{display:'flex',alignItems:'flex-start',overflow:'hidden'}}>
                    {getIconComponent({label:item.object.name,size:24,colour:theme.palette.primary.main})}
                    <div style={{overflow:'hidden',marginLeft:12}}>
                      <Typography style={{color:theme.palette.primaryText.main,fontSize:16,whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden'}}>{item.user.username}{item.user.username!==item.name?` (${item.name})`:''}</Typography>
                      <Typography style={{color:theme.palette.primaryText.light,fontSize:13.75,whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden'}}>{item.user.account_type}</Typography>
                      <Typography style={{color:theme.palette.primaryText.light,fontSize:13.75,whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden'}}>{processSourceText(item)}</Typography>
                      {/* <Typography style={{color:theme.palette.primaryText.light,fontSize:13.75,whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden'}}>{user.teams_txts && user.teams_txts.join(', ')}</Typography> */}
                    </div>
                  </div>
                </ListItem>
                {index!==items.length-1 && <Divider style={{marginLeft:16}}/>}
              </div>
          ))}}
        />
        {
          state.sourceData && state.sourceData.length>0 && 
          <div style={{display:'flex',alignItems:'center',overflow:'hidden',marginTop:4}}>
            <Typography className={classes.helperText}>Sources connected: {getSourceNamesString().str}</Typography>
            {
              getSourceNamesString().isOverflow && 
              <Typography 
                className={classes.seeMoreText}
                onMouseEnter={(event)=>{
                  clearTimeout(popperFadeTimeout.current)
                  setPopperAnchor(event.currentTarget)
                }}
                onMouseLeave={()=>{
                  popperFadeTimeout.current = setTimeout(()=>{
                    setPopperAnchor();
                  },250)
                }}
              >
                see more
              </Typography>
            }
            <Popper open={popperAnchor}  anchorEl={popperAnchor}>
              <Paper 
                className={classes.sourcePopperContainer}
                onMouseEnter={()=>{clearTimeout(popperFadeTimeout.current)}}
                onMouseLeave={()=>{
                  popperFadeTimeout.current = setTimeout(()=>{
                    setPopperAnchor();
                  },250)
                }}
              >
                <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginBottom:4}}>SOURCES CONNECTED TO K</Typography>
                <div className={classes.sourceListContainer}>
                  {
                    state.sourceData.map(s=>(
                      <div className={classes.sourceItem} >
                        <div style={{width:24,height:24,flexShrink:0,flexGrow:0,marginRight:24}}>
                          {
                            getIconComponent({
                              label: getIconComponent({label:s.source_template.name})?s.source_template.name:'source',
                              size:24,
                              colour:theme.palette.primaryText.light
                            })
                          }
                        </div>  
                        <KTooltip title={s.name}>
                          <Typography 
                            style={{fontSize:16,color:theme.palette.primaryText.main,overflow:"hidden",textOverflow:'ellipsis',whiteSpace:'nowrap'}}
                          >
                            {s.name}
                          </Typography>
                        </KTooltip>
                      </div>
                    ))
                  }
                </div>
              </Paper>
            </Popper>
          </div>
        }
      </div>
      <div>
        <div style={{display:'flex',marginTop:24}}>
          <Typography color='primary' style={{fontSize:12,flex:'0 1 300px',marginLeft:56,paddingRight:16}}>NAME</Typography>
          <Typography color='primary' style={{fontSize:12,flex:'0 1 200px',paddingRight:16}}>ACCOUNT TYPE</Typography>
          <Typography color='primary' style={{fontSize:12,flex:'0 1 300px',paddingRight:16}}>SOURCE</Typography>
          <Typography color='primary' style={{fontSize:12,flex:'1 1 348px',paddingRight:16}}>ALTERNATE SOURCE</Typography>
        </div>
        <div style={{marginTop:10}}>
          {
            state.alias.length===0 &&
            <Typography style={{color:theme.palette.primaryText.light,marginLeft:58}}>No accounts selected</Typography>
          }
          {
            state.alias.map(el=>(
              <div style={{width:'100%', display:'flex',paddingLeft:16,height:48,alignItems:'center',borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,overflow:'hidden'}}>
                {getIconComponent({label:'user',size:24,colour:theme.palette.primaryText.light})}
                <Typography style={{fontSize:16,color:theme.palette.primaryText.main,flex:'0 1 300px',marginLeft:16,paddingRight:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                  <KTooltip title={`${el.user.username}${el.user.username!==el.name?` (${el.name})`:''}`} classes={{tooltip:classes.tooltip}}>
                    <div className={classes.itemChip + ' ' + classes.targetItemChip}>
                      <span>{el.user.username}{el.user.username!==el.name?` (${el.name})`:''}  </span>
                    </div>
                  </KTooltip>
                </Typography>
                <Typography style={{fontSize:16,color:theme.palette.primaryText.main,flex:'0 1 200px',paddingRight:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                  {el.user.account_type}  
                </Typography>
                <Typography style={{fontSize:16,color:theme.palette.primaryText.main,flex:'0 1 300px',paddingRight:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                  {el.source.name}
                </Typography>
                <Typography style={{fontSize:16,color:theme.palette.primaryText.main,flex:'1 1 300px',paddingRight:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                  {el.reference_sources.map(el=>el.reference_source.name).join(', ')}
                </Typography>
                <IconButton onClick={()=>{onDeleteAlias(el)}} style={{flex:'0 0 48px'}}>
                  {getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.main})}
                </IconButton>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

Identifiers.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
}

export default withTheme()(withStyles(styles)(Identifiers));