import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Select, MenuItem, Button, Checkbox, Typography } from '@material-ui/core';

const styles = theme => ({
  selector: {
    ...theme.components.selector,
    width:'100%',
    '& div div': {
      paddingLeft: 0,
      paddingTop:12,
      paddingBottom:11,
      fontSize:16
    },
    '& p':{
      overflow:'hidden'
    }
  },
  selectorText:{
    paddingLeft:`16px !important`,
    color:theme.palette.primaryText.light,
    fontSize:16
  },
  button:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    textTransform:'none',
    padding:0,
    marginTop:2,
    marginLeft:16,
    '& span':{
      fontSize:12
    }
  },
  menuItem:{
		padding:'10px 10px 10px 16px',
    display:'flex',
    alignItems:'center',
		color:theme.palette.primaryText.main,
		'&:hover':{
      background: theme.palette.hovered.main 
    }
	},
  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  checkbox:{
    padding:4,
    paddingLeft:0,
    marginRight:8,
    marginLeft:-2
  },
  groupTitle:{
    fontSize:12,
    color:theme.palette.primary.main,
    letterSpacing:2,
    marginBottom:8,
    marginLeft:16
  }
})

const ClearableSelector = props => {

  const {
    classes,
    value,
    options,
    onChange,
    onReset,
    multiple,
    renderValue,
    width=180,
    autoWidth,
    title,
    hideReset,
    testID,
  } = props;


  if(!options)return <></>

  let processedOptions;
  if(options.some(el=>!el.group)){
    processedOptions = options.map(el=>(
      <MenuItem data-test-id={`menu-item-${el.value}`}  className={classes.menuItem} value={el.value}>
        {
          multiple && el.value!=='all' && 
          <Checkbox 
            color='primary'
            checked={value.includes(el.value)}
            className={classes.checkbox}
          />
        }
        {el.name}
      </MenuItem>
    ))
  }else{
    let groups = [];
    options.forEach(el=>{
      if(!groups.includes(el.group))groups.push(el.group)
    })
    processedOptions = groups.map(el=>
      (
        <div style={{marginBottom:16}} onClick={event=>{event.stopPropagation()}} >
          <Typography onClick={event=>{event.stopPropagation()}} className={classes.groupTitle}>{el}</Typography>
          {
            options.filter(o=>o.group===el).map(o=>(
              <MenuItem 
                onClick={event=>{onChange({target:{value:o.value}})}} 
                className={classes.menuItem} 
                data-test-id={`menu-item-${o.value}`}
                value={o.value}
                style={{fontSize:13.75}}
              >
                {o.name}
              </MenuItem>
            ))
          }
        </div>
      )
    )
  }

  return (
    <div style={autoWidth?{width:'100%'}:{width}}>
      {
        title && <Typography color='primary' style={{fontSize:12,letterSpacing:2,marginBottom:8}}>{title}</Typography>
      }
      <Select
        className={classes.selector}
        value={value}
        multiple={multiple}
        onChange={onChange}
        disableUnderline
        data-test-id={testID}
        renderValue={
          renderValue || 
          (value=>
            multiple?
              value.length===options.length?'All':value.map(v=>(options.find(o=>o.value===v).dispName||options.find(o=>o.value===v).name)+'').join(', ')
              :
              options.find(o=>o.value===value)?
                options.find(o=>o.value===value).name+'':
                value+''
          )}
        inputProps={{className:classes.selectorText}}
        MenuProps={{
          classes:{
            paper:classes.selectPaper
          },
          getContentAnchorEl:null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            horizontal: "left"
          },
        }}
      > 
        {processedOptions}
      </Select>
      {!hideReset && <Button onClick={onReset} data-test-id={testID?`${testID}-clear-button`:undefined} className={classes.button}>Clear filter</Button>}
    </div>
  )
}

ClearableSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  renderValue: PropTypes.func,
  onReset: PropTypes.func,
  width: PropTypes.number,
  autoWidth:PropTypes.bool,
  title: PropTypes.string,
  hideReset: PropTypes.bool,
  testID: PropTypes.string,
}

export default withTheme()(withStyles(styles)(ClearableSelector));