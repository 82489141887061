import React,{ useEffect, useRef, useState} from 'react';
import { withTheme, withStyles, CircularProgress,} from '@material-ui/core';
import {  Typography, Modal, Paper, Button } from '@material-ui/core';
import { FileUploader } from "react-drag-drop-files";
import axiosCerebrum from '../../../axios-cerebrum';
import ModalAlert from '../../UI/ModalAlert/ModalAlert';
import useAlert from '../../../hooks/useAlert';
import WarningConfirmModal from '../../UI/ConfirmModals/WarningConfirmModal';

const styles = theme => ({
  modal:{
    width:752,
    overflowY:'auto',
    marginTop:'-10vh',
    display:'flex',
    flexDirection:'column',
    maxHeight:'80vh',
    paddingBottom:8,
    border:`1px solid ${theme.palette.border.main}`,
    background:theme.palette.background.main
  },
  modalBody:{
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.main
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb':{
      background: theme.palette.primaryText.light,
      borderRadius:4,
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover':{
      
    },
    '&::-webkit-scrollbar': {
      width: 5
    },
  },
  sectionHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:1.5,
    marginBottom:8
  },
  sectionBody:{
    fontSize:13.75,
    wordBreak:'break-word',
    marginBottom:56
  },
  dropZone:{
    width:'100%',
    boxSizing:'border-box',
    height:200,
    border:`2px dashed ${theme.palette.primary.main}`,
    borderRadius:4,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  }
})

function ImportFileModal(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    url,
    confirmMessage,
    history,
    onFileUploaded
  } = props;

  const [file, setFile] = useState(null);
  const [error, setError] = useState(false);
  const [uploading, setUploading] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const [isConfirmView, setIsConfirmView] = useState(confirmMessage?true:false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    id:`bulk-edit-import-file-modal`,
    isCancelledRef
  })

  useEffect(()=>{ 
    return () => {
      isCancelledRef.current = true
    }
  },[])

  useEffect(()=>{
    if(!state.importModalOpen){
      setFile()
      setError(false)
      setAlertOpen(false)
      setIsConfirmView(false)
    }
  },[state.importModalOpen])

  const onFileDrop = (f) => {
    setError(false)
    setFile(f)
  }

  const onImport = () => {
    setUploading(true)
    let formData = new FormData();
    formData.append("data", file);
    axiosCerebrum
      .post(
        url || `/api/objects/bulk`,
        formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then(response=>{
        setUploading(false)
        onFileUploaded?.();
        sendAlert({type:'info',message:'Template successfully uploaded'})
        history.push(`/my_tasks?tabName=JOBS&jobName=BULK EDIT`)
        // sendMessage({reload_bulk_history:true})
        // dispatch({type:'set_import_modal_open',importModalOpen:false})
      })
      .catch(error=>{
        console.log(error)
        setIsConfirmView(false)
        setUploading(false)
        setAlertOpen(true)
        setAlertMessage(typeof(error?.response?.data?.errors)==='string'?error?.response?.data?.errors:'Template failed to upload')
      })
  }

  if(isConfirmView){
    return (
      <WarningConfirmModal
        description={confirmMessage}
        setModalOpen={()=>{
          setIsConfirmView(false)
          dispatch({type:'set_import_modal_open',importModalOpen:false})
        }}
        modalOpen={isConfirmView}
        onConfirm={()=>setIsConfirmView(false)}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
        alertMessage={alertMessage}
        confirmText={'YES'}
      />
    )
  }

  return (
    <Modal open={state.importModalOpen}>
      <div style={{width:'100vw',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center',outline:'none'}}>
        <div style={{display:'block',width:754}}>
          <Paper className={classes.modal}>
            <div className={classes.modalBody} style={{flexGrow:1,overflowY:'auto',overflowX:'hidden',padding:24}}>
              <div style={{display:'flex',justifyContent:'space-between',marginBottom:24}}>
                <Typography  style={{color:theme.palette.header.main,fontSize:20}}>UPLOAD TEMPLATE FILE</Typography>
                <Button onClick={()=>dispatch({type:'set_import_modal_open',importModalOpen:false})} color='primary' variant='outlined'>CLOSE</Button>
              </div>
              {
                file && 
                <>
                  <Typography className={classes.sectionHeader}>FILE NAME</Typography>
                  <Typography className={classes.sectionBody}>{file.name}</Typography>
                  {/* <Typography className={classes.sectionHeader}>NOTES</Typography>
                  <Typography className={classes.sectionBody}>QAQ</Typography> */}
                  {
                    uploading?
                    <CircularProgress color='secondary' style={{width:24,height:24}}/>:
                    <Button 
                      variant='contained' 
                      color='secondary' 
                      style={{marginTop:60}} 
                      onClick={()=>{
                        onImport()
                      }}
                    >
                      START IMPORT
                    </Button>
                  }
                </>
              }
              {
                !file && 
                <FileUploader 
                  required={true}  
                  handleChange={onFileDrop} 
                  maxSize={50} 
                  types={['XLSX','ZIP']} 
                  name={'template'}
                  dropMessageStyle={{display:'none'}}
                  onSizeError={()=>setError('size')}
                  onTypeError={()=>setError('type')}
                  children={
                    <div className={classes.dropZone}>
                      {
                        !error && 
                        <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light}}>Click or drag to add a template</Typography>
                      }
                      {
                        error==='size' &&
                        <Typography style={{fontSize:13.75,color:theme.palette.error.main}}>File size must be less than 50MB</Typography>
                      }
                      {
                        error==='type' &&
                        <Typography style={{fontSize:13.75,color:theme.palette.error.main}}>File type must be XLSX or ZIP</Typography>
                      }
                    </div>
                  }
                />
              }
            </div>
          </Paper>
          <ModalAlert
            open={alertOpen}
            setOpen={setAlertOpen}
            message={alertMessage}
            disableAutoClose={true}
            type='error'
          />
        </div>
      </div>
    </Modal>
  )
}

export default withTheme()(withStyles(styles)(ImportFileModal));