
import React from 'react';
import { Typography,CircularProgress } from '@material-ui/core';
import DataTile from '../../Grid/Components/DataTile'
import KTooltip from '../../UI/KTooltip/KTooltip';
import StackBar from '../../Grid/Components/StackBar';
import BarChart from '../../Grid/Components/BarChart';
import { processObjectName } from '../../Grid/Governance/loadUtils';

export const generateDataTiles = ({classes, data, setData, selectedTile, setSelectedTile}) => {
  return (
    <div style={{display:'flex',width:'100%',marginBottom:24,flexWrap:'wrap'}}>
      {
        data.loading && <CircularProgress color='secondary' style={{margin:'0px auto'}} />
      }
      {
        data.error && <Typography className={classes.normalText}>Error occurred loading details</Typography>
      }
      {
        data.data && data.data.map((el,index)=>(
          <DataTile tooltip={el.tooltip} forceRootStyle={{width:data.data.length>6||window.innerWidth<1100?180:220,flexGrow:0,flexShrink:0}} clickable={el.clickable} tileId={el.id} onClick={()=>{if(el.clickable){setSelectedTile(el.id)}}} selected={selectedTile===el.id} title={el.title} value={el.value} footer={el.footer}/>
        ))
      }
      {
        data.data && data.data.length===0 && <Typography className={classes.normalText}>No insights to display</Typography>
      }
    </div>
  )
}

export const generateSummaryChart = ({classes,data,selectedTile}) => {
  if(data[`${selectedTile}_graphDataLoading`]){
    return <div style={{textAlign:'center',marginTop:36, marginBottom:24}}><CircularProgress color='secondary'/></div>
  }
  if(data[`${selectedTile}_graphDataError`]){
    return <Typography className={classes.normalText} style={{marginBottom:24}}>Error occurred loading graphs</Typography>
  }
  if(!data[`${selectedTile}_graphData`]){
    return <div></div>
  }
  let graphData = data[`${selectedTile}_graphData`];
  let chartData = [];

  let objectType = selectedTile;
  let title = `Governance progress`
  if(graphData[`${objectType}_GOV_PROGRESS`]){
    chartData.push({type:'stack',width:'90%',height:120,title,...graphData[`${objectType}_GOV_PROGRESS`]})
  }

  if(chartData.length===0)return <Typography style={{marginBottom:24}}>No summary provided</Typography>
  
  return (
    <div style={{display:'flex',flexWrap:'wrap'}}>
      {
        chartData.map(el=>(
          <div className={classes.chartContainer} style={{width:'100%'}}>
            <KTooltip title={el.tooltip} classes={{tooltip:classes.tooltip}}>
              <div style={{marginBottom:16}}>  
                <Typography className={classes.chartTitle}>{el.title}</Typography>
              </div>
            </KTooltip>
            {
              el.type==='stack' && 
              <StackBar
                id={el.title}
                key={el.title}
                width={el.width}
                height={el.height}
                series={el.series}
                labels={el.labels}
              />
            }
          </div>
        ))
      }
    </div>
  ) 
}

export const generateChartGroup = ({classes,data,selectedTile}) => {
  if(data[`${selectedTile}_graphDataLoading`]){
    return <div style={{textAlign:'center',marginTop:36, marginBottom:24}}><CircularProgress color='secondary'/></div>
  }
  if(data[`${selectedTile}_graphDataError`]){
    return <Typography className={classes.normalText} style={{marginBottom:24}}>Error occurred loading graphs</Typography>
  }
  if(!data[`${selectedTile}_graphData`]){
    return <div></div>
  }
  let graphData = data[`${selectedTile}_graphData`];
  let chartData = [];

  let objectType = selectedTile;
  let displayObjectType = processObjectName(objectType)
  let title = `${displayObjectType}s by Classification`
  let subTitle = `Count of ${displayObjectType}s by Classification`
  if(graphData[`${objectType}_BY_CLASSIFICATION`]){
    chartData.push({type:'bar',width:'80%',height:260,title,subTitle,...graphData[`${objectType}_BY_CLASSIFICATION`]})
  }

  title = `${displayObjectType}s by Domain status`
  subTitle = `Count of ${displayObjectType}s by Domain status`
  if(graphData[`${objectType}_DOMAIN_STATUS`]){
    chartData.push({type:'bar',width:'80%',height:260,title,subTitle,...graphData[`${objectType}_DOMAIN_STATUS`]})
  }

  title = `${displayObjectType}s by Use Case status`
  subTitle = `Count of ${displayObjectType}s by Use Case status`
  if(graphData[`${objectType}_USE_CASE_STATUS`]){
    chartData.push({type:'bar',width:'80%',height:260,title,subTitle,...graphData[`${objectType}_USE_CASE_STATUS`]})
  }

  title = `${displayObjectType}s by Documentation status`
  subTitle = `Count of ${displayObjectType}s that have been documented`
  if(graphData[`${objectType}_DOCUMENTED_STATUS`]){
    chartData.push({type:'bar',width:'80%',height:260,title,subTitle,...graphData[`${objectType}_DOCUMENTED_STATUS`]})
  }

  title = `${displayObjectType}s by Data Owner status`
  subTitle = `Count of ${displayObjectType}s that have an owner assigned`
  if(graphData[`${objectType}_OWNER_STATUS`]){
    chartData.push({type:'bar',width:'80%',height:260,title,subTitle,...graphData[`${objectType}_OWNER_STATUS`]})
  }

  title = `${displayObjectType}s by Data Steward status`
  subTitle = `Count of ${displayObjectType}s that have stewards assigned`
  if(graphData[`${objectType}_STEWARD_STATUS`]){
    chartData.push({type:'bar',width:'80%',height:260,title,subTitle,...graphData[`${objectType}_STEWARD_STATUS`]})
  }

  if(chartData.length===0)return <Typography style={{marginBottom:24}}>No insights found</Typography>
  
  return (
    <div style={{display:'flex',flexWrap:'wrap'}}>
      {
        chartData.map(el=>(
          <div className={classes.chartContainer}>
            <KTooltip title={el.tooltip} classes={{tooltip:classes.tooltip}}>
              <div>  
                <Typography className={classes.chartTitle}>{el.title}</Typography>
                <Typography className={classes.chartSubtitle}>{el.subTitle}</Typography>
              </div>
            </KTooltip>
           
            {
              el.type==='text' && 
              <div style={{width:el.width,height:el.height}}>
                <Typography style={{marginTop:16}} className={classes.subTitle}>{el.text}</Typography>
              </div>
            }
            {
              el.type==='bar' && 
              <BarChart
                id={el.title}
                key={el.title + objectType}
                width={el.width}
                height={el.height}
                series={el.series}
                labels={el.labels}
                toggleLabel={el.toggleLabel}
              />
            }
            
          </div>
        ))
      }
      
    </div>
  ) 
}

export const generateRecommendationList = ({classes, data, selectedTile}) => {

  if(data[`${selectedTile}_recommendationLoading`]){
    return <div style={{textAlign:'center',marginTop:36}}><CircularProgress color='secondary'/></div>
  }

  if(data[`${selectedTile}_recommendationError`]){
    return <Typography className={classes.normalText}>Error occurred loading recommendations</Typography>
  }

  if(!data[`${selectedTile}_recommendationData`] || !selectedTile){
    return <div></div>
  }

  let recommendations = data[`${selectedTile}_recommendationData`];
  if(recommendations.length===0)return <Typography className={classes.normalText}>No recommendations currently provided</Typography>
  return (
    <div style={{marginBottom:500}}>
      {
        recommendations.map(el=>(
          <div className={classes.recommendationContainer} onClick={el.onClick}>
            <Typography className={classes.recommendationTitle}>{el.title}</Typography>
            <div className={classes.recommendationCount}>{el.countTxt}</div>
          </div>
        ))
      }
    </div>
  )
}
