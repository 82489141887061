import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import QuestionBoardChip from '../QuestionBoardChip/QuestionBoardChip'
import { getExtractName } from '../Utils/Utils'

const styles = theme => ({
  title:{
    fontSize:20
  },
  selector: {
    minWidth: 185,
    height:16,
    marginLeft:6,
    backgroundColor: '#EEE',
    padding:'10px 14px',
  },
})

const DataRisk = props => {

  const {
    classes,
		state,
		dispatch,
    history
  } = props;

  const sourceAccessRisk = [
    {
      title:getExtractName('0400'),
      subtitle:'Identify users who have not used a source for a set period of time',
      code:'0400'
    }
  ]

  const dataUserRisk = [
    {
      title:getExtractName('0150'),
      subtitle:'Unused access report for all data users',
      code:'0150'
    },
    {
      title:getExtractName('0340_OBJECT'),
      subtitle:'Access and usage report for a specific data item (e.g. Table) within a Source',
      code:'0340_OBJECT'
    },
    {
      title:getExtractName('0340_OWNER'),
      subtitle:'Usage and access report by Data Owner',
      code:'0340_OWNER'
    },
    {
      title:getExtractName('0340_STEWARD'),
      subtitle:'Usage and access report by Data Steward',
      code:'0340_STEWARD'
    },
  ]

  const contentUserRisk = [
    {
      title:getExtractName('0390'),
      subtitle:'Access and usage report for all content item (e.g. Report) within a Source',
      code:'0390'
    }
  ]

  const adhocRisk = [
    {
      title:getExtractName('0170'),
      subtitle:'Usage metrics for adhoc data',
      code:'0170',
    },
  ]

  const onExtractSubmitted = () => history.push(`/my_tasks?tabName=EXTRACTS&jobName=ASK K`)

  return (
    <div >
      <div>
			  <Typography color='primary' className={classes.title}>SOURCE ACCESS</Typography>
      </div>  
      <div style={{marginLeft:16,marginTop:16}}>
        {
          sourceAccessRisk.map(el=>(
            <QuestionBoardChip
              state={state}
              dispatch={dispatch}
              code={el.code}
              title={el.title}
              subtitle={el.subtitle}
              disableAutoDownload={true}
              onExtractSubmitted={onExtractSubmitted}
            />
          ))
        }
      </div> 
      <div style={{marginTop:40}}>
			  <Typography color='primary' className={classes.title}>DATA ACCESS</Typography>
      </div>  
      <div style={{marginLeft:16,marginTop:16}}>
        {
          dataUserRisk.map(el=>(
            <QuestionBoardChip
              state={state}
              dispatch={dispatch}
              code={el.code}
              title={el.title}
              subtitle={el.subtitle}
              disableAutoDownload={true}
              onExtractSubmitted={onExtractSubmitted}
            />
          ))
        }
      </div> 
      <div style={{marginTop:40}}>
			  <Typography color='primary' className={classes.title}>CONTENT ACCESS</Typography>
      </div>  
      <div style={{marginLeft:16,marginTop:16}}>
        {
          contentUserRisk.map(el=>(
            <QuestionBoardChip
              state={state}
              dispatch={dispatch}
              code={el.code}
              title={el.title}
              subtitle={el.subtitle}
              disableAutoDownload={true}
              onExtractSubmitted={onExtractSubmitted}
            />
          ))
        }
      </div> 
      <div style={{marginTop:40}}>
			  <Typography color='primary' className={classes.title}>ADHOC DATA</Typography>
      </div>  
      <div style={{marginLeft:16,marginTop:16}}>
        {
          adhocRisk.map(el=>(
            <QuestionBoardChip
              state={state}
              dispatch={dispatch}
              code={el.code}
              title={el.title}
              subtitle={el.subtitle}
              disableAutoDownload={true}
              onExtractSubmitted={onExtractSubmitted}
            />
          ))
        }
      </div> 
    </div>
    
  )
}

export default withStyles(styles)(DataRisk);