import React, { useState } from 'react';
import { withStyles, withTheme, Typography, MobileStepper, Button} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { formatNumber, getCollectionColour, getIconComponent, onClickResultItem, titleCaseObjectName } from '../../../utilities';
import KTooltip from '../../UI/KTooltip/KTooltip';
import UserChip from '../../UI/Chips/UserChip';
import axiosSolr from '../../../axios-solr';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  container:{
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    borderRadius:4,
    display:'flex',
    flexDirection:'column',
    height:288,
    flex:'0 0 32%',
    marginLeft:16,
    minWidth:460,
    marginBottom:24,
    overflow:'hidden'
  },
  headerSection:{
    flexGrow:0,
    flexShrink:0,
    height:102,
  },
  contentSection:{
    flexGrow:1,
    flexShrink:1,
    // borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    padding:'8px 24px',
    marginTop:8,
    marginBottom:8,
    overflow:'auto',
    ...theme.components.customScroll
  },
  footerSection:{
    flexGrow:0,
    flexShrink:0,
    height:48,
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
  },
  mobileStepper:{
    width:'100%',
    background:theme.palette.background.main,
  },
  icon:{
    marginTop:24,
    marginLeft:22,
    marginRight:24,
    width:30,
    height:30,
    flexGrow:0,
    flexShrink:0,
  },
  header:{
    fontSize:20,
    marginLeft:24,
    marginRight:24,
    marginTop:8,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    cursor:'pointer',
    '&:hover':{
      textDecoration:'underline'
    }
  },
  subtitle:{
    fontSize:12,
    marginLeft:24,
    marginRight:24,
    color:theme.palette.primaryText.light,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  contentHeader:{
    fontSize:13,
  },
  description:{
    color:theme.palette.primaryText.light,
    fontSize:13.75,
    wordBreak:'break-word',
  },
  detailSection:{
    display:'flex',
    alignItems:'center',
    marginBottom:12,
    overflow:'hidden'
  },
  overflowText:{
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  tag:{
    height:24,
    borderRadius:12,
    padding:'0px 8px',
    margin:'2px 6px 2px 0',
    fontSize:13.75,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
  }
})

function SourceStepperCard(props) {

  const {
    // history,
    // state,
    // dispatch,
    theme,
    source,
    classes,
  } = props;
  
  const [activeStep, setActiveStep] = useState(0)

  const {
    sendAlert,
  } = useAlert({})
  
  const getObjectNameAndCount = () => {
    let sourceType = source.source_template?.type;
    let objectOrder = [];
    if(sourceType==='DATABASE'){
      objectOrder = ['DATABASE','SCHEMA','TABLE']
    }
    if(sourceType==='TOOL'){
      objectOrder = ['CONTENT_APP','WORKSPACE','REPORT','DATASET','DATA_PIPELINE','ML_MODEL','DQ_TEST']
    }
    let chips = []
    for(let i=0;i<objectOrder.length;i++){
      let count = source.object_count.find(s=>s.val===objectOrder[i])?.count;
      if(count>0){
        chips.push({objectName:objectOrder[i],objectCount:count})
      }
    }
    return chips;
  }

  let isRef = source?.object?.name==='HOST';
  let chips = getObjectNameAndCount()
  let collectionStats = {};
  if(source.node_stats){
    if(source.node_stats.top_5_platform_classifications){
      collectionStats['Classification'] = source.node_stats.top_5_platform_classifications.split(',')
    }
    if(source.node_stats.top_5_platform_domains){
      collectionStats['Domain'] = source.node_stats.top_5_platform_domains.split(',')
    }
    if(source.node_stats.top_5_platform_verified_use_cases){
      collectionStats['Verified Use Case'] = source.node_stats.top_5_platform_verified_use_cases.split(',')
    }
  }

  const onClickInstanceTag = ({collectionName,instanceName}) => {
    axiosSolr
      .get(
        `/solr/search/select`,{
          params:{
            q:"*",
            fq:`object_type_srt:collection_instance AND collection_srt:"${collectionName}" AND name_srt:"${instanceName}"`,
            rows:1,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound>0){
          let instance = response.data.response.docs[0]
          onClickResultItem({item:instance, id:instance.id, label:'collection_instance',newWindow:true})
        }
      })
      .catch(error=>{
        console.log(error)
        sendAlert({type:'info',message:'Error opening profile page'})
      })
  }
  
  let name, hostName;
  if(isRef){
    name = source?.alternate_name || 'Unknown';
    hostName = source?.name || 'Unknown';
  }else{
    name = source?.name || 'Unknown';
    hostName = source?.host || 'Unknown';
  }


  const maxSteps = 2;

  let bodyContent;

  if(activeStep===0){
    bodyContent = (
      <div>
        <div style={{maxHeight:66,marginBottom:12,overflow:'hidden'}}>
          <Typography data-test-classname="card-description" className={classes.description}>
            {source.description || 'No description provided'}
          </Typography>
        </div>
        <div style={{display:'flex',flexWrap:"wrap"}}>
          {
            collectionStats.Domain?.map(el=>{
              return (
                <KTooltip title={el} key={el}>
                  <div 
                    data-test-classname="card-domain-chip" 
                    className={classes.tag}
                    onClick={()=>{
                      onClickInstanceTag({collectionName:'domain',instanceName:el})
                    }}
                    style={{
                      background:getCollectionColour('domain','manual',el).colour,
                      color:getCollectionColour('domain','manual',el).fontColour
                    }}
                  >
                    <span className={classes.overflowText}>{el}</span>
                  </div>
                </KTooltip>
              )
            })
          }
        </div>
        <div style={{display:'flex',flexWrap:"wrap"}}>
          {
            chips.map(el=>{
              let primaryObjectName = el.objectName;
              let objectCount = el.objectCount;
              return (
                <KTooltip 
                  title={formatNumber(objectCount) + ' ' + titleCaseObjectName(primaryObjectName) + '(s)'} 
                  key={primaryObjectName}
                >
                  <UserChip
                    testID={'card-count-chip'}
                    user={{name:formatNumber(objectCount) + ' ' + titleCaseObjectName(primaryObjectName) + '(s)'}}
                    iconLabel={primaryObjectName}
                    avatarGap={6}
                    onClick={()=>{
                      onClickResultItem({item:source, id:isRef?source.id:source.host_ids[0],label:'source',newWindow:true, query:['assetTabName='+primaryObjectName]})
                    }}
                  />
                </KTooltip>
              )
            })
          }
        </div>
      </div>
    )
  }
  if(activeStep===1){
    bodyContent = (
      <div>
        {
          Object.keys(collectionStats).length===0 &&
          <Typography className={classes.description}>
            No additional details available
          </Typography>
        }
         {
          collectionStats['Verified Use Case']?.length>0 && 
          <div className={classes.detailSection}>
            <Typography className={classes.contentHeader} style={{flex:'0 0 120px'}}>Verified Use Cases:</Typography>
            <div style={{display:'flex',overflow:'hidden'}}>
              {
                collectionStats['Verified Use Case'].map(el=>{
                  return (
                    <KTooltip title={el} key={el}>
                      <div 
                        data-test-classname="card-verified-chip" 
                        className={classes.tag}
                        onClick={()=>{
                          onClickInstanceTag({collectionName:'verified use case',instanceName:el})
                        }}
                        style={{
                          background:getCollectionColour('verified','manual',el).colour,
                          color:getCollectionColour('verified','manual',el).fontColour
                        }}
                      >
                        <span className={classes.overflowText}>{el}</span>
                      </div>
                    </KTooltip>
                  )
                })
              }
            </div>
          </div>
        }
        {
          collectionStats.Classification?.length>0 && 
          <div className={classes.detailSection}>
            <Typography className={classes.contentHeader} style={{flex:'0 0 120px'}}>Classifications:</Typography>
            <div style={{display:'flex',overflow:'hidden'}}>
              {
                collectionStats.Classification.map(el=>{
                  return (
                    <KTooltip title={el} key={el}>
                      <div 
                        data-test-classname="card-classification-chip" 
                        className={classes.tag}
                        onClick={()=>{
                          onClickInstanceTag({collectionName:'classification',instanceName:el})
                        }}
                        style={{
                          background:getCollectionColour('classification','manual',el).colour,
                          color:getCollectionColour('classification','manual',el).fontColour
                        }}
                      >
                        <span className={classes.overflowText}>{el}</span>
                      </div>
                    </KTooltip>
                  )
                })
              }
            </div>
          </div>
        }
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.headerSection}>
        <div className={classes.icon}>
          {getIconComponent({label:isRef?'host':source.source_template?.name,size:30,colour:theme.palette.primaryText.light,defaultLabel:'source'})}
        </div>
        <KTooltip title={name} placement={'bottom-start'}>
          <Typography data-test-classname="card-name" className={classes.header} onClick={()=>onClickResultItem({item:source, id:isRef?source.id:source.host_ids[0],label:'source',newWindow:true})}>
            {name}
          </Typography>
        </KTooltip>
        <KTooltip title={hostName} placement={'bottom-start'}>
          <Typography data-test-classname="card-host-name" className={classes.subtitle}>
            {hostName}
          </Typography>
        </KTooltip>
      </div>
      <div className={classes.contentSection}>
        {bodyContent}
      </div>
      <div className={classes.footerSection}>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          className={classes.mobileStepper}
          nextButton={
            <Button data-test-classname="card-next-button" color='primary' size="small" onClick={()=>setActiveStep(activeStep+1)} disabled={activeStep === maxSteps - 1}>
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button data-test-classname="card-back-button" color='primary' size="small" onClick={()=>setActiveStep(activeStep-1)} disabled={activeStep === 0}>
              <KeyboardArrowLeft /> 
              Back
            </Button>
          }
        />
      </div>
    </div>
  )
}

export default withTheme()(withStyles(styles)(SourceStepperCard));