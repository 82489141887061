import React, { useState, useEffect, useRef } from 'react';
import { withTheme, withStyles, Typography, Button, Modal, Paper, IconButton, InputBase } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import { getIconComponent, removeUrlQueryArg } from '../../../utilities'
import fileDownloader from 'js-file-download';
import moment from 'moment';
import { getExtractName, getFileName } from '../Utils/Utils'
import axiosSolr from '../../../axios-solr'
import 'url-search-params-polyfill';
import { withRouter } from 'react-router'
import ScannerConfigs from './ScannerConfigs/ScannerConfigs';
import PropTypes from 'prop-types';
import useAlert from '../../../hooks/useAlert';
import { checkRequired, contentTypeOnlyExtracts, generatePayload, getModalParamsByCode, mapFilterValuesToStates, modalExtracts } from './utils';
import SourceSelector from './ExtractInputs/SourceSelector';
import SchemaSelector from './ExtractInputs/SchemaSelector';
import ObjectSelector from './ExtractInputs/ObjectSelector';
import ContentTypeSelector from './ExtractInputs/ContentTypeSelector';
import ZoneSelector from './ExtractInputs/ZoneSelector';
import SurveyCategorySelector from './ExtractInputs/SurveyCategorySelector';
import SurveyTemplateSelector from './ExtractInputs/SurveyTemplateSelector';
import ObjectTypeSelector from './ExtractInputs/ObjectTypeSelector';
import ExtractTeamSelector from './ExtractInputs/ExtractTeamSelector';
import UserNumSelector from './ExtractInputs/UserNumSelector';
import UserSelector from './ExtractInputs/UserSelector';
import DateRangeSelector from './ExtractInputs/DateRangeSelector';
import TrendSelector from './ExtractInputs/TrendSelector';
import UnusedPeriodSelector from './ExtractInputs/UnusedPeriodSelector';
import PeriodSelector from './ExtractInputs/PeriodSelector';
import KTooltip from '../../UI/KTooltip/KTooltip';
import AddAlertModal from './AddAlertModal/AddAlertModal';
import { checkEmailConfigured } from '../../../permissionChecker';

const styles = theme => ({
  itemTitle: {
    fontSize: 16,
    color: theme.palette.primaryText.main
  },
  itemSubtitle: {
    fontSize: 13.75,
    color: theme.palette.primaryText.light
  },
  modalBlock: {
    paddingBottom: 24,
  },
  modalBlockTitle: {
    fontSize: 12,
    color: theme.palette.primaryText.main,
    letterSpacing:1,
    marginBottom:8,
    textTransform:'uppercase'
  },
  selector: {
    ...theme.components.selector,
    width: '100%',
    height: 56,
    // backgroundColor: theme.palette.background.main,
    border: `1px solid ${theme.palette.border.main}`,
    '& svg': {
      color: theme.palette.primaryText.main,
      marginRight: 12
    },
    '& div div': {
      padding: '2px 16px 2px 16px'
    }
  },

  selectPaper: {
    background: theme.palette.background.main,
    border: `1px solid ${theme.palette.border.main}`
  },
  userChip: {
    padding: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    width: 'max-content',
    maxWidth: '100%',
    backgroundColor: theme.palette.chip.main,
    border: `1px solid ${theme.palette.border.main}`,
    minHeight: 27,
    borderRadius: 5,
    marginBottom:6,
    marginRight:6
  },
  button: {
    width: 90,
    marginLeft: 16
  },
  suggestionChip: {
    ...theme.components.suggestionChip
  },
  helperText: {
    color: theme.palette.primaryText.light,
    marginLeft: 16
  },
  inputBase: {
    ...theme.components.inputBase,
    '& div': {
      backgroundColor: 'transparent',
    },
    '& div:hover': {
      backgroundColor: 'transparent',
    },
    '& label': {
      color: theme.palette.primaryText.light
    },
    '& input': {
      color: theme.palette.primaryText.main,
      paddingLeft: 16
    }
  },
  disabledButton: {
    ...theme.components.disabledButton
  },
  downloadButton: {
    // height:40,
    margin: '8px 0 8px 16px',
    // padding:'6px 8px',
    display: 'flex',
    alignItems: 'center',
  }
})

const QuestionBoardChip = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    title,
    subtitle,
    code,
    buttonOnly,
    ////// 
    inputFieldOnly,
    onParamChange,
    //////
    removeButtonMargin,
    buttonText,
    defaultFilters,
    //
    disableAutoDownload,
    onExtractSubmitted,
    modalSubtitle,
    forceFileName,
    history
  } = props;

  const [createAlertModalOpen, setCreateAlertModalOpen] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [user, setUser] = useState();
  const [userList, setUserList] = useState([]);
  const [userNum, setUserNum] = useState('');
  const [dataSource, setDataSource] = useState('none');
  const [schema, setSchema] = useState('none');
  const [schemaList, setSchemaList] = useState();
  const [userSearchValue, setUserSearchValue] = useState('');
  const [teamSearchValue, setTeamSearchValue] = useState('');
  const [team, setTeam] = useState()
  const [trend, setTrend] = useState('none')
  const [period, setPeriod] = useState('none')
  const [zone, setZone] = useState('All')
  const [surveyCategory, setSurveyCategory] = useState('none')
  const [surveyTemplate, setSurveyTemplate] = useState('none')
  const [objectList, setObjectList] = useState([])
  const [objectType, setObjectType] = useState('none')
  const [contentType, setContentType] = useState('none')
  const [instance, setInstance] = useState('none')
  const [validObjectTypes, setValidObjectTypes] = useState()
  const [object, setObject] = useState()
  const [objectSearchValue, setObjectSearchValue] = useState('')

  //////////////
  const [scannerConfigModalOpen, setScannerConfigModalOpen] = useState(false)
  ///////

  const [modalTitle, setModalTitle] = useState();
  const [modalParams, setModalParams] = useState([]);

  const [modalOpen, setModalOpen] = useState(false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  let allParams = {
    code,
    dataSource,
    schema,
    startDate,
    endDate,
    surveyCategory,
    surveyTemplate,
    objectType, 
    zone,
    contentType,
    period,
    trend,
    userNum,
    user,
    userList,
    team,
    object,
    objectList,
    instance,
    sendAlert
  }

  let allSetters = {
    setDataSource,
    setSchema,
    setStartDate,
    setEndDate,
    setSurveyCategory,
    setSurveyTemplate,
    setObjectType,
    setZone,
    setContentType,
    setPeriod,
    setInstance,
    setTrend,
    setUserNum,
    setUser,
    setUserList,
    setTeam,
    setObject,
    setObjectList
  }

  useEffect(() => {
    if(inputFieldOnly){
      setModalParams(getModalParamsByCode(code))
    }
    return () => {
      isCancelledRef.current = true
    }
  // eslint-disable-next-line
  }, [])

  useEffect(()=>{
    // if(modalParams.length>0){
    if(defaultFilters){
      mapFilterValuesToStates({filters:defaultFilters, loadSchema, allSetters, modalParams})
    }
    // }
  // eslint-disable-next-line
  },[JSON.stringify(defaultFilters)])
  
  useEffect(()=>{
    onParamChange && onParamChange(allParams)
    // eslint-disable-next-line
  },[JSON.stringify(allParams)])

  const submitExtract = (payload, code, fileName = forceFileName) => {
    dispatch({ type: 'set_running_code', runningCode: code })
    axiosCerebrum.post(
      '/api/extracts', payload
    ).then(extractResponse => {
      if (disableAutoDownload) {
        onExtractSubmitted && onExtractSubmitted();
        return;
      }
      sendAlert({ message: 'Your answer is being generated. This may take a few minutes.', type: 'info' })
      let extractId = extractResponse.data.id;
      let downloaded = false;
      let previousReturned = true;
      let recursionTimes = 0;

      const onError = (interval) => {
        clearInterval(interval);
        dispatch({ type: 'set_running_code', runningCode: '' })
        sendAlert({ message: 'Error occurred downloading the file', type: 'error' })
      }

      let downloadExtractInterval = setInterval(() => {
        if (!previousReturned) return;
        if (recursionTimes > 200) {
          onError(downloadExtractInterval)
          return;
        }
        recursionTimes += 1;
        previousReturned = false;
        axiosCerebrum.get(`/api/extracts/${extractId}`).then(response => {
          previousReturned = true;
          if (response.data.status === 'COMPLETE') {
            if (downloaded) return;
            downloaded = true;
            axiosCerebrum.get(`/api/extracts/${extractId}/download`)
              .then(downloadRes => {
                clearInterval(downloadExtractInterval);
                fileDownloader(downloadRes.data.replace(/\|/g, ','), `${fileName || getFileName(code, {extract:response.data, sourceList: state.sources, dataSource, user, userNum, trend, zone, team, period, startDate, endDate })}.csv`);
                dispatch({ type: 'set_running_code', runningCode: '' })
              })
              .catch(error => {
                console.log(error)
                onError(downloadExtractInterval);
              })
          }
          if (response.data.status === 'FAILED') {
            onError(downloadExtractInterval);
          }
        }).catch(error => {
          console.log(error);
          onError(downloadExtractInterval);
          previousReturned = true;
        })
      }, 2000)
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: 'set_running_code', runningCode: '' })
      sendAlert({ message: 'Error occurred downloading the file', type: 'error' })
    })
  }

  const getModalSubtitle = code => {
    if (modalSubtitle) return modalSubtitle
    return 'Set the report parameters and click RUN to generate the report'
  }

  const createPayload = code => {
    if (modalExtracts.includes(code)) {
      if (code === '0360') {
        setScannerConfigModalOpen(true)
        return;
      }
      setModalTitle(getExtractName(code, { object_type: objectType }).toUpperCase());
      setModalParams(getModalParamsByCode(code))
      setModalOpen(true)
      return;
    }
    let payload = generatePayload(allParams)
    submitExtract(payload, code)
  }

  const clearStates = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setUser();
    setTeam();
    setDataSource('none');
    setSchema('none');
    setObject();
    setUserNum('');
    setTrend('none');
    setPeriod('none')
  }

  const onModalDownload = code => {
    let payload = generatePayload(allParams)

    clearStates();
    submitExtract(payload, code)
    setModalOpen(false);
  }

  const onModalCancel = () => {
    setModalOpen(false);
    clearStates();
  }

  const setStartDateByDiff = days => {
    setStartDate(moment().add(-days, 'days'));
    setEndDate(moment());
  }

  const onSurveyTemplateChange = value => {
    setSurveyTemplate(value);
    setObjectType('none');
    if (value !== 'none') {
      axiosCerebrum
        .get(`/api/surveys/attributes`, {
          params: {
            attribute: 'object_type',
            survey_template_id: value
          }
        })
        .then(response => {
          setValidObjectTypes(response.data.items)
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      setValidObjectTypes()
    }
  }

  const getButtonStyle = () => {
    if (code !== state.runningCode && state.runningCode !== '') {
      return theme.palette.primaryText.light
    }
    return theme.palette.primary.main;
  }

  const loadSchema = ({ previousData = [], sourceId }) => {
    axiosSolr
      .get(
        `/solr/search/select`,
        {
          params: {
            q: '*',
            fq: `source_id_srt:${sourceId} AND object_type_srt:SCHEMA`,
            rows: 20,
            start: previousData.length
          }
        }
      )
      .then(response => {
        let newList = [...previousData, ...response.data.response.docs];
        if (newList.length >= response.data.response.numFound) {
          setSchemaList(newList)
        } else {
          loadSchema({ previousData: newList, sourceId })
        }
      })
      .catch(error => {
        console.log(error)
        setSchemaList(previousData)
      })
  }

  const onChangeDataSource = id => {
    setDataSource(id);
    setSchemaList();
    setSchema('none');
    if (!modalParams.includes('schema')) return;
    if (state.sources.filter(el => el.source_template.type === 'DATABASE').find(el => el.id === id)) {
      loadSchema({ sourceId: id })
    }
  }

  useEffect(() => {
    const urlSearch = new URLSearchParams(window.location.search);
    const presetCode = urlSearch.get('presetCode');
    if (presetCode && presetCode === code) {
      createPayload(code);
      const objectId = urlSearch.get('objectId');
      if (objectId) {
        axiosSolr
          .get(
            `/solr/search/select`,
            {
              params: {
                q: "*",
                fq: `id:${objectId}`
              }
            }
          )
          .then(response => {
            let resp = response.data.response.docs[0]
            setObject(resp)
            if (resp) setDataSource(resp.source_id_srt)
          })
          .catch(error => {
            console.log(error)
          })
      }
      window.history.replaceState(null, null, removeUrlQueryArg({ url: window.location.toString(), keys: ['presetCode', 'objectId'] }));
    }
    // eslint-disable-next-line
  }, [])
  
  const inputComponent = (
    <>
      {
        modalParams.includes('dataSource')?
        <SourceSelector
          classes={classes}
          theme={theme}
          code={code}
          dataSource={dataSource}
          onChangeDataSource={onChangeDataSource}
          sources={state.sources || []}
        />
        :null
      }
      {
        modalParams.includes('schema') && schemaList ?
        <SchemaSelector
          classes={classes}
          theme={theme}
          schema={schema}
          setSchema={setSchema}
          schemaList={schemaList}
        />
        :null
      }
      {
        modalParams.includes('contentType') ?
        <ContentTypeSelector
          classes={classes}
          theme={theme}
          contentType={contentType}
          setContentType={setContentType}
        />
        :null
      }
      {
        (modalParams.includes('object')) ?
        <ObjectSelector
          classes={classes}
          theme={theme}
          object={object}
          setObject={setObject}
          objectSearchValue={objectSearchValue}
          setObjectSearchValue={setObjectSearchValue}
          dataSource={dataSource}
          schema={schema}
          schemaList={schemaList}
          required={true}
        />
        :null
      }
      {
        modalParams.includes('objectList') ?
        <ObjectSelector
          classes={classes}
          theme={theme}
          objectList={objectList}
          setObjectList={setObjectList}
          objectSearchValue={objectSearchValue}
          setObjectSearchValue={setObjectSearchValue}
          dataSource={dataSource}
          objectType={contentTypeOnlyExtracts.includes(code)?contentType:objectType}
          isMulti={true}
          required={['0390'].includes(code)?false:true}
        />
        :null
      }
      {
        modalParams.includes('zone') ?
        <ZoneSelector
          classes={classes}
          theme={theme}
          zone={zone}
          setZone={setZone}
        />
        :null
      }
      {
        modalParams.includes('surveyCategory') ?
        <SurveyCategorySelector
          classes={classes}
          theme={theme}
          surveyTemplates={state.surveyTemplates}
          surveyCategory={surveyCategory}
          setSurveyCategory={setSurveyCategory}
          setSurveyTemplate={setSurveyTemplate}
          setObjectType={setObjectType}
        />
        :null
      }
      {
        modalParams.includes('surveyTemplate') ?
        <SurveyTemplateSelector
          classes={classes}
          theme={theme}
          surveyTemplates={state.surveyTemplates}
          surveyCategory={surveyCategory}
          surveyTemplate={surveyTemplate}
          onSurveyTemplateChange={onSurveyTemplateChange}
        />
        :null
      }
      {
        modalParams.includes('objectType') ?
        <ObjectTypeSelector
          classes={classes}
          theme={theme}
          objectType={objectType}
          setObjectType={setObjectType}
          validObjectTypes={validObjectTypes}
        />
        :null
      }
      {
        modalParams.includes('team') ?
        <ExtractTeamSelector
          classes={classes}
          theme={theme}
          teamSearchValue={teamSearchValue}
          setTeamSearchValue={setTeamSearchValue}
          team={team}
          setTeam={setTeam}
        />
        :null
      }
      {
        modalParams.includes('userNum') ?
        <UserNumSelector
          classes={classes}
          theme={theme}
          userNum={userNum}
          setUserNum={setUserNum}
        />
        :null
      }
      {
        (modalParams.includes('user') || modalParams.includes("owner") || modalParams.includes("steward")) ?
        <UserSelector
          classes={classes}
          theme={theme}
          userSearchValue={userSearchValue}
          setUserSearchValue={setUserSearchValue}
          user={user}
          setUser={setUser}
          modalParams={modalParams}
          code={code}
        />
        :null
      }
      {
        modalParams.includes('userList') ?
        <UserSelector
          classes={classes}
          theme={theme}
          userSearchValue={userSearchValue}
          setUserSearchValue={setUserSearchValue}
          userList={userList}
          setUserList={setUserList}
          isMulti={true}
          modalParams={modalParams}
          code={code}
        />
        :null
      }
      {
        modalParams.includes('dateRange') ?
        <DateRangeSelector
          classes={classes}
          theme={theme}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDateByDiff={setStartDateByDiff}
        />
        :null
      }
      {
        modalParams.includes('trend') ?
        <TrendSelector
          classes={classes}
          theme={theme}
          trend={trend}
          setTrend={setTrend}
        />
        :null
      }
      {
        modalParams.includes('unusedPeriod') ?
        <UnusedPeriodSelector
          classes={classes}
          theme={theme}
          period={period}
          setPeriod={setPeriod}
        />
        :null
      }
      {
        modalParams.includes('period') ?
        <PeriodSelector
          classes={classes}
          theme={theme}
          period={period}
          setPeriod={setPeriod}
          code={code}
        />
        :null
      }
    </>
  )
  
  if(inputFieldOnly){
    return (
      <>
        {inputComponent}
        {
          modalParams.length===0 && 
          <div>
            <Typography className={classes.modalBlockTitle}>REPORT</Typography>
            <InputBase
              value={getExtractName(code)}
              disabled={true}
              className={classes.inputBase}
              style={{width:'100%',height:56}}
            />
          </div>
        }
      </>
    )
  }

  return (
    <div >
      {
        !buttonOnly &&
        <div style={{ display: 'flex', alignItems: 'center', padding: '8px 0px', borderBottom: '1px solid #DDD' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 600px' }}>
            <Typography className={classes.itemTitle}>{title}</Typography>
            <Typography className={classes.itemSubtitle} >{subtitle}</Typography>
          </div>
          <KTooltip title={"Run"}>
            <IconButton data-testid={`Run-${code}`} onClick={() => createPayload(code)}>
              {getIconComponent({label:'play',size:24,colour:theme.palette.primaryText.light})}
            </IconButton>
          </KTooltip>
          {
            ['0140','0005','0360','0080'].includes(code)?
            <div style={{width:40}}></div>:
            <KTooltip title={checkEmailConfigured()?"Create Alert":"Email integration needs to be configured to send Alerts. Contact your K administrator"}>
              <div style={{width:40,height:40}}>
                <IconButton disabled={!checkEmailConfigured()} data-testid={`Create-alert-${code}`} onClick={() => setCreateAlertModalOpen(true)}>
                  {getIconComponent({label:'add_alert',size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
              </div>
            </KTooltip>
          }
        </div>
      }
      {
        buttonOnly &&
        <div>
          {
            <div>
              {
                <Button
                  variant='outlined'
                  data-testid={`Run-${code}`}
                  disabled={(code !== state.runningCode && state.runningCode !== '') || code === state.runningCode}
                  className={classes.downloadButton}
                  color='primary'
                  style={{ color: getButtonStyle(), border: `1px solid ${getButtonStyle()}`, margin: removeButtonMargin ? 0 : undefined }}
                  onClick={() => code !== state.runningCode && state.runningCode === '' && createPayload(code)}
                >
                  {code === state.runningCode ? 'RUNNING' : (buttonText || 'DOWNLOAD')}
                </Button>
              }
            </div>
          }
        </div>
      }

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        disableBackdropClick={true}
      >
        <div style={{ width: `100%`, outline: 'none', height: `100vh`, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Paper style={{ maxHeight: '80vh', marginTop: '-5vh', width: 700, padding: '30px 40px 0px', overflow: 'auto', background: theme.palette.background.main, border: `1px solid ${theme.palette.border.main}` }}>
            <Typography style={{ color: theme.palette.header.main, fontSize: 20, marginBottom: 4 }}>{modalTitle}</Typography>
            <Typography style={{ fontSize: 12, marginBottom: 8, color: theme.palette.primaryText.light }}>{getModalSubtitle(code)}</Typography>
            {inputComponent}
            <div style={{ height: 20 }}></div>
            <div style={{ position: 'sticky', paddingBottom: 16, bottom: 0, backgroundColor: theme.palette.background.main, display: 'flex', justifyContent: 'flex-end', paddingTop: 16, zIndex: 1 }}>
              <Button 
                color='primary' 
                className={classes.button} 
                classes={{ disabled: classes.disabledButton }} 
                disabled={!checkRequired(allParams)} 
                data-testid={`DownloadButton`} 
                onClick={() => onModalDownload(code)}
              >
                RUN
              </Button>
              <Button 
                color='secondary' 
                className={classes.button} 
                onClick={() => onModalCancel()}
              >
                CANCEL
              </Button>
            </div>
          </Paper>
        </div>
      </Modal>

      {
        code === '0360' &&
        <ScannerConfigs
          modalOpen={scannerConfigModalOpen}
          setModalOpen={setScannerConfigModalOpen}
          state={state}
          dispatch={dispatch}
          submitExtract={submitExtract}
        />
      }
      <AddAlertModal
        modalOpen={createAlertModalOpen}
        setModalOpen={() => setCreateAlertModalOpen(false)}
        state={state}
        dispatch={dispatch}
        history={history}
        code={code}
        defaultFilters={defaultFilters}
      />
    </div>
  )
}

QuestionBoardChip.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  buttonOnly: PropTypes.bool,
  inputFieldOnly: PropTypes.bool,
  onParamChange: PropTypes.func,
  buttonText: PropTypes.string,
  defaultFilters: PropTypes.object,
  disableAutoDownload: PropTypes.bool,
  onExtractSubmitted: PropTypes.string,
  modalSubtitle: PropTypes.string,
  forceFileName: PropTypes.string,
  removeButtonMargin: PropTypes.bool,
}

export default withTheme()(withStyles(styles)(withRouter(QuestionBoardChip)));