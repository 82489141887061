import React, { useRef, useEffect} from 'react';
import { withTheme,withStyles, InputBase,IconButton} from '@material-ui/core';
import { getIconComponent } from '../../../../utilities';
import ClearableSelector from '../../../UI/ClearableSelector/ClearableSelector';
import { contentItems, dataItems, userItems } from '../../utils';
import axiosSolr from '../../../../axios-solr';

const styles = theme => ({
  root:{
    display:'flex',
    alignItems:'flex-start',
    flexWrap:'wrap'
  },
  inputBase:{
    ...theme.components.inputBase,
    width:240,
    marginRight:32
  }
})

const Filters = props => {

  const {
    classes,
    theme,
    extractID,
    filters,
    filterData,
    setFilterData,
    onLoadData,
    filterValueData,
    setFilterValueData,
    selectedTile
  } = props;

  const searchTimeoutRef = useRef()

  const searchValue = filterData['search']
  const setSearchValue = value => {
    let newData = {...filterData,search:value}
    setFilterData(newData)
    clearTimeout(searchTimeoutRef.current)
    searchTimeoutRef.current = setTimeout(()=>{
      onLoadData({filterPyload:newData})
    },250)
  }

  const onFilterChange = (field, value) => {
    let newData = {...filterData,[field]:value}
    setFilterData(newData)
    onLoadData({filterPyload:newData})
  }

  const loadFilterValues = () => {
    let fq = [
      `extract_id:${extractID}`
    ];
    if(selectedTile==='all'){
      fq.push(`object_type_srt:(${[...dataItems,...contentItems].join(' OR ')})`)
    }
    else if(selectedTile==='data'){
      fq.push(`object_type_srt:(${dataItems.join(' OR ')})`)
    }
    else if(selectedTile==='content'){
      fq.push(`object_type_srt:(${contentItems.join(' OR ')})`)
    }
    else if(selectedTile==='people'){
      fq.push(`object_type_srt:(${userItems.join(' OR ')})`)
    }
    else{
      fq.push(`object_type_srt:COLLECTION_INSTANCE`)
      fq.push(`parent_id_srt:${selectedTile}`)
    }

    let facet = {}
    filters.forEach(f=>{
      facet[f.field] = {
        "type": "terms",
        "field": f.field,
        "mincount":1,
        "missing":true
      }
    })

    axiosSolr
      .get(
        `/solr/impact/select`,{
          params:{
            q:'*',
            fq:fq.join(' AND '),
            rows:0,
            'json.facet':facet
          }
        }
      )
      .then(response=>{
        let filterValue = {};
        filters.forEach(f=>{
          let buckets = (response.data.facets && response.data.facets[f.field] && response.data.facets[f.field].buckets) || [];
          let missing = (response.data.facets && response.data.facets[f.field] && response.data.facets[f.field].missing && response.data.facets[f.field].missing.count) || 0;
          filterValue[f.field] = buckets.map(b=>b.val)
          if(f.field==='max_level_srt')filterValue[f.field].sort((a,b)=>Number(a).valueOf()-Number(b).valueOf())
          if(missing!==0 && f.field==='teams_msrt'){
            filterValue[f.field].push('No Team')
          }
        })
        setFilterValueData(filterValue)
      })
      .catch(error=>{
        console.log(error)
      })
  }
  
  useEffect(()=>{
    if(filters.length===0 || Object.keys(filterValueData).length>0)return;
    loadFilterValues()
  })
  
  return (
    <div className={classes.root} style={{justifyContent:selectedTile==='all'?'flex-end':'flex-start'}}>
      <InputBase
        value={searchValue}
        onChange={(event)=>setSearchValue(event.target.value)}
        className={classes.inputBase}
        style={
          selectedTile==='all'?{
            marginBottom:16,
            marginTop:-48
          }:
          {}
        }
        inputProps={{
          'data-test-id':'detail-search-input'
        }}
        placeholder={`Search`}
        endAdornment={
          <IconButton onClick={()=>setSearchValue('')} disabled={!searchValue}>
            {getIconComponent({label:searchValue?'clear':'search',size:24,colour:theme.palette.primaryText.light})}
          </IconButton>
        }
      />
      {
        filters.map(f=>{
          return (
            <div style={{marginLeft:16}}>
              <ClearableSelector
                testID={`detail-filter-${f.field}`}
                width={160}
                value={filterData[f.field]!==undefined?filterData[f.field]:'all'}
                onChange={event=>onFilterChange(f.field, event.target.value)}
                options={[
                  {value:'all',name:`All ${f.name}(s)`},
                  ...(filterValueData[f.field]||[]).map(v=>({
                    value:v, name:v
                  }))
                ]}
                onReset={()=>{onFilterChange(f.field, 'all')}}
              />
            </div>
          )
        })
      }
    </div>  
  )
}

export default withTheme()(withStyles(styles)(Filters));