import React, { useEffect, useReducer } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { LinearProgress, Typography } from '@material-ui/core';
import Body from '../../components/TeamProfile/Body/Body';
import TabBar from '../../components/UI/TabBar/TabBar';
import PropTypes from 'prop-types';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import {  setInitialState, handleShareClick, getDispFields } from '../../utilities';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
// import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import {addHistory} from '../../HistoryManager'
// import axiosSolr from '../../axios-solr'
import axiosCerebrum from '../../axios-cerebrum';
import axiosSolr from '../../axios-solr';

const styles = theme => ({
  normalText:{
    color:theme.palette.primaryText.main
  }
});

const initialState = {
  tabState: 0,
  dataTabState:0,
  contentTabState:0,
  listData:{},
  listSort: 'last_used_srt desc',
  memberSearch:'',
  teamSearch:""
}

const reducer = (state, action) => {
  switch (action.type) {
    case "set_tab_state":
      return {
        ...state,
        tabState: action.tabState
      }
    case 'set_list_show_deleted':
      return {
        ...state,
        listShowDeleted:action.listShowDeleted
      }
    case 'set_membership_tab_state':{
      return {
        ...state,
        membershipTabState:action.membershipTabState
      }
    }
    case "set_basic_data":
      return {
        ...state,
        basicData: action.basicData,
        basicDataError:action.basicDataError,
        basicDataLoading:action.basicDataLoading
      }
    case "set_member_data":
      return {
        ...state,
        memberData: action.memberData,
        memberLoading: action.memberLoading,
        memberError: action.memberError,
        memberNum: action.memberNum
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData: action.insightsData,
      }
    
    case 'set_data_tab_state':
      return {
        ...state,
        dataTabState:action.dataTabState
      }
    case 'set_content_tab_state':
      return {
        ...state,
        contentTabState:action.contentTabState
      }
    case 'set_list_sort':
      return {
        ...state,
        listSort:action.listSort
      }
    case 'set_list_search':
      return{
        ...state,
        listSearch:action.listSearch
      }
    case 'set_list_data':
      return {
        ...state,
        listData:action.listData
      }
    case 'set_parent':
      return {
        ...state,
        parentData:action.parentData,
        parentLoading:action.parentLoading,
        parentError:action.parentError
      }
    case 'set_children':
      return {
        ...state,
        childrenData: action.childrenData,
        childrenLoading:action.childrenLoading,
        childrenError:action.childrenError
      }
    case 'set_member_search':
      return {
        ...state,
        memberSearch:action.memberSearch
      }
    case 'set_data_tab_option':
      return {
        ...state,
        dataTabOption:action.dataTabOption,
        dataTabOptionLoading:action.dataTabOptionLoading,
        dataTabOptionError:action.dataTabOptionError
      }
    case 'set_content_tab_option':
      return {
        ...state,
        contentTabOption:action.contentTabOption,
        contentTabOptionLoading:action.contentTabOptionLoading,
        contentTabOptionError:action.contentTabOptionError
      }
    default:
      throw new Error("Action type not supoorted");
  }
}

const TeamProfile = props => {

  const {
    match,
    theme,
    history,
    classes,
    pageCache,
    storePageCache
  } = props;

  const tabOptions = ['DETAILS','DATA','CONTENT'];

  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache,initialState));


  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  // eslint-disable-next-line
  },[state])

  
  const fetchList = () => {
    dispatch({type:'set_basic_data',basicDataLoading:state.basicData?false:true,basicData:state.basicData});
    axiosCerebrum
      .get(
        `/api/groups/${match.params.id.toLowerCase()}`,
      )
      .then(response=>{
        addHistory({url:window.location.pathname, title: getDispFields(response.data,'dispTitle'), subTitle:getDispFields(response.data,'dispSubtitle'),object:response.data,type:'profile'})
        dispatch({ type: 'set_basic_data', basicData: response.data})
      })
      .catch(error=>{
        console.log(error)
        dispatch({ type: 'set_basic_data', basicDataError: true})
      })
  }


  useEffect(() => {
    if(!state.basicData){
      fetchList();
    }
    // eslint-disable-next-line
  }, [])


  if (state.basicDataLoading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.basicDataError) {
    return (
      <DeadEnd />
    )
  }

  if(!state.basicData){
    return <div></div>
  }

  const loadDataTabOption = async () => {
    dispatch({
      type:'set_data_tab_option',
      dataTabOptionLoading:true
    })
    let options = [];
    try{
      await axiosCerebrum
        .get(
          `/api/groups/${match.params.id.toLowerCase()}/related`,{
            params:{
              relationship:'KEY_IN_AUTO',
              object_name:'TABLE',
              per_page:0
            }
          }
        )
        .then(response=>{
          if(response.data.total>0){
            options.push("KEY_TABLE")
          }
        })
      await axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:"TABLE" AND teams_msrt:"${state.basicData.name}"`,
              rows:0
            }
          }
        )
        .then(response=>{
          if(response.data.response.numFound>0){
            options.push("TABLE")
          }
        })
      dispatch({
        type:'set_data_tab_option',
        dataTabOption:options
      })
    }catch(error){
      console.log(error)
      dispatch({
        type:'set_data_tab_option',
        dataTabOptionError:true
      })
    }
    
  }

  const loadContentTabOption = async () => {
    dispatch({
      type:'set_content_tab_option',
      contentTabOptionLoading:true
    })
    let options = [];
    try{
      await axiosCerebrum
        .get(
          `/api/groups/${match.params.id.toLowerCase()}/related`,{
            params:{
              relationship:'KEY_IN_AUTO',
              object_name:'CONTENT',
              per_page:0
            }
          }
        )
        .then(response=>{
          if(response.data.total>0){
            options.push("KEY_REPORT")
          }
        })
      await axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:(REPORT OR DATASET OR DATASET_TABLE OR FILE OR DATA_PIPELINE OR WORKSPACE) AND teams_msrt:"${state.basicData.name}"`,
              rows:0,
              'json.facet':{
                object_type:{
                  "type": "terms",
                  "field":"object_type_srt",
                  'min.count':1
                }
              }
            }
          }
        )
        .then(response=>{
          if(response.data.facets.object_type && response.data.facets.object_type.buckets){
            response.data.facets.object_type.buckets.forEach(b=>{
              options.push(b.val)
            })
          }
        })
      dispatch({
        type:'set_content_tab_option',
        contentTabOption:options
      })
    }catch(error){
      console.log(error)
      dispatch({
        type:'set_content_tab_option',
        contentTabOptionError:true
      })
    }
    
  }



  let buttons = [];

  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )

  let bannerdisplayText = '';
  let bannerCause = '';
  if (state.basicData && state.basicData.active_flag === false) {
    bannerdisplayText = 'This group has been disabled';
    bannerCause = 'active';
  }

  return (
    <ProfileLayout
      header={
        <ProfileHeader
          type='tool'
          title={state.basicData.name}
          subtitle={''}
          label={'group'}
          buttons={buttons}
          data={state.basicData}
          history={history}
          avatarIcon={true}
          shouldLoadBreadCrumb
          bannerdisplayText={bannerdisplayText}
          bannerCause={bannerCause}
        />
      }
      disableTabBarOffset
      tabBar={
        <TabBar
          minWidth={200}
          maxWidth={200}
          tabOptions={tabOptions}
          tabState={state.tabState}
          setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
          disableScroll={true}
          disableUnderline={true}
        />
      }
      body={
        <Body
          tabState={state.tabState}
          nodeKey={match.params.id.toLowerCase()}
          history={history}
          state={state}
          dispatch={dispatch}
          fetchList={fetchList}
          loadDataTabOption={loadDataTabOption}
          loadContentTabOption={loadContentTabOption}
        />
      }
      // sideBar={
      //   <ProfileSideBar
      //     history={history}
      //     data={state.basicData}
      //     dispatch={dispatch}
      //     state={state}
      //     mapping={['topcontent','topdata']}
      //   />
      // }
    />
  )
}

TeamProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sessionData: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(TeamProfile)));