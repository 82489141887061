import React, {useEffect, useState, useRef} from 'react';
import { withTheme, Typography, withStyles, Checkbox, Button, CircularProgress } from '@material-ui/core';
import ResultItem from '../ResultItem/ResultItem'
import AscIcon from '@material-ui/icons/ArrowUpward'
import DescIcon from '@material-ui/icons/ArrowDownward'
import ColumnSelector from '../ColumnSelector/ColumnSelector'
import { formatColumnNames, generateSearchFq, getNameWidth, getSearchParams, getSearchQuery, processObjectType } from '../Utils/Utils'
import { formatNumber, sendMessage, titleCaseObjectName } from '../../../../utilities';
import axiosCerebrum from '../../../../axios-cerebrum';
import { useStore } from 'react-redux';
import { KUpdateOnlyObjects, checkCanAddToCart } from '../../../../permissionChecker';
import SnackBar from '../../../UI/SnackBar/SnackBar';
import axiosSolr from '../../../../axios-solr'
import fileDownloader from 'js-file-download';
import {DndContext} from '@dnd-kit/core';
import {SortableContext, arrayMove, horizontalListSortingStrategy} from '@dnd-kit/sortable';
import SortableColumn from './SortableColumn';
import useAlert from '../../../../hooks/useAlert';

const styles = theme => ({
  root:{
  },
  title:{
    color:theme.palette.header.main,
    fontSize:20
  },
  columnContainer:{
    paddingTop:8,
    position:'sticky',
    top:58,
    zIndex:999,
    background:theme.palette.background.main,
  },
  columnHeader:{
    display:'flex',
    alignItems:'flex-start',
    padding:'0 16px',
    paddingBottom:8,
    paddingTop:8,
    minWidth:768
  },
  mainContent:{
    // flex:'0 0 350px',
    flexGrow:0,
    flexShrink:0,
    // paddingLeft:56,
    // minWidth:220,
    paddingRight:40,
    display:'flex',
    alignItems:'center'
  },
  columnBoxes:{
    // flex:'0 0 110px',
    flexGrow:0,
    flexShrink:0,
    marginRight:16,
    padding:'2px 0px 2px 4px',
    position:'relative',
    boxSizing:'border-box',
    left:-4,
    borderRadius:2,
    display:'flex',
    alignItems:'center',
    '&:hover':{
      background:theme.palette.hovered.main,
    }
  },
  sortable:{
    cursor:'pointer',
  },
  columnText:{
    fontSize:12,
    color:theme.palette.primary.main,
    letterSpacing:2,
    wordBreak:'break-word'
  },
  sortIconWrapper:{
    height:16,
    width:16,
    marginLeft:4
  },
  helperText:{
    fontSize:13.75,
    marginTop:16,
    color:theme.palette.primaryText.light,
  },
  clickableText:{
    cursor:'pointer',
    textDecoration:'underline'
  },
  hideScroll:{
    ...theme.components.hideScroll
  }
});



const ResultList = props => {

  const {
    classes,
    state,
    dispatch,
    isNotSearchPage,
    history,
    onUpdateSort,
    loadSearchResults,
    theme,
    hiddenComponents,
    customHeaderFormatter,
    resultItemVariant,
    alwaysOpenNewTab,
    indexName,
    customEmptyMsg,
    forceOnItemClick
  } = props;

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarButton, setSnackBarButton] = useState([]);
  const [keepSnackbarOpen, setKeepSnackBarOpen] = useState(false);
  const [addingToCart, setAddingToCart] = useState(false);
  const [selectingAll, setSelectingAll] = useState(false)

  const store = useStore();
  const sessionData = store.getState().auth.session_user;

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    id:`basic-search-result-list`,
    isCancelledRef
  })

  useEffect(()=>{
    return () => {
      isCancelledRef.current = true
    }
  },[])

  const mapDisplayName = name => {
    name = processObjectType(name)
    if(name==='GLOSSARY')return 'TERM'
    return name;
  }

  const isSortable = column => {
    if(column.includes('_srt'))return true;
    if(column.includes('_count'))return true;
    if(column==='trust')return true;
    return false;
  }

  const onClickColumn = column => {
    let sortValue = column
    if(column==='trust')sortValue = 'trust_srt';
    if(column==='severity')sortValue = 'severity_srt';
    onUpdateSort(sortValue)
  }

  const getSortIcon = column => {
    let sortValue = column;
    if(sortValue==='trust')sortValue = 'trust_srt';
    if(sortValue==='severity')sortValue = 'severity_srt';
    if(state.mainSearchSort===`${sortValue} asc` ){
      return <AscIcon style={{width:16,height:16,color:theme.palette.primary.main}}/>
    }
    if(state.mainSearchSort===`${sortValue} desc`){
      return <DescIcon style={{width:16,height:16,color:theme.palette.primary.main}}/>
    }
  }

  const onClearDefaultFilter = () => {
    dispatch({
      type:'set_filter_status',
      filterStatus:{
        ...state.filterStatus,
        active_srt:undefined,
        reference_srt:undefined,
      }
    })

    let newFilters = state.mainSearchFilters.filter(el=>!el.includes('reference_srt:') && !el.includes('active_srt:'))
    dispatch({
      type:'set_main_search_filters',
      mainSearchFilters:newFilters
    })
    loadSearchResults({page:1,isReload:true,filters:newFilters})
  }

  const onSelectAllClick = () => {
    if(state.isSelectAll){
      dispatch({type:'set_cart_items',cartItems:[]})
      dispatch({type:'set_is_select_all',isSelectAll:false})
    }else{
      setSelectingAll(true)
      let waitTimeout = setTimeout(()=>{
        setSnackBarOpen(true)
        setSnackBarMessage(
          <div style={{display:'flex'}}>
            <CircularProgress color='secondary' style={{width:24,height:24}}/>
            <Typography style={{fontSize:13.75,marginLeft:16,color:theme.palette.snackbarContent.main}}>Selecting items...</Typography>
          </div>
        )
      },1000);

      loadSearchResults({
        page:1,
        isAddToCart:true,
        onLoaded: () => {
          clearTimeout(waitTimeout);setSnackBarOpen(false)
          setSelectingAll(false)
        }
      })
    }
  }

  const onAddToCart = () => {
    setAddingToCart(true)
    let waitTimeout = setTimeout(()=>{
      setKeepSnackBarOpen(true)
      setSnackBarOpen(true)
      setSnackBarMessage(
        <div style={{display:'flex'}}>
          <CircularProgress color='secondary' style={{width:24,height:24}}/>
          <Typography style={{fontSize:13.75,marginLeft:16,color:theme.palette.snackbarContent.main}}>Adding to cart...</Typography>
        </div>
      )
    },1000)
    
    let promises = [];
    
    let chunks = state.cartItems.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index/500)
      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }
      resultArray[chunkIndex].push(item)
      return resultArray
    }, [])

    chunks.forEach(chunk=>{
      promises.push(axiosCerebrum.post(`/api/me/cart`,{object_ids:chunk}))
    })

    Promise
      .all(promises)
      .then(response=>{
        setAddingToCart(false)
        clearTimeout(waitTimeout)
        setKeepSnackBarOpen(false)
        setSnackBarOpen(true)
        let msg = `${state.cartItems.length} items added to cart`
        if(KUpdateOnlyObjects.includes(state.selectedObjectType)){
          msg+=`\n\nWarning: ${titleCaseObjectName(state.selectedObjectType)}(s) are currently excluded from Bulk Edit Template option in Cart`
        }
        setSnackBarMessage(msg)
        setSnackBarButton(<Button color='secondary' variant="outlined" onClick={()=>history.push('/cart')}>GO TO CART</Button>)
        dispatch({type:'set_cart_items',cartItems:[]})
        dispatch({type:'set_is_select_all',isSelectAll:false})
        sendMessage({reloadCart:true})
      })
      .catch(error=>{
        console.log(error)
        setAddingToCart(false)
        setSnackBarOpen(false)
        sendAlert({type:'error',message:'Error occurred adding items to Cart'})
      })
  }

  const onDownload = (isAll) => {
    let q = getSearchQuery({queryInput:state.mainSearchQuery, searchMode:state.searchMode});
    let fq = generateSearchFq({
      tabs:state.searchTabs,
      filters:state.mainSearchFilters,
      objectType:state.selectedObjectType,
      urlFilter:state.mainSearchUrlFilter,
      indexName:indexName
    })

    if(state.mainSearchFilters.length>0)fq += ` AND ${state.mainSearchFilters.join(' AND ')}`;

    let flList = ['NAME:name_txt','DESCRIPTION:description','LOCATION:location_txt']
    state.selectedColumns.forEach(el=>{
      flList.push(`${formatColumnNames(el).replace(/\s/g,'_')}:${el}`)
    })

    let promises = [];

    if(!isAll){
      sendAlert({type:'info',message:'Download started'})
  
      // chunks.forEach((chunk,index)=>{
        promises.push(
          axiosSolr.post(`/solr/${indexName}/select`, {params:{
            q:"*",
            fq:`id:(${state.cartItems.join(' OR ')})`,
            fl:flList.join(','),
            rows:state.cartItems.length,
            wt:'csv'
          }}).then(response=>{
            // fileDownloader(response.data, `kada_search_results_${index*500+1}_${index*500+chunk.length}.csv`);
            fileDownloader(response.data, `kada_search_results.csv`);
          })
        )
      // })
    }else{

      sendAlert({type:'info',message:'Download started'})

      promises = [
        axiosSolr.get(`/solr/${indexName}/select`, {params:{
          q:q,
          fq:fq,
          fl:flList.join(','),
          rows:100000,
          ...getSearchParams({queryInput:state.mainSearchQuery, searchMode:state.searchMode}),
          wt:'csv'
        }}).then(response=>{
          fileDownloader(response.data, `kada_search_results.csv`);
        })
      ]
    }

    Promise
      .all(promises)
      .then(response=>{
        if(state.resultsTotal>100000){
          sendAlert({type:'info',message:'Download completed. A limit of 100k items can be downloaded at a time'})
        }else{
          sendAlert({type:'info',message:'Download completed'})
        }
      })
      .catch(error=>{
        console.log(error)
        sendAlert({type:'error',message:'Error occurred exporting results, please try again'})
      })
  }

  let showDefaultFilterHelperText = false;
  if(!state.searchTabs.find(el=>el.objectType===state.selectedObjectType))return <div></div>
  let filteredCount = state.searchTabs.find(el=>el.objectType===state.selectedObjectType).count - state.resultsTotal;
  
  if(filteredCount>0){
      showDefaultFilterHelperText = true;
  }

  let perPage = 10;
  try{
    let defaultParams = JSON.parse(JSON.parse(localStorage.getItem('platformSettings')).items.find(el=>el.id===8000).value);
    perPage = defaultParams.rows || 10;
  }catch{}

  let columnTopOffset = 58;
  if(hiddenComponents.includes('searchBar'))columnTopOffset=50
  if(hiddenComponents.includes('header'))columnTopOffset=50
  if(hiddenComponents.includes('header') && hiddenComponents.includes('searchBar'))columnTopOffset=0

  let isAllHeaderComponentsHidden = ['listTitle','cartButton','downloadButton','columnSelector'].every(el=>hiddenComponents.includes(el))
  let objectTypeName = state.selectedObjectType==='ALL'?"":(titleCaseObjectName(mapDisplayName(state.selectedObjectType))+' ')
  
  return (
    <div className={classes.root}>
      <div className={isNotSearchPage?'':classes.columnContainer} style={{top:columnTopOffset }}>
        <div 
          style={{
            display:'flex',
            marginBottom:8,
            justifyContent:'space-between',
            alignItems:'flex-start',
            ...(isAllHeaderComponentsHidden?{marginBottom:16,height:0}:{})
          }}
        >
          <div style={{display:'flex',flexWrap:'wrap',alignItems:'center'}}>
            <div>
              {
                !hiddenComponents.includes('listTitle') && 
                (
                  customHeaderFormatter?
                  customHeaderFormatter({objectType:state.selectedObjectType,resultsTotal:state.resultsTotal}):
                  <>
                    <Typography className={classes.title} style={{marginBottom:8}}>
                      {
                        state.resultsTotal===0?
                        'No results found':
                        state.cartItems.length===0 ?
                          `Showing ${(state.pageNum-1)*perPage+1}-${Math.min(state.resultsTotal,state.pageNum*perPage)} of ${formatNumber(state.resultsTotal)} ${objectTypeName}results`:
                          `${formatNumber(state.cartItems.length)} of ${formatNumber(state.resultsTotal)} ${objectTypeName} results selected`
                      }
                    </Typography>
                  </>
                )
              }
              {
                showDefaultFilterHelperText && state.resultsTotal===0 && !customEmptyMsg && 
                <Typography className={classes.helperText}>
                  {
                    Object.keys(state.filterStatus).some(s=>!['active_srt','reference_srt'].includes(s))?
                    <>No assets or items found with your current search filters.</>
                    :
                    <>
                      No active items found with your current search filters. Click <span className={classes.helperText + ' ' + classes.clickableText} style={{color:theme.palette.error.light}} onClick={onClearDefaultFilter}>here</span> to see reference items that match your search.
                    </>
                  }
                </Typography>
              }
            </div>
            <div style={{flexGrow:0,flexShrink:0,marginBottom:8,marginLeft:hiddenComponents.includes('listTitle')?0:16}}>
              {
                checkCanAddToCart({sessionData,objectType:state.selectedObjectType}) && state.resultsTotal!==0 && !hiddenComponents.includes('cartButton') && indexName==='search' && 
                <Button 
                  data-test-id="add-to-cart-button" 
                  color='secondary'
                  variant="outlined" 
                  onClick={onAddToCart} 
                  disabled={state.cartItems.length===0 || selectingAll || addingToCart}
                >
                  Add to Cart {state.cartItems.length===0?'':`(${formatNumber(state.cartItems.length)})`}
                </Button>
              }
              {
                state.mainSearchQuery!=='' && state.searchTabs && state.searchTabs.length>0 && state.resultsTotal!==0 && 
                <>
                  {
                    state.cartItems.length>0 && !hiddenComponents.includes('cartButton') && 
                    <Button
                      onClick={() => onDownload()}
                      data-test-ud="download-button"
                      color='primary'
                      variant='outlined'
                      style={{marginLeft:16}}
                      disabled={selectingAll}
                    >
                      DOWNLOAD {`(${formatNumber(state.cartItems.length)})`}
                    </Button>
                  }
                  {
                    !hiddenComponents.includes('downloadButton') && 
                    <Button
                      onClick={() => onDownload(true)}
                      data-test-ud="download-all-button"
                      color='primary'
                      variant='outlined'
                      style={{marginLeft:16}}
                    >
                      DOWNLOAD ALL
                    </Button>
                  }
                </>
              }
            </div>
          </div>
          <div style={{flexGrow:1}}></div>
          {
            state.resultsTotal>0 && !hiddenComponents.includes('columnSelector') && 
            <ColumnSelector state={state} dispatch={dispatch}/>
          }
        </div>
      </div>
      
      <div className={classes.hideScroll} style={{maxWidth:'100%',overflow:'auto'}}>
        {
          state.resultsTotal>0 &&
          <div className={classes.columnHeader}>
            <div style={{width:24,height:24,marginRight:24,flexShrink:0}}>
              {
                checkCanAddToCart({sessionData,objectType:state.selectedObjectType}) && !hiddenComponents.includes('cartButton') && 
                (
                  selectingAll?
                  <CircularProgress color='secondary' style={{width:24,height:24}}/>
                  :
                  <Checkbox checked={state.isSelectAll} onClick={onSelectAllClick} style={{padding:0,width:24,height:24,color:state.isSelectAll?theme.palette.secondary.main:theme.palette.primaryText.light}}/>
                )
              }
            </div>
            <div className={classes.mainContent} style={{width:getNameWidth(state.selectedColumns)}}>
              <Typography data-test-classname="search-column-text" className={classes.columnText + ' ' + classes.sortable} onClick={()=>onClickColumn('name_srt')}>NAME</Typography>
              <div className={classes.sortIconWrapper}>
                {getSortIcon('name_srt')}
              </div>
            </div>
            {
              <DndContext onDragEnd={(event)=>{
                const {active, over} = event;
                if(-1<=event.delta.x && event.delta.x<=1 && -1<=event.delta.y && event.delta.y<=1){
                  isSortable(active.id) && onClickColumn(active.id)
                  return;
                }
                if (active.id !== over.id) {
                  let oldArr = [...state.selectedColumns]
                  let oldIndex = oldArr.indexOf(active.id)
                  let newIndex = oldArr.indexOf(over.id)
                  let newArr = arrayMove(oldArr, oldIndex, newIndex);
                  dispatch({type:'set_selected_columns',selectedColumns:newArr})
                }             
              }}>
                <SortableContext items={state.selectedColumns} strategy={horizontalListSortingStrategy}>
                  {
                    state.selectedColumns.map(el=>(
                      <SortableColumn
                        id={el}
                        key={el}
                        state={state}
                        dispatch={dispatch}
                        isSortable={isSortable}
                        field={el}
                        classes={classes}
                        getSortIcon={getSortIcon}
                      />
                    ))
                  }
                </SortableContext>
              </DndContext>
            }
          </div>
        }
        {
          state.resultsTotal===0 && customEmptyMsg && 
          <Typography>{customEmptyMsg}</Typography>
        }
        {
          state.searchResults[state.pageNum].data.map(el=>(
            <ResultItem 
              selectingAll={selectingAll} 
              state={state}
              dispatch={dispatch} 
              object={el} 
              highlights={state.searchResults[state.pageNum].highlights} 
              history={history} 
              columnFileds={state.selectedColumns}
              hiddenComponents={hiddenComponents}
              resultItemVariant={resultItemVariant}
              alwaysOpenNewTab={alwaysOpenNewTab}
              indexName={indexName}
              forceOnItemClick={forceOnItemClick}
            />
          ))  
        }
      </div>
      <SnackBar
         snackBarOpen={snackBarOpen}
         keepOpen={keepSnackbarOpen}
         setSnackBarOpen={setSnackBarOpen}
         message={snackBarMessage}
         buttons={snackBarButton}
      />
    </div>
  )
}

export default withTheme()(withStyles(styles)(ResultList));
