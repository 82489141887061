import React from 'react';
import { withStyles} from '@material-ui/core';
import Home from '../Home/Home';
import CreateSource from '../CreateSource/CreateSource';
// import SheduleSource from '../ScheduleSource/ScheduleSource';
import Merge from '../Merge/Merge';
import AccessRequest from '../AccessRequest/AccessRequest';
import DownloadCollector from '../DownloadCollector/DownloadCollector';
import SourceScheduler from '../ScheduleSource/SourceScheduler';
import AddReference from '../AddReference/AddReference';
// import Edit from '../EditSource/EditSource';

const styles = theme => ({
  root: {
    marginBottom:64
  }
})

const Body = props => {

  const {
    classes,
    tabState,
    history,
    state,
    dispatch,
    sessionData,
    dbFetch,
  } = props;


  const generateScheduleText = source => {
    if(source.active_flag===false)return '';
    if(source.job_mode==='LOAD_ONLY' && source.cron.hour){
      return `Polling frequency: Every ${source.cron.hour.split('/')[1]} hour(s)`
    }
    if(source.job_mode==='EXTRACT_AND_LOAD' && source.cron.hour && source.cron.minute){
      let date = new Date();
      date.setUTCHours(source.cron.hour)
      date.setUTCMinutes(source.cron.minute)
      let hour = date.getHours()+'';
      if(hour.length===1)hour = '0'+hour;
      let min = date.getMinutes()+'';
      if(min.length===1)min = '0'+min;
      return `Daily extract time: ${hour}:${min}`
    }
    return ''
  }
  
  let bodyContent;

  switch (tabState) {
    case 0: // home
      bodyContent = <Home history={history} state={state} sessionData={sessionData} dispatch={dispatch} generateScheduleText={generateScheduleText}/>
      break;
    case 1: // create new
      bodyContent = <CreateSource dbFetch={dbFetch} history={history} state={state} sessionData={sessionData} dispatch={dispatch} generateScheduleText={generateScheduleText}/>
      break;
    case 2: // edit 
      bodyContent = <CreateSource isEdit={true} dbFetch={dbFetch} history={history} state={state} sessionData={sessionData} dispatch={dispatch} generateScheduleText={generateScheduleText}/>
      break;
    case 3: // schedule
      bodyContent = <SourceScheduler history={history} state={state} sessionData={sessionData} dispatch={dispatch} />
      break;
    case 4:
      bodyContent = <Merge history={history} state={state} sessionData={sessionData} dispatch={dispatch}/>
      break;
    case 5:
      bodyContent = <AccessRequest history={history} state={state} sessionData={sessionData} dispatch={dispatch}/>
      break;
    case 6:
      bodyContent = <DownloadCollector history={history} state={state} sessionData={sessionData} dispatch={dispatch}/>
      break;
    case 7: // add new reference
      bodyContent = <AddReference  history={history} state={state} sessionData={sessionData} dispatch={dispatch}/>
      break;
    case 8: // edit new reference
      bodyContent = <AddReference  history={history} state={state} sessionData={sessionData} dispatch={dispatch} editedHost={state.selectedHost}/>
      break;
    default:
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

export default withStyles(styles)(Body);