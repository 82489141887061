import React from 'react';
import { withStyles, Typography, withTheme } from '@material-ui/core';
import QuestionBoardChip from '../../QuestionBoard/QuestionBoardChip/QuestionBoardChip'
import { getFileName } from '../../QuestionBoard/Utils/Utils';

const styles = theme => ({
})

const Governance = props => {

  const {
    classes,
    state,
    dispatch,
    theme,
    history,
  } = props;
  
  return (
    <div className={classes.root}>
      <Typography style={{fontSize:20,color:theme.palette.header.main}}>REPORTS</Typography>
      <QuestionBoardChip
        buttonText={'RUN'}
        title={`${state.basicData.name} governance report`}
        subtitle={`Governance and usage metrics for all items linked to this collection instance`}
        state={state}
        dispatch={dispatch}
        code={`0370`}
        forceFileName={getFileName('0370',{instance:state.basicData})}
        defaultFilters={{collection_instance_id:state.basicData.id}}
        disableAutoDownload
        onExtractSubmitted={()=>{
          history.push(`/my_tasks?tabName=EXTRACTS&jobName=ASK K`)
        }}
      />
    </div>
  )
}


export default withTheme()(withStyles(styles)(Governance));