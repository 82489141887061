import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import KTooltip from '../../../KTooltip/KTooltip';
import { tileStyles } from '../utils/styles';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    fontSize: 12,
    color:theme.palette.primaryText.main,
    letterSpacing:1,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    width:132,
    marginRight:12,
    flexShrink:0
  },
  tooltip:{
    whiteSpace:'pre-wrap',
    fontSize:13.75
  },
})

const CustomTile = props => {

  const {
    classes,
    title,
    tooltip,
    body,
  } = props;

  return (
    <KTooltip classes={{tooltip:classes.tooltip}}  title={tooltip} disableHoverListener={tooltip?false:true}  disableFocusListener={tooltip?false:true} disableTouchListener={tooltip?false:true}> 
      <div className={classes.root} data-test-classname="text-data-tile">
        {
          title && 
          <Typography className={classes.title} data-test-classname="text-data-tile-title">{title}</Typography>
        }
        {
          body
        }
      </div>
    </KTooltip>

  )
}

CustomTile.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  body: PropTypes.node.isRequired,
}

export default withStyles(styles)(CustomTile);