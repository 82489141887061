import React, { useEffect, useRef } from 'react';
import { withTheme, withStyles, Typography, Stepper, Step, StepLabel, StepContent} from '@material-ui/core';
import {  toTitleCase} from '../../../utilities';
import SourceSelector from './SourceSelector';
import ObjectTypeSelector from './ObjectTypeSelector';
import CodeSearch from './CodeSearch';
import axiosCerebrum from '../../../axios-cerebrum';
import MaxLimit from './MaxLimit';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  block:{
    maxWidth:640,
    marginLeft:6,
    marginTop:8
  },
  title:{
    fontSize:16,
    color:theme.palette.header.main,
  },
  chip: {
    padding: '0.5rem',
    display: 'inline-block',
    backgroundColor: theme.palette.chip.main,
    border:`1px solid ${theme.palette.border.main}`,
    marginBottom: '1rem',
    borderRadius: 5
  },
  selector: {
    ...theme.components.selector,
    width: 618,
    height:56,
    marginTop: '0.5rem',
  },
  inputBase:{
    ...theme.components.inputBase,
    height:48,
    marginTop: '0.5rem',
    width:'100%'
  },
  stepper:{
    marginBottom:120,
    backgroundColor:theme.palette.background.main
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
  },
})

const Search = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    history,
  } = props;

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  let steps = [
    'Select a source',
    'Select an object type',
    'Set max number of results',
    'Search code'
  ]

  const getStepLabelText = index => {
    switch(index){
      case 0:
        if(state.step!==0 && state.sourceIDs.length>0){
          return `Source: ${state.sourceIDs.map(id=>state.sourceList.find(s=>s.id===id).name).join(', ')}`;
        }
        return steps[index];
      case 1:
        if(state.step!==1 && state.objectTypes.length>0){
          return `Object type: ${state.objectTypes.map(el=>toTitleCase(el.replace(/_/g,' '))).join(', ')}`
        }
        return steps[index]
      case 2:
        if(state.step!==2 && state.maxLimit){
          return `Max number of results: ${state.maxLimit}`
        }
        return steps[index]
      case 3:
        return steps[index];
      default:
        return ''
    }
  }

  const onSelectSource = event => {
    dispatch({
      type:'set_source_ids',
      sourceIDs:event.target.value==='none'?[]:[event.target.value]
    })
    dispatch({
      type:'set_object_types',
      objectTypes:[]
    })
    dispatch({
      type:'set_search_str',
      searchStr:''
    })
    dispatch({type:'set_step',step:1})
  }
  
  const onSelectObjectType = event => {
    dispatch({
      type:'set_object_types',
      objectTypes:event.target.value==='none'?[]:[event.target.value]
    })
    dispatch({type:'set_step',step:2})
  }

  const onChangeSearchStr = event => {
    dispatch({
      type:'set_search_str',
      searchStr:event.target.value
    })
  }

  const onLimitChange = event => {
    let value = event.target.value;
    if(/^\d+$/.test(value) || value===''){
      dispatch({
        type:'set_max_limit',
        maxLimit:value
      })
    }
  }

  const onReset = () => {
    dispatch({
      type:'set_source_ids',
      sourceIDs:[]
    })
    dispatch({
      type:'set_object_types',
      objectTypes:[]
    })
    dispatch({
      type:'set_max_limit',
      maxLimit:1000
    })
    dispatch({
      type:'set_search_str',
      searchStr:''
    })
    dispatch({type:'set_step',step:0})
  }

  const labelClickable = index => {
    let clickable = false;
    if(index===0)return true;
    if(index===1 && state.sourceIDs.length>0)clickable =  true;
    if(index===2 && state.objectTypes.length>0)clickable = true;
    if(index===3 && state.maxLimit)clickable = true;
    return clickable && labelClickable(index-1)
  }

  const onLabelClick = index => {
    if(!labelClickable(index))return;
    dispatch({type:'set_step',step:index})
  }

  const onRunExtract = () => {
    const payload = {
      "domain": document.location.protocol + "//" + document.location.hostname,
      "filters": {
        "search_term": state.searchStr,
        "object_types": state.objectTypes.length>0?state.objectTypes:undefined,
        "source_ids": state.sourceIDs.length>0?state.sourceIDs:undefined,
        'limit':Number(state.maxLimit).valueOf(),
        "active_flag": true
      },
      "ignore_cache":true,
      "lookup_code": `0380`,
      "type":`CODE_SEARCH`,
    }
    axiosCerebrum
      .post('/api/extracts',payload)
      .then(extractResponse=>{
        onReset();
        dispatch({type:'set_tab_state',tabState:1})
        history.push(`/my_tasks?tabName=EXTRACTS&jobName=CODE SEARCH`)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({
          type:'error',
          message:"Error occurred submitting the request, please try again"
        })
      })
  }


  const getStepContent = index => {
    switch(index){
      case 0:
        return <SourceSelector classes={classes} state={state} onSelectSource={onSelectSource}/>
      case 1:
        return <ObjectTypeSelector classes={classes} state={state} onSelectObjectType={onSelectObjectType}/>
      case 2:
        return <MaxLimit classes={classes} state={state} onLimitChange={onLimitChange} dispatch={dispatch}/>
      case 3:
        return <CodeSearch classes={classes} state={state} onChangeSearchStr={onChangeSearchStr} onRunExtract={onRunExtract}/>
      default:
        return <div></div>
    }
  }

  return (
    <div className={classes.root}>
      <Typography style={{fontSize:20,marginBottom:12,color:theme.palette.header.main,}}>CONFIGURE CODE SEARCH</Typography>
      
      <Stepper activeStep={state.step} orientation="vertical" className={classes.stepper} >
        {steps.map((label, index) => (
          <Step key={index} className={classes.step}>
            <StepLabel style={{cursor:labelClickable(index)?'pointer':undefined}} onClick={()=>{onLabelClick(index)}}>
              <Typography color='primary' style={{color:state.settingStep<index?theme.palette.primaryText.light:theme.palette.primaryText.main,fontSize:16,marginLeft:6}}>{getStepLabelText(index)}</Typography> 
            </StepLabel>
            <StepContent>
             {getStepContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
     
      
    </div>
  )
}

export default withTheme()(withStyles(styles)(Search));