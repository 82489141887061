import React from 'react';
import { withStyles, Typography} from '@material-ui/core';
import QuestionBoardChip from '../QuestionBoardChip/QuestionBoardChip'
import { getExtractName } from '../Utils/Utils'

const styles = theme => ({
  title:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  selector: {
    minWidth: 185,
    height:16,
    marginLeft:6,
    backgroundColor: '#EEE',
    padding:'10px 14px',
  },
})

const DataGovernance = props => {

  const {
    classes,
		state,
		dispatch,
    history,
  } = props;

  const classifications = [
    {
      title:getExtractName('0300'),
      subtitle:'All Table classifications for a data source',
      code:'0300'
    },
  ]

  const piis = [
    {
      title:getExtractName('0110'),
      subtitle:'Tables linked by PII metadata linking rules',
      code:'0110'
    },
  ]

  const dataQuality = [
    {
      title:getExtractName('0290'),
      subtitle:'All issues raised in K for a data source',
      code:'0290'
    }
  ]

  const onExtractSubmitted = () => history.push(`/my_tasks?tabName=EXTRACTS&jobName=ASK K`)

  return (
    <div>
      <div>
			  <Typography  className={classes.title}>CLASSIFICATION</Typography>
      </div>  
      <div style={{marginLeft:16,marginTop:16}}>
        {
          classifications.map(el=>(
            <QuestionBoardChip
              state={state}
              dispatch={dispatch}
              code={el.code}
              title={el.title}
              subtitle={el.subtitle}
              disableAutoDownload={true}
              onExtractSubmitted={onExtractSubmitted}
            />
          ))
        }
      </div> 

      <div style={{marginTop:40}}>
			  <Typography className={classes.title}>PII</Typography>
      </div>  
      <div style={{marginLeft:16,marginTop:16}}>
        {
          piis.map(el=>(
            <QuestionBoardChip
              state={state}
              dispatch={dispatch}
              code={el.code}
              title={el.title}
              subtitle={el.subtitle}
              disableAutoDownload={true}
              onExtractSubmitted={onExtractSubmitted}
            />
          ))
        }
      </div> 

      <div style={{marginTop:40}}>
			  <Typography className={classes.title}>ISSUES</Typography>
      </div>  
      <div style={{marginLeft:16,marginTop:16}}>
        {
          dataQuality.map(el=>(
            <QuestionBoardChip
              state={state}
              dispatch={dispatch}
              code={el.code}
              title={el.title}
              subtitle={el.subtitle}
              disableAutoDownload={true}
              onExtractSubmitted={onExtractSubmitted}
            />
          ))
        }
      </div> 
    </div>
    
  )
}

export default withStyles(styles)(DataGovernance);