import axiosSolr from "../../axios-solr";
import { formatNumber, sendMessage, toTitleCase } from "../../utilities";
import { generateUsageQuery, generateUsageUrlQuery, getHighestUsage, processObjectName } from "../Grid/Governance/loadUtils";


export const loadDomainList = ({variant, userName, isDataGovUser}) => {
  let filters = [`object_type_srt:COLLECTION_INSTANCE`,'collection_srt:DOMAIN']
  let fieldName;
  if(variant==='steward')fieldName = 'stewards_msrt'
  if(variant==='owner')fieldName = 'owners_msrt'
  // filters.push(`${fieldName}:"${userName}"`)
  return axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:filters.join(' AND ') + (isDataGovUser?'':` AND ${fieldName}:"${userName}"`),
          rows:300,
        }
      }
    )
}

let objectTypes = ['TABLE','COLUMN','REPORT','SHEET','DATASET','DATA_PIPELINE','ML_MODEL']

export const loadTiles = ({ domain, usage}) => {
  let filters = [`-source_id_srt:998`,'active_srt:YES']
  if(domain){
    filters.push(`domain_kc_msrt:"${domain}"`)
  }
  filters.push(`-object_type_srt:(COLLECTION_INSTANCE OR CODE)`)
  return axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:filters.join(' AND ') + generateUsageQuery(usage),
          rows:0,
          'json.facet':{
            "object_types": {
              "type": "terms",
              "field": "object_type_srt",
              "mincount":1,
              "limit":50,
            }
          }
        }
      }
    )
}

export const processTileData = ({facet}) => {
  if(!facet.data.facets.object_types)return []

  const getCount = bucket => {
    return bucket.count;
  }

  let buckets = facet.data.facets.object_types.buckets;
  let tiles = buckets.map(o=>({
    id:o.val,
    clickable:true,
    title:`${toTitleCase(o.val.replace(/_/g,' '))}s`,
    value:formatNumber(getCount(o)),
  }))
  return tiles.sort((a,b)=>objectTypes.indexOf(a)-objectTypes.indexOf(b))
}


export const loadGovProgress = async ({objectType, source, usage, state, domain}) => {
  let chartData;

  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 ${generateUsageQuery(usage)} AND domain_kc_msrt:"${domain}"`
            + (source?` AND source_id_srt:${source.id}`:'')
          ,
          rows:0,
          'json.facet':{
            "gov_progress": {
              "type": "terms",
              "field": "governance_progress_srt",
              "mincount":1,
              "limit":100,
              "missing":true
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.gov_progress)return;
      let buckets = facet.data.facets.gov_progress.buckets;
      
      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }else{
        let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
        if(state.objectCountBySource){
          validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
        }
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }
      let labels = ['count']

      const getColour = value => {
        if(value==='NOT_STARTED')return '#F50057'
        if(value==='IN_PROGRESS')return '#FFAB00'
        if(value==='COMPLETE')return '#00D46A'
      }

      const getName = value => {
        if(value==='NOT_STARTED')return 'No governance properties set'
        if(value==='IN_PROGRESS')return 'Some governance properties set'
        if(value==='COMPLETE')return 'All governance properties set'
      }

      let series = [];

      buckets.forEach(b=>{
        series.push({
          name:getName(b.val),
          data:[b.count],
          color:getColour(b.val),
          clickFns:[
            ()=>{
              sendMessage({
                modalQuery:"*",
                modalObject:objectType,
                modalFilter:`governance_progress_srt:${b.val} AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq} AND domain_kc_msrt:${domain}`
              })
            }
          ]
        })
      })

      if(facet.data.facets.gov_progress.missing?.count){
        series.push({
          name:'Unknown',
          data:[facet.data.facets.gov_progress.missing.count],
          color:'#AEAEAE',
          clickFns:[
            ()=>{
              sendMessage({
                modalQuery:"*",
                modalObject:objectType,
                modalFilter:`governance_progress_srt:NO GOVERNANCE PROGRESS AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq} AND domain_kc_msrt:${domain}`
              })
            }
          ]
        })
      }

      chartData = {
        labels, 
        series
      }
    })
  return chartData
}

export const loadClassificationChart = async ({objectType, source, usage, state, domain}) => {
  let chartData;

  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 ${generateUsageQuery(usage)} AND domain_kc_msrt:"${domain}"` + 
              (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "classification": {
              "type": "terms",
              "field": "classification_kc_msrt",
              "mincount":1,
              "limit":100,
              "missing":true
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.classification)return;
      let buckets = facet.data.facets.classification.buckets;
      // if(buckets.length===0)return;
      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }else{
        let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
        if(state.objectCountBySource){
          validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
        }
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }
      let labels = []
      let data = []
      let clickFns = []
      let toggleLabelArr = [];

      data = buckets.map(b=>b.count);
      labels = buckets.map(b=>toTitleCase(b.val));
      clickFns = buckets.map(b=>()=>{
        sendMessage({
          modalQuery:"*",
          modalObject:objectType,
          modalFilter:`classification_kc_msrt:${b.val} AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq} AND domain_kc_msrt:${domain}`
        })
      })    

      if(facet.data.facets.classification.missing?.count){
        labels.push('No classification')
        data.push(facet.data.facets.classification.missing.count)
        toggleLabelArr.push('No classification')
        clickFns.push(()=>{
          sendMessage({
            modalQuery:"*",
            modalObject:objectType,
            modalFilter:`classification_kc_msrt:NO CLASSIFICATION AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq} AND domain_kc_msrt:${domain}`
          })
        })
      }

      chartData = {
        labels, 
        toggleLabel:{name:'No classification',value:toggleLabelArr},
        series:[{
          name:'count',
          data,
          clickFns
        }]
      }
    })
  return chartData
}

export const loadUseCaseStatus = async ({objectType, source, usage, state, domain}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 ${generateUsageQuery(usage)} AND domain_kc_msrt:"${domain}"`  
              + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "verified": {
              "type": "terms",
              "field": "verified_kc_msrt",
              "mincount":1,
              "limit":200,
              'missing':true
            },
            "verified_count": {
              "type": "query",
              "query": "verified_kc_msrt:[* TO *]",
            },
            "not_verified": {
              "type": "terms",
              "field": "not_verified_kc_msrt",
              "mincount":1,
              "limit":200,
              'missing':true
            },
            "not_verified_count": {
              "type": "query",
              "query": "not_verified_kc_msrt:[* TO *]",
            },
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.verified && !facet.data.facets.not_verified)return;

      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }
      else{
        let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
        if(state.objectCountBySource){
          validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
        }
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }

      let labels = []
      let data = []
      let clickFns = []
      let toggleLabelArr = [];

      let verifiedBuckets = facet.data.facets.verified.buckets;
      if(verifiedBuckets.length!==0 || facet.data.facets.verified.missing?.count){
        let useCaseNames = verifiedBuckets.map(b=>b.val);
        let hasUseCaseCounts = facet.data.facets.verified_count?.count || 0;
        if(hasUseCaseCounts){
          labels.push(`Verified 'use case'`)
          data.push(hasUseCaseCounts)
          clickFns.push(()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalFilter:`verified_kc_msrt:${useCaseNames.join(',')} AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq} AND domain_kc_msrt:${domain}`
            })
          })
        }
        if(facet.data.facets.verified.missing?.count){
          labels.push(`No 'use case' verified`)
          toggleLabelArr.push(`No 'use case' verified`)
          data.push(facet.data.facets.verified.missing.count)
          clickFns.push(()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalFilter:`verified_kc_msrt:NO VERIFIED AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq} AND domain_kc_msrt:${domain}`
            })
          })
        }
      }

      let notVerifiedBuckets = facet.data.facets.not_verified.buckets;
      if(notVerifiedBuckets.length!==0 || facet.data.facets.not_verified.missing?.count){
        let useCaseNames = notVerifiedBuckets.map(b=>b.val);
        let hasUseCaseCounts = facet.data.facets.not_verified_count?.count || 0;
        if(hasUseCaseCounts){
          labels.push(`Verified 'do not use case'`)
          data.push(hasUseCaseCounts)
          clickFns.push(()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalFilter:`not_verified_kc_msrt:${useCaseNames.join(',')} AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq} AND domain_kc_msrt:${domain}`
            })
          })
        }
        if(facet.data.facets.verified.missing?.count){
          labels.push(`No 'do not use case' verified`)
          toggleLabelArr.push(`No 'do not use case' verified`)
          data.push(facet.data.facets.verified.missing.count)
          clickFns.push(()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalFilter:`not_verified_kc_msrt:NO VERIFIED AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq} AND domain_kc_msrt:${domain}`
            })
          })
        }
      }
      chartData = {
        labels,
        toggleLabel:{name:'No verified use cases',value:toggleLabelArr},
        series:[{
          name:'count',
          data,
          clickFns
        }]
      }
    })
  return chartData
}

export const loadDocumentedStatus = async ({objectType, source, usage, state, domain}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 ${generateUsageQuery(usage)} AND domain_kc_msrt:"${domain}"`  
                + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "documented": {
              "type": "query",
              "q":'description:*'
            },
            "not_documented": {
              "type": "query",
              "q":'-description:[* TO *]'
            },
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.documented?.count && !facet.data.facets.not_documented?.count)return;

      let sourceFq = '';
      if(source){
        sourceFq = `source_srt:${source.name}`
      }else{
        let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
        if(state.objectCountBySource){
          validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
        }
        sourceFq = `source_srt:${validSources.join(',')}`
      }

      let labels = []
      let data = []
      let clickFns = []
      let toggleLabelArr = [];
      if(facet.data.facets.documented?.count){
        labels.push('Has documentation')
        data.push(facet.data.facets.documented.count)
        clickFns.push(()=>{
          sendMessage({
            modalQuery:"*",
            modalObject:objectType,
            modalFilter:`${sourceFq} AND active_srt:YES${generateUsageUrlQuery(usage)} AND documented_srt:IS_DOCUMENTED AND domain_kc_msrt:${domain}`
          })
        })
      }
      if(facet.data.facets.not_documented?.count){
        labels.push('No documentation')
        toggleLabelArr.push('No documentation')
        data.push(facet.data.facets.not_documented.count)
        clickFns.push(()=>{
          sendMessage({
            modalQuery:"*",
            modalObject:objectType,
            modalFilter:`${sourceFq} AND active_srt:YES${generateUsageUrlQuery(usage)} AND documented_srt:NOT_DOCUMENTED AND domain_kc_msrt:${domain}`
          })
        })
      }
      chartData = {
        labels,
        toggleLabel:{name:'No documentation',value:toggleLabelArr},
        series:[{
          name:'count',
          data,
          clickFns
        }]
      }
    })
  return chartData
}

export const loadOwnerStatus = async ({objectType, source, usage, state, domain}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 ${generateUsageQuery(usage)} AND domain_kc_msrt:"${domain}"`  + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "owned": {
              "type": "query",
              "q":'owned_srt:IS_OWNED'
            },
            "not_owned": {
              "type": "query",
              "q":'owned_srt:NOT_OWNED'
            },
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.owned?.count && !facet.data.facets.not_owned?.count)return;

      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }else{
        let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
        if(state.objectCountBySource){
          validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
        }
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }
      let labels = []
      let data = []
      let clickFns = []
      let toggleLabelArr = [];
      
      if(facet.data.facets.owned?.count){
        labels.push('Data owner assigned')
        data.push(facet.data.facets.owned.count)
        clickFns.push(()=>{
          sendMessage({
            modalQuery:"*",
            modalObject:objectType,
            modalFilter:`owned_srt:IS_OWNED AND active_srt:YES${generateUsageUrlQuery(usage)} AND domain_kc_msrt:${domain}${sourceFq}`
          })
        })
      }
      if(facet.data.facets.not_owned?.count){
        labels.push('No data owner assigned')
        toggleLabelArr.push('No data owner assigned')
        data.push(facet.data.facets.not_owned.count)
        clickFns.push(()=>{
          sendMessage({
            modalQuery:"*",
            modalObject:objectType,
            modalFilter:`owned_srt:NOT_OWNED AND active_srt:YES${generateUsageUrlQuery(usage)} AND domain_kc_msrt:${domain}${sourceFq}`
          })
        })
      }

      chartData = {
        labels,
        toggleLabel:{name:'No data owner assigned',value:toggleLabelArr},
        series:[{
          name:'count',
          data,
          clickFns
        }]
      }
    })
  return chartData
}


export const loadStewardStatus = async ({objectType, source, usage, state, domain}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 ${generateUsageQuery(usage)} AND domain_kc_msrt:"${domain}"`  + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "stewarded": {
              "type": "query",
              "q":'steward_count:[1 TO *]'
            },
            "not_stewarded": {
              "type": "query",
              "q":'steward_count:0'
            },
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.stewarded?.count && !facet.data.facets.not_stewarded?.count)return;

      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }else{
        let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
        if(state.objectCountBySource){
          validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
        }
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }
      
      let labels = []
      let data = []
      let clickFns = []
      let toggleLabelArr = [];
      
      if(facet.data.facets.stewarded?.count){
        labels.push('Data steward assigned')
        data.push(facet.data.facets.stewarded.count)
        clickFns.push(()=>{
          sendMessage({
            modalQuery:"*",
            modalObject:objectType,
            modalFilter:`steward_count:1,[2 TO *] AND active_srt:YES${generateUsageUrlQuery(usage)} AND domain_kc_msrt:${domain}${sourceFq}`
          })
        })
      }
      if(facet.data.facets.not_stewarded?.count){
        labels.push('No data steward assigned')
        toggleLabelArr.push('No data steward assigned')
        data.push(facet.data.facets.not_stewarded.count)
        clickFns.push(()=>{
          sendMessage({
            modalQuery:"*",
            modalObject:objectType,
            modalFilter:`steward_count:0 AND active_srt:YES${generateUsageUrlQuery(usage)} AND domain_kc_msrt:${domain}${sourceFq}`
          })
        })
      }

      chartData = {
        labels,
        toggleLabel:{name:'No data steward assigned',value:toggleLabelArr},
        series:[{
          name:'count',
          data,
          clickFns
        }]
      }
    }
  )
  return chartData
}

export const loadRecommendations = async ({objectType, usage ,source, state, domain}) => {
  let recommendations = [undefined,undefined,undefined,undefined];
  let promises = [];

  let heighestUsage = getHighestUsage(usage)
  if(heighestUsage==='UNUSED' || heighestUsage==='NO USAGE')return [];
  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND governance_progress_srt:NOT_STARTED AND -source_id_srt:998 ${generateUsageQuery(usage)}` 
                + `  AND domain_kc_msrt:"${domain}"`
                + (source?` AND source_id_srt:${source.id}`:''),
            rows:0,
            'json.facet':{
              'usage':{
                type:'terms',
                field:'usage_srt',
                mincount:1,
                missing:true,
                limit:5
              }
            }
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        let sourceFq = '';
        if(source){
          sourceFq = ` AND source_srt:${source.name}`
        }else{
          let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
          if(state.objectCountBySource){
            validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
          }
          sourceFq = ` AND source_srt:${validSources.join(',')}`
        }

        let usages = response.data.facets.usage.buckets.map(el=>el.val)
        if(response.data.facets.usage.missing?.count)usages.push('NO USAGE')
        let heighestUsage = getHighestUsage(usages)
        let count;
        if(heighestUsage==='NO USAGE')count = response.data.facets.usage.missing?.count;
        else{count = response.data.facets.usage.buckets.find(e=>e.val===heighestUsage).count}
        
        recommendations[0] = {
          title:`Review ${processObjectName(objectType)}s ${heighestUsage==='NO USAGE'?'that have unknown usage':heighestUsage==='UNUSED'?'that are unused':`used ${heighestUsage.toLowerCase()}`} with no governance properties completed`,
          countTxt:formatNumber(count) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalFilter:`${generateUsageUrlQuery([heighestUsage],true)} AND active_srt:YES AND governance_progress_srt:NOT_STARTED${sourceFq} AND domain_kc_msrt:${domain}`
            })
          }
        }
      })
  )

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND governance_progress_srt:IN_PROGRESS AND -source_id_srt:998 ${generateUsageQuery(usage)}`  
                + ` AND domain_kc_msrt:"${domain}"`
                + (source?` AND source_id_srt:${source.id}`:''),
            rows:0,
            'json.facet':{
              'usage':{
                type:'terms',
                field:'usage_srt',
                mincount:1,
                missing:true,
                limit:5
              }
            }
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        let sourceFq = '';
        if(source){
          sourceFq = ` AND source_srt:${source.name}`
        }else{
          let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
          if(state.objectCountBySource){
            validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
          }
          sourceFq = ` AND source_srt:${validSources.join(',')}`
        }

        let usages = response.data.facets.usage.buckets.map(el=>el.val)
        if(response.data.facets.usage.missing?.count)usages.push('NO USAGE')
        let heighestUsage = getHighestUsage(usages)
        let count;
        if(heighestUsage==='NO USAGE')count = response.data.facets.usage.missing?.count;
        else{count = response.data.facets.usage.buckets.find(e=>e.val===heighestUsage).count}
        
        recommendations[0] = {
          title:`Review ${processObjectName(objectType)}s ${heighestUsage==='NO USAGE'?'that have unknown usage':heighestUsage==='UNUSED'?'that are unused':`used ${heighestUsage.toLowerCase()}`} with some governance properties set`,
          countTxt:formatNumber(count) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalFilter:`${generateUsageUrlQuery([heighestUsage],true)} AND active_srt:YES AND governance_progress_srt:IN_PROGRESS${sourceFq} AND domain_kc_msrt:${domain}`
            })
          }
        }
      })
  )

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND owned_srt:NOT_OWNED AND -source_id_srt:998 ${generateUsageQuery(usage)} AND domain_kc_msrt:"${domain}"` + (source?` AND source_id_srt:${source.id}`:''),
            rows:0,
            'json.facet':{
              'usage':{
                type:'terms',
                field:'usage_srt',
                mincount:1,
                missing:true,
                limit:5
              }
            }
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        let sourceFq = '';
        if(source){
          sourceFq = ` AND source_srt:${source.name}`
        }else{
          let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
          if(state.objectCountBySource){
            validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
          }
          sourceFq = ` AND source_srt:${validSources.join(',')}`
        }

        let usages = response.data.facets.usage.buckets.map(el=>el.val)
        if(response.data.facets.usage.missing?.count)usages.push('NO USAGE')
        let heighestUsage = getHighestUsage(usages)
        let count;
        if(heighestUsage==='NO USAGE')count = response.data.facets.usage.missing?.count;
        else{count = response.data.facets.usage.buckets.find(e=>e.val===heighestUsage).count}
        
        recommendations[2] = {
          title:`Assign Owners to ${processObjectName(objectType)}s ${heighestUsage==='NO USAGE'?'that have unknown usage':heighestUsage==='UNUSED'?'that are unused':`used ${heighestUsage.toLowerCase()}`}`,
          countTxt:formatNumber(count) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalFilter:`${generateUsageUrlQuery([heighestUsage],true)} AND active_srt:YES AND owned_srt:NOT_OWNED AND domain_kc_msrt:${domain}${sourceFq}`
            })
          }
        }
      })
  )

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND steward_count:0 AND -source_id_srt:998 ${generateUsageQuery(usage)} AND domain_kc_msrt:"${domain}"` + (source?` AND source_id_srt:${source.id}`:''),
            rows:0,
            'json.facet':{
              'usage':{
                type:'terms',
                field:'usage_srt',
                mincount:1,
                missing:true,
                limit:5
              }
            }
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        let sourceFq = '';
        if(source){
          sourceFq = ` AND source_srt:${source.name}`
        }else{
          let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
          if(state.objectCountBySource){
            validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
          }
          sourceFq = ` AND source_srt:${validSources.join(',')}`
        }


        let usages = response.data.facets.usage.buckets.map(el=>el.val)
        if(response.data.facets.usage.missing?.count)usages.push('NO USAGE')
        let heighestUsage = getHighestUsage(usages)
        let count;
        if(heighestUsage==='NO USAGE')count = response.data.facets.usage.missing?.count;
        else{count = response.data.facets.usage.buckets.find(e=>e.val===heighestUsage).count}
        
        recommendations[3] = {
          title:`Assign Stewards to ${processObjectName(objectType)}s ${heighestUsage==='NO USAGE'?'that have unknown usage':heighestUsage==='UNUSED'?'that are unused':`used ${heighestUsage.toLowerCase()}`}`,
          countTxt:formatNumber(count) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalFilter:`${generateUsageUrlQuery([heighestUsage],true)} AND active_srt:YES AND steward_count:0 AND domain_kc_msrt:${domain}${sourceFq}`
            })
          }
        }
      })
  )

  await Promise.all(promises)
  return recommendations.filter(r=>r)
}
