import React, { useEffect, useState } from 'react';
import { withStyles, Typography, Select, MenuItem} from '@material-ui/core';
import Chart from './Chart';
import { getJobResult } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import ChartLoadingIcon from '../../UI/DQInsights/Runs/ChartLoadingIcon';

const styles = theme => ({
  root: {
    marginBottom:40
  },
  header:{
    fontSize:20,
    color:theme.palette.header.main,
    marginBottom:6
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  },
  normalText:{
    color:theme.palette.primaryText.main,
    fontSize:13.75
  },

  titleSelector:{
    ...theme.components.titleSelector,
    marginRight:8,
    maxWidth:'80vw',
  },
})

const Results = props => {

  const {
    classes,
    // history,
    dispatch,
    selectedRecord,
    state,
  } = props;

  const [result, setResult] = useState({})

  const getTestResult = (recordId, period=state.chartPeriod) => {
    let cacheID = `${recordId}_${period}`;
    if(state.resultDataCache[cacheID]){
      setResult({data:state.resultDataCache[cacheID]})
      return; 
    }

    setResult({loading:true})
    axiosCerebrum
      .post(
        '/api/platformbatches/0',
        {
          "adhoc_args":{
            "job": "_0060_metrics",
            "object_id": recordId,
            "related_id":state.basicData.id,
            'agg_method':['COUNT','AVG'],
            'days':period,
            "metric_type":'DATA_QUALITY_SCORE'
          }
        }
      )
      .then(response=>{
        if(recordId!==state.selectedRecord.object_id_srt)return;
        let id = response.data.id;
        getJobResult(
          id,
          data=>{
            setResult({data:data.result.data})
            dispatch({type:'set_result_data_cache',resultDataCache:{...state.resultDataCache,[cacheID]:data.result.data}})
          },
          ()=>{
            setResult({error:true})
          }
        )
      })
      .catch(error=>{
        console.log(error);
        setResult({error:true})
      })
  }

  useEffect(()=>{
    if(!selectedRecord)return;
    getTestResult(selectedRecord.object_id_srt);
  // eslint-disable-next-line
  },[selectedRecord])

  // return <></>;
  if(!selectedRecord)return <></>;

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        LINKED ASSEST DQ SCORE
        <Select
          className={classes.titleSelector}
          style={{marginLeft:12}}
          value={state.chartPeriod}
          disabled={state.resultLoading}
          disableUnderline
          onChange={event=>{
            dispatch({type:'set_chart_period',chartPeriod:event.target.value})
            getTestResult(selectedRecord.object_id_srt, event.target.value);
          }}
        >
          {
            [30,90,365].map(el=>(
              <MenuItem disabled={state.resultLoading} className={classes.menuItem} value={el}>
                {el} DAYS
              </MenuItem>
            ))
          }
        </Select>
      </Typography>
      <Typography className={classes.subTitle}>Average DQ test scores and number of runs over time. Score is averaged if a test is run multiple times on a single day</Typography>
      {
        result.loading && <ChartLoadingIcon/>
      }
      {
        result.error && <Typography className={classes.normalText}>Error occurred loading results</Typography>
      }
      {
        result.data && 
        <Chart state={state} data={result.data}/>
      }
    </div>
  )
}


export default withStyles(styles)(Results);