import React,{useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {withTheme, Typography, withStyles, Button, Modal, Paper, CircularProgress, IconButton } from '@material-ui/core';
import {onClickResultItem, getLabelPlural, toTitleCase, getIconComponent, getDispFields, mapObjectName} from '../../../utilities'
import axiosCerebrum from '../../../axios-cerebrum'
import ModalAlert from '../../UI/ModalAlert/ModalAlert';
// import { useStore } from 'react-redux'
import SearchSelector from '../SearchSelector/SearchSelector'
import SimpleResultItem from '../SearchResults/SimpleResultItem';
import {withRouter} from 'react-router'
import axiosSolr from '../../../axios-solr'
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  modalContainer:{
    width:680,
    maxWidth:'85vw',
    margin:'auto',
    marginTop:'15vh',
    outline:'none'
  },
  inputBody:{
    maxHeight:'45vh',
    paddingRight:24,
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.light
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb':{
      background: theme.palette.primaryText.light,
      borderRadius:4,
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover':{
      
    },
    '&::-webkit-scrollbar': {
      width: 5
    },
    overflowY:'auto',
    overflowX:'hidden',
  },
  formBody:{
    padding:24,
    paddingRight:0,
    paddingBottom:8,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
  },
})

function AccessAdder(props) {

  const {
    theme,
    classes,
    object,
    state,
    dispatch,
    history,
    modalOnly,
    modalOpen,
    setModalOpen,
  } = props;

  // const store = useStore();
  // let sessionData = store.getState().auth.session_user;
  const [searchValue, setSearchValue] = useState('')
  // let permissions = store.getState().auth.permissions;
  const [internalModalOpen, setInternalModalOpen] = useState(false);

  const [alertMessage, setAlertMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [edit] = useState(false)
  const [seeMore, setSeeMore] = useState(false)
  const [updated, setUpdated] = useState(false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  const loadRoles = ({page=1,previousData=[]}) => {
    if(page===1){
      dispatch({
        type:'set_access_roles',
        accessRolesLoading:true
      })
    }
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related/collections`,
        {params:{
          category:'PLATFORM',
          parent_name:'Access Role',
          sort:'END_DESC',
          page:page,
          per_page:10,
        }}
      )
      .then(async response=>{
        let items = []
        if(response.data.total===0){
          dispatch({
            type:'set_access_roles',
            accessRoles:response.data
          })
          return;
        }
        if(response.data.items.length!==0){
          for(let i=0; i<response.data.items.length; i++){
            let detail = await axiosCerebrum
              .get(
                `/api/collectioninstances/${response.data.items[i].id}`
              )
            items.push({
              ...detail.data,
              collection_name:detail.data.parent.name.toLowerCase(),
              collection_category:'platform',
            })
          }
          await axiosSolr
            .get(
              `/solr/search/select`,{
                params:{
                  q:"*",
                  fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`
                }
              }
            )
            .then(solrRes=>{
              items = items.map(el=>({
                ...el,
                ...((solrRes.data.response.docs.find(s=>s.id===el.id))||{})
              }))
            })
            .catch(error=>{
              console.log(error)
            })
        }

        if(response.data.page<response.data.pages){
          loadRoles({page:response.data.page+1,previousData:[...previousData,...items]})
        }else{
          dispatch({
            type:'set_access_roles',
            accessRoles:{...response.data,items:[...previousData,...items]}
          })
        }
        
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_access_roles',
          accessRolesError:true
        })
      })
  }

  useEffect(()=>{
    if(!state.accessRoles && !modalOnly){
      loadRoles({})
    }
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if((modalOpen || internalModalOpen) && !state.accessRoles && !state.accessRolesLoading){
      loadRoles({})
    }
    // eslint-disable-next-line
  },[modalOpen, internalModalOpen])


  const onCancel = () => {
    if(updated){
      sendAlert({message:'Access Role successfully updated',type:'info'})
    }
    setModalOpen(false);
    setInternalModalOpen(false);
    setUpdated(false)
  }

  const onLink = async (el) => {
    axiosCerebrum
      .put(
        `/api/collectioninstances/${el.id}/related?object_id=${object.id}&relationship=MEMBERS`
      )
      .then(response=>{
        loadRoles({})
        setUpdated(true)
        window.postMessage({reload_sidebar:'hasAccess'},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
      })
      .catch(error=>{
        console.log(error)
        let msg = "Error occurred adding the role, please try again";
        if(error && error.response &&  error.response.status && error.response.status===409){
          msg = "This role has already been linked to this object"
        }
        setAlertMessage(msg)
        setAlertOpen(true)
      })
  }

  const onRemoveRole = (role) => {
    axiosCerebrum
      .delete(
        `/api/collectioninstances/${role.id}/related?object_id=${object.id}&relationship=MEMBERS`
      )
      .then(response=>{
        loadRoles({})
        setUpdated(true)
        window.postMessage({reload_sidebar:'hasAccess'},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
        sendAlert({message:'Access Role successfully removed',type:'info'})
      })
      .catch(error=>{
        console.log(error)
        sendAlert({message:'Error occurred removing the role',type:'error'})
      })
  }

  // let isEditable = permissions.includes('edit_access_role')
  
  return (
    <div className={classes.root} >
      {
        !modalOnly &&
        <div id={'profile-access-role-section'}>
          <div style={{display:'flex'}}>
            <div style={{display:'flex',flexGrow:1}}>
              <Typography style={{fontSize:20,color:theme.palette.header.main}}>ACCESS</Typography>
              {/* {isEditable && <Button color='primary' style={{marginLeft:16,marginTop:-2}} disabled={!state.accessRoles} onClick={()=>setInternalModalOpen(true)}>ADD</Button>}
              {isEditable && state.accessRoles && state.accessRoles.total>0 && <Button color='primary' style={{marginLeft:8,marginTop:-2}} disabled={!state.accessRoles} onClick={()=>setEdit(!edit)}>{edit?'CLOSE':'EDIT'}</Button>} */}
            </div>

          </div>
          <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginBottom:16}}>
            Roles that give access to this {toTitleCase(mapObjectName(object.object.name).replace(/_/g,' '))}
          </Typography>
          {
            state.accessRoles && state.accessRoles.total===0 && 
            <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main}}>No roles found or loaded from source</Typography>
          }
          {
            state.accessRoles && (seeMore?state.accessRoles.items:state.accessRoles.items.slice(0,3)).map((item)=>(
              <SimpleResultItem
                item={item}
                key={item.id}
                label={item.object_type_txt}
                titleColour={theme.palette.primaryText.main}
                title={getDispFields(item,'dispTitle')}
                subTitle={getDispFields(item,'dispSubtitle')}
                hideRight
                showUnderline={true}
                tailObject={
                  edit?
                  <IconButton onClick={()=>onRemoveRole(item)} style={{padding:8}}>
                    {getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})}
                  </IconButton>
                  :undefined
                }
                onClick={(event)=>{if(edit)return;onClickResultItem({item,id:item.id,label:'COLLECTION_INSTANCE',history,collection:'collection'})}}
              />
            ))
          }
          {
            state.accessRolesLoading && <CircularProgress color='secondary'/>
          }
          {
            state.accessRolesError && <Typography style={{fontSize:12,color:theme.palette.primaryText.main,marginBottom:16}}>Error occurred loading roles</Typography>
          }
          <div style={{display:'flex'}}>
            {
              state.accessRoles && state.accessRoles.items.length>3 &&
              <Button color='primary' style={{marginTop:8}} onClick={()=>{setSeeMore(!seeMore)}}>SEE {seeMore?'LESS':'MORE'}</Button>
            }
          </div>
        </div>
      }
      
      <Modal 
        open={(modalOnly && modalOpen) || internalModalOpen} 
        onClose={()=>onCancel()}
        disableBackdropClick={true}
      >
        <div className={classes.modalContainer}>
          <Paper className={classes.formBody}>
            <div style={{display:'flex',alignItems:'flex-start',paddingRight:24}}>
              <Typography style={{color:theme.palette.header.main,fontSize:20,marginBottom:24,flexGrow:1}}>Add Access Role</Typography>
              <Button color='primary' variant='outlined' style={{width:96}} onClick={onCancel}>CLOSE</Button>
            </div>
            <div className={classes.inputBody}>
              <SearchSelector 
                // url='/api/collectioninstances'
                // params={{
                //   collection_id:collectionIds.accessRole,
                //   'search.name':searchValue,
                //   per_page:20,
                //   page:1,
                // }}
                url='/solr/search/select'
                params={{
                 q:'*',
                 fq:`name_srt:${searchValue}* AND collection_srt:"ACCESS ROLE" and collection_type_srt:PLATFORM`,
                 fl:"*"
                }}
                // isCerebrum={true}
                autoSuggestion={true}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder={`Search for an access role to link`}
                onResultClick={el=>onLink(el)}
                postProcessDataList={list=>{
                  if(!state.accessRoles)return list;
                  return list.filter(el=>!state.accessRoles.items.find(a=>a.id===el.id))
                }}
                removeFLModify={false}
              />
            </div>
            <div style={{maxHeight:'40vh',overflow:'hidden',marginTop:24,marginBottom:24,paddingRight:24}} className={classes.customScroll}>
              <Typography style={{fontSize:16,marginBottom:8,color:theme.palette.primary.main}}>Roles linked</Typography>
              {
                state.accessRoles && state.accessRoles.items.map(item=>(
                  <SimpleResultItem
                    title={getDispFields(item,'dispTitle')}
                    key={item.id}
                    titleColour={theme.palette.primaryText.main}
                    label={'access_role'}
                    item={item}
                    subTitle={getDispFields(item,'dispSubtitle')}
                    hideRight={true}
                    showUnderline={true}
                    tailObject={
                      <IconButton onClick={()=>onRemoveRole(item)} style={{padding:8}}>
                        {getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})}
                      </IconButton>
                    }
                  />
                ))
              }
              {
                state.accessRoles && state.accessRoles.total===0 && 
                <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main}}>No roles found or loaded from source</Typography>
              }
              {
                state.accessRolesLoading && <CircularProgress color='secondary'/>
              }
              {
                state.accessRolesError && <Typography style={{fontSize:12,color:theme.palette.primaryText.main,marginBottom:16}}>Error occurred loading roles</Typography>
              }
            </div>
          </Paper>
          <ModalAlert
            setOpen={setAlertOpen}
            open={alertOpen}
            message={alertMessage}
            type={'error'}
          />
        </div>
      </Modal>
    </div>
  )
}

AccessAdder.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(withRouter(AccessAdder)));