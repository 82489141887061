import React, {  } from 'react';
import {withTheme, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DQModal from '../DQModal/DQModal';
import AdditionalPropertyAdder from '../AdditionalPropertyAdder/AdditionalPropertyAdder';
import NoteAdder from '../NoteAdder/NoteAdder';
import GenericLinkModal from '../GenericLinkModal/GenericLinkModal';
import ListModalAdder from '../ListModalAdder/ListModalAdder';
import { mapObjectName } from '../../../utilities';
import EmbedUrlModal from './EmbedUrlModal';

const styles = theme => ({

});


const ProfileModalController = props => {
  const {
    state,
    dispatch,
    linkModalOpen, 
    setLinkModalOpen,
    modalMapping = [],
    history
  } = props;

  if(!state.basicData)return <></>

  return (
    <div>
      {
        modalMapping.includes('list') && 
        <ListModalAdder
          object={state.basicData}
          modalOpen={state.listModalOpen}
          setModalOpen={open=>dispatch({type:'set_list_modal_open',listModalOpen:open})}
          dispatch={dispatch}
          state={state}
        />
      }
      {
         modalMapping.includes('link') && 
        <GenericLinkModal
          object={state.basicData}
          history={history}
          modalOpen={linkModalOpen}
          setModalOpen={setLinkModalOpen}
          linkableObjects={linkModalOpen.linkableObjects}
          relations={linkModalOpen.relations}
          onLinkUpdated={linkModalOpen.onLinkUpdated}
        />
      }
      {
        modalMapping.includes('note') && 
        <NoteAdder
          history={history}
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.noteModalOpen}
          setModalOpen={value=>dispatch({type:'set_note_modal_open',noteModalOpen:value})}
          modalOnly={true}
        />
      }
      {
        modalMapping.includes('additional_property') && 
        <AdditionalPropertyAdder
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.additionalPropertiesModalOpen}
          setModalOpen={value=>dispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:value})}
          modalOnly={true}
        />
      }
      {
        modalMapping.includes('issue') && 
        <DQModal
          history={history}
          label={mapObjectName(state.basicData.object.name).toLowerCase()}
          objectName={state.basicData.name}
          state={state}
          dispatch={dispatch}
          id={state.basicData.id}
          dqModalOpen={state.dqModalOpen}
          setDqModalOpen={(open) => dispatch({ type: 'set_dqModal_open', dqModalOpen: open })}
        />
      }
      {
        modalMapping.includes('embed_url') && 
        <EmbedUrlModal
          state={state}
          dispatch={dispatch}
          modalOpen={state.embedUrlModalOpen}
          setModalOpen={value=>{
            dispatch({type:'set_embed_url_modal_open',embedUrlModalOpen:value})
          }}
        />
      }
    </div>
  )
}

ProfileModalController.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  linkModalOpen: PropTypes.object.isRequired,
  setLinkModalOpen: PropTypes.func.isRequired,
  modalMapping: PropTypes.arrayOf(
    PropTypes.oneOf(
      ['list', 'link', 'note', 'additional_property', 'issue']
    )
  ).isRequired,
  history: PropTypes.object.isRequired
};

export default withTheme()(withStyles(styles)(ProfileModalController));