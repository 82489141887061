import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, IconButton, Button, InputBase} from '@material-ui/core';
import { getIconComponent, handleShareClick, isInViewport, onClickResultItem, sendMessage, setHelpWdigetVisibility } from '../../../utilities';
import moment from 'moment'
import ScheduleIcon from '@material-ui/icons/QueryBuilder'
import PlayIcon from '@material-ui/icons/PlayCircleOutline'
import ManualLoad from '../ManualLoad/ManualLoad';
import KTooltip from '../../UI/KTooltip/KTooltip';
import TabBar from '../../UI/TabBar/TabBar';
import WarningConfirmModal from '../../UI/ConfirmModals/WarningConfirmModal';
import axiosCerebrum from '../../../axios-cerebrum';
import useAlert from '../../../hooks/useAlert';
import { loadReferences } from './ReferenceUtils';
import { loadOnboardedSources } from './SourceUtil';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu';
import CustomMenu from '../../UI/ContextMenu/ContextMenu'

const styles = theme => ({
  chip:{
    display:'flex', 
    padding:'4px 0', 
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`, 
    width:'100%',
    alignItems:'center',
    overflow:'hidden',
    '&:hover':{
      background:theme.palette.hovered.main
    },
  },
  // hoveredChip:{
  //   cursor:'pointer',
  //   '&:hover':{
  //     background:theme.palette.hovered.main
  //   },
  // },
  hoverableText:{
    cursor:'pointer',
    '&:hover':{
      textDecoration:'underline',
      background:theme.palette.hovered.main
    }
  },
  tooltip:{
    maxWidth:600,
  },
  title:{
    fontSize:16,
    color:theme.palette.header.main,
  },
  subtitle:{
    color:theme.palette.primaryText.light,
    fontSize:13.75
  },
  icon:{
    flex:'0 0 36px'
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  columnHeader:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.primary.main,
    marginLeft:16
  },
  columnText:{
    color:theme.palette.primaryText.main,
    fontSize:13.75,
    marginLeft:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  scheduleChip:{
    width:'max-content',
    padding:'4px 8px 5px',
    borderRadius:8
  },
  infoBox:theme.components.infoBox,
  infoHeader:theme.components.infoBoxHeader,
  infoDescription:theme.components.infoBoxDescription,
  focusTxt:theme.components.infoBoxFocusTxt,
  button:theme.components.infoBoxButtonContained,
  openMonitorButton:theme.components.infoBoxButtonOutlined,
  inputBase:{
    ...theme.components.inputBase,
    width:260,
    height:42
  }
})

const Home = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    history,
    // generateScheduleText
  } = props;
  
  const [manualLoadModalOpen, setManualLoadModalOpen] = useState(false)

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [deleting, setDeleting] = useState(false)

  const [buttonHovered, setButtonHovered] = useState(false);

  const isCancelledRef = useRef(false)
  const scrollRef = useRef()
  const searchRef = useRef()

  let tabOptions = ['ACTIVE','INACTIVE','REFERENCE'];

  const {
    sendAlert
  } = useAlert({
    isCancelledRef,
    disableStateCache:true
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  useEffect(()=>{
    setHelpWdigetVisibility(true)
    return ()=>{setHelpWdigetVisibility(false)}
  // eslint-disable-next-line
  },[])
  
  useEffect(()=>{
    dispatch({type:'set_selected_source'})
    dispatch({type:'set_selected_host'})
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(tabOptions[state.sourceTabState]==='ACTIVE'){
      if(state.activeSourcesLoading)return;
      if(state.activeSourcesError)return;
      if(state.activeSources)return;
      loadOnboardedSources({page:1,type:'active',state,dispatch})
    }
    if(tabOptions[state.sourceTabState]==='INACTIVE'){
      if(state.inactiveSourcesLoading)return;
      if(state.inactiveSourcesError)return;
      if(state.inactiveSources)return;
      loadOnboardedSources({page:1,type:'inactive',state,dispatch})
    }
    if(tabOptions[state.sourceTabState]==='REFERENCE'){
      if(state.referenceSourcesLoading)return;
      if(state.referenceSourcesError)return;
      if(state.referenceSources)return;
      loadReferences({page:1,state,dispatch})
    }
    // eslint-disable-next-line
  },[tabOptions, state.sourceTabState])

  useEffect(()=>{
    if(!state.referenceSources)return;
    if(state.tabState===0 && state.hostsUpdated){
      loadReferences({page:1,state,dispatch,search:state.referenceSearchValue})
      dispatch({type:'set_hosts_updated',hostsUpdated:false})
    }
  // eslint-disable-next-line
  },[state.tabState,state.hostsUpdated])

  useEffect(()=>{
    if(!state.activeSources && !state.inactiveSources)return;
    if([0,1].includes(state.tabState) && state.sourcesUpdated){
      if(state.tabState===0){
        loadOnboardedSources({page:1,type:'active',state,dispatch})
        dispatch({type:'set_inactive_sources'})
      }
      if(state.tabState===1){
        loadOnboardedSources({page:1,type:'inactive',state,dispatch})
        dispatch({type:'set_active_sources'})
      }
      dispatch({type:'set_sources_updated',sourcesUpdated:false})
    }
  // eslint-disable-next-line
  },[state.tabState,state.sourcesUpdated])

  const shouldLoadMoreRef = () => {
    if(tabOptions[state.sourceTabState]==='REFERENCE'){ 
      return !state.referenceSourcesLoading && !state.referenceSourcesError && state.referenceSources?.page < state.referenceSources?.pages && isInViewport(scrollRef)
    }
    if(tabOptions[state.sourceTabState]==='ACTIVE'){
      return !state.activeSourcesLoading && !state.activeSourcesError && state.activeSources?.page < state.activeSources?.pages && isInViewport(scrollRef)
    }
    if(tabOptions[state.sourceTabState]==='INACTIVE'){
      return !state.inactiveSourcesLoading && !state.inactiveSourcesError && state.inactiveSources?.page < state.inactiveSources?.pages && isInViewport(scrollRef)
    }
  }

  useEffect(()=>{
    if(shouldLoadMoreRef()){
      if(tabOptions[state.sourceTabState]==='REFERENCE'){
        loadReferences({page:state.referenceSources.page+1,state,dispatch})
      }
      if(tabOptions[state.sourceTabState]==='ACTIVE'){
        loadOnboardedSources({page:state.activeSources.page+1,type:'active',state,dispatch})
      }
      if(tabOptions[state.sourceTabState]==='INACTIVE'){
        loadOnboardedSources({page:state.inactiveSources.page+1,type:'inactive',state,dispatch})
      }
    }
    // eslint-disable-next-line
  },[state.referenceSources, tabOptions, state.sourceTabState])

  window.onscroll = () => {
    if(shouldLoadMoreRef()){
      if(tabOptions[state.sourceTabState]==='REFERENCE'){
        loadReferences({page:state.referenceSources.page+1,state,dispatch})
      }
      if(tabOptions[state.sourceTabState]==='ACTIVE'){
        loadOnboardedSources({page:state.activeSources.page+1,type:'active',state,dispatch})
      }
      if(tabOptions[state.sourceTabState]==='INACTIVE'){
        loadOnboardedSources({page:state.inactiveSources.page+1,type:'inactive',state,dispatch})
      }
    }
  }

  const onClickEdit = el => {
    if(!el.source_template){
      dispatch({type:'set_selected_host', selectedHost:el})
      dispatch({type:'set_tab_state', tabState:8})
    }else{
      dispatch({type:'set_selected_source', selectedSource:el})
      dispatch({type:'set_tab_state', tabState:2})
    }
  }

  const onClickSchedule = el => {
    dispatch({type:'set_selected_source', selectedSource:el})
    dispatch({type:'set_tab_state', tabState:3})
    window.scrollTo({top:0,left:0,behavior:'smooth'})
  }

  const onMerge = el => {
    dispatch({type:'set_selected_source', selectedSource:el})
    dispatch({type:'set_tab_state', tabState:4})
    window.scrollTo({top:0,left:0,behavior:'smooth'})
  }

  const onClickManualLoad = el => {
    dispatch({type:'set_selected_source', selectedSource:el})
    if(el.job_mode==='LOAD_ONLY'){
      sendMessage({manual_load_source:JSON.stringify(el)})
    }else{
      setManualLoadModalOpen(true)
    }
  }

  const onEditAccessRequest = el => {
    dispatch({type:'set_selected_source', selectedSource:el})
    dispatch({type:'set_tab_state', tabState:5})
    window.scrollTo({top:0,left:0,behavior:'smooth'})
  }

  const onCreateSourceFromHost = host => {
    dispatch({type:'set_tab_state',tabState:1})
    dispatch({type:'set_selected_host', selectedHost:host})
  }

  const onDelete = el => {
    setDeleting(true)
    axiosCerebrum 
      .delete(
        `/api/hosts/${el.id}`
      )
      .then(response => {
        setDeleting(false)
        setDeleteModalOpen(false)
        dispatch({type:'set_hosts_updated',hostsUpdated:true})
        sendAlert({type:'info',message:'Source successfully deleted'})
      })
      .catch(error => {
        setDeleting(false)
        setAlertMessage('Error occurred deleting source, please try again')
        setAlertOpen(true)
      })
  }

  const onChangeRefSearch = value => {
    dispatch({type:'set_reference_search_value',referenceSearchValue:value})
    clearTimeout(searchRef.current)
    searchRef.current = setTimeout(()=>{
      loadReferences({page:1,search:value,state,dispatch})
    },500)
  }

  const getColumnRow = ({activated,reference}) => {
    return (
      <div style={{display:'flex',marginBottom:16}}>
        <div className={classes.columnHeader} style={{flex:'0 0 24px'}}></div>
        <div className={classes.columnHeader} style={{flex:'1 1 270px',minWidth:80}}>
          {
            reference?
            'REFERENCE NAME':
            'SOURCE NAME'
          }
        </div>
        <div className={classes.columnHeader} style={{flex:reference?'0 0 200px':'0 0 160px'}}>STATUS</div>
        {
          activated && !reference && 
          <div className={classes.columnHeader} style={{flex:'0 0 160px'}}>LOAD TYPE</div>
        }
        <div className={classes.columnHeader} style={{flex:'0 0 200px'}}>ADDED</div>
        {
          reference && 
          <div className={classes.columnHeader} style={{flex:'0 1 180px',minWidth:80}}>ADDED BY</div>
        }
        <div className={classes.columnHeader} style={{flex:'0 0 220px'}}>ACTION</div>
      </div>
    )
  }

  const getSourceItem = (el) => {
    let isRef = !el.source_template
    let sourceName, hostName;
    if(isRef){
      sourceName = el.alternate_name
      hostName = el.name
    }else{
      sourceName = el.name
      hostName = el.host
    }
    return (
      <>
        <ContextMenuTrigger id={el.id}>
          <div 
            className={classes.chip} 
            style={{background:buttonHovered?theme.palette.background.main:undefined}}
            // onClick={()=>window.open(`/profile/source/${isRef?el.id:el.host_ids[0]}`,'_blank')}
          >
            <div className={classes.columnText} style={{flex:'0 0 24px'}}>
              {getIconComponent({label:isRef?'info':el.source_template.name,size:24, colour:theme.palette.primary.main, defaultLabel:'source'})}
            </div>
            <div style={{flex:'1 1 270px',minWidth:80}}>
              <KTooltip title={sourceName || 'Unknown'} placement="bottom-start">
                <div 
                  className={classes.columnText } 
                >
                  <span 
                    style={{width:"max-content",maxWidth:'100%'}}
                    className={classes.hoverableText} 
                    onMouseEnter={()=>setButtonHovered(true)}
                    onMouseLeave={()=>setButtonHovered(false)}
                    onClick={()=>{
                      onClickResultItem({
                        history,
                        id:isRef?el.id:el.host_ids[0],
                        label:'source',
                        newWindow:true,
                      })
                    }}
                  >
                    {sourceName || 'Unknown'}
                  </span>
                </div>
              </KTooltip>
              {
                !isRef?
                <KTooltip 
                  title={
                    <div style={{display:'flex',alignItems:'center'}}>
                      <span style={{overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{hostName}</span>
                      <span style={{cursor:'pointer',marginLeft:8}} onClick={(event)=>{
                        event.stopPropagation();
                        handleShareClick(hostName)
                      }}>
                        {getIconComponent({label:'copy',size:16,colour:'#fff'})}
                      </span>
                    </div>
                  } 
                  placement="bottom-start"
                  interactive={true}
                  leaveDelay={500}
                >
                  <div className={classes.columnText} style={{color:theme.palette.primaryText.light,display:'flex',alignItems:'center',fontSize:12,marginTop:4}}>
                  {hostName}
                  </div>
                </KTooltip>
                :
                <KTooltip 
                  title={hostName} 
                  placement="bottom-start"
                  interactive={true}
                  leaveDelay={500}
                >
                  <div className={classes.columnText} style={{color:theme.palette.primaryText.light,display:'flex',alignItems:'center',fontSize:12,marginTop:4}}>
                    {hostName}
                  </div>
                </KTooltip>
              }
            </div>
            {
              isRef && 
              <div className={classes.columnText} style={{flex:'0 0 200px'}}>
                {
                  el.manual_creation?
                  <div className={classes.scheduleChip} style={{background:'#7ED321',color:'#000'}}>Added by user</div>:
                  <div className={classes.scheduleChip} style={{background:'#F5A623',color:'#000'}}>Detected by K collector</div>
                }
              </div>
            }
            {
              !isRef && 
              <div className={classes.columnText} style={{flex:'0 0 160px'}}>
                {
                  el.active_flag===false?
                  <div className={classes.scheduleChip} style={{background:'#dedede',color:'#000'}}>Deactivated</div>
                  :
                  el.configured_flag?
                    el.job_mode==='LOAD_ONLY'?
                      <div className={classes.scheduleChip} style={{background:'#dedede',color:'#000'}}>N/A</div>
                      :
                      el.scheduled_flag===false?
                        <div className={classes.scheduleChip} style={{background:'#F5A623',color:'#000'}}>Unscheduled</div>
                        :
                        <div className={classes.scheduleChip} style={{background:'#7ED321',color:'#000'}}>Scheduled</div>
                    :
                    <div className={classes.scheduleChip} style={{background:'#EA5455',color:'#fff'}}>Not configured</div>
                }
              </div>
            }
            {
              el.active_flag===true && !isRef && 
              <div className={classes.columnText} style={{flex:'0 0 160px',color:theme.palette.primaryText.light}}>
                {el.job_mode==='LOAD_ONLY'?'Load from File':'Direct connect'}
              </div>
            }
            <div className={classes.columnText} style={{flex:'0 0 200px',color:theme.palette.primaryText.light}}>
              {moment(el.created_at).format('llll')}
            </div>
            {
              isRef &&
              <KTooltip title={el.creator?.name}>
                <div className={classes.columnText} style={{flex:'0 1 180px',minWidth:80}}>
                  {el.creator?.name || 'N/A'}
                </div>
              </KTooltip>
            }
            <div className={classes.columnText} style={{flex:'0 0 220px',display:'flex',justifyContent:'flex-start'}}>
              <KTooltip title="Edit source load settings">
                <IconButton 
                  onClick={(event)=>{event.stopPropagation();onClickEdit(el)}}
                  onMouseEnter={()=>setButtonHovered(true)}
                  onMouseLeave={()=>setButtonHovered(false)}
                >
                  {getIconComponent({label:'edit',colour:theme.palette.primaryText.light,size:24})}
                </IconButton>
              </KTooltip>
              {
                isRef && 
                <KTooltip title={'Onboard Source from this reference'}>
                  <IconButton 
                    onClick={(event)=>{event.stopPropagation();onCreateSourceFromHost(el)}}
                    onMouseEnter={()=>setButtonHovered(true)}
                    onMouseLeave={()=>setButtonHovered(false)}
                  >
                    {getIconComponent({label:'add_circle',colour:theme.palette.primaryText.light,size:24})}
                  </IconButton>
                </KTooltip>
              }
              {
                el.active_flag===true && !isRef && 
                <>
                  <KTooltip title={(!el.configured_flag)?"Source must be configured before a load can be completed":"Run manual load"}>
                    <div>
                      <IconButton 
                        disabled={!el.configured_flag} 
                        onClick={(event)=>{event.stopPropagation();onClickManualLoad(el)}}
                        onMouseEnter={()=>setButtonHovered(true)}
                        onMouseLeave={()=>setButtonHovered(false)}
                      >
                        <PlayIcon style={{color:theme.palette.primaryText.light+((!el.configured_flag)?"75":""),width:24,height:24}}/>
                      </IconButton>
                    </div>
                  </KTooltip>
                  <KTooltip title={
                      (!el.configured_flag || el.job_mode==='LOAD_ONLY')?
                        el.job_mode==='LOAD_ONLY'?
                        <div style={{display:'flex',alignItems:'center'}}>
                          <Typography style={{color:'#fff',fontSize:13.75}}>Source load frequencies are set at the platform level</Typography>
                          <Button style={{marginLeft:16,flexShrink:0}} color='secondary' onClick={event=>{event.stopPropagation();history.push(`/admin/platform_management?tabName=BATCH MANAGER`)}} >CHANGE SETTING</Button>
                        </div>
                        :
                        "Source must be configured before a schedule can be set"
                      :
                      "Schedule load"
                    }
                    classes={{tooltip:classes.tooltip}}
                    leaveDelay={200} 
                    interactive={true}
                  >
                    <div>
                      <IconButton 
                        disabled={!el.configured_flag || el.job_mode==='LOAD_ONLY'} 
                        onClick={(event)=>{event.stopPropagation();onClickSchedule(el)}}
                        onMouseEnter={()=>setButtonHovered(true)}
                        onMouseLeave={()=>setButtonHovered(false)}
                      >
                        <ScheduleIcon style={{color:theme.palette.primaryText.light+((!el.configured_flag || el.job_mode==='LOAD_ONLY')?"75":""),width:24,height:24}}/>
                      </IconButton>
                    </div>
                  </KTooltip>
                  {
                    el.source_template.type==='TOOL'?
                    <KTooltip title={!el.last_loaded_at?"An initial load of this source is required before you can map its connections":`Merge ${el.name} sources to sources integrated with K`}>  
                      <div>
                        <IconButton 
                          disabled={!el.last_loaded_at} 
                          onClick={(event)=>{event.stopPropagation();onMerge(el)}}
                          onMouseEnter={()=>setButtonHovered(true)}
                          onMouseLeave={()=>setButtonHovered(false)}
                        >
                          {getIconComponent({label:'merge',colour:theme.palette.primaryText.light+((!el.last_loaded_at)?"75":""),size:24})}
                        </IconButton>
                      </div>
                    </KTooltip>
                    :
                    <div style={{width:40}}></div>
                  }
                  <KTooltip title={'Edit access request'}>
                    <div>
                      <IconButton 
                        onClick={(event)=>{event.stopPropagation();onEditAccessRequest(el)}}
                        onMouseEnter={()=>setButtonHovered(true)}
                        onMouseLeave={()=>setButtonHovered(false)}
                      >
                      {getIconComponent({label:'access_role',colour:theme.palette.primaryText.light,size:24})}
                      </IconButton>
                    </div>
                  </KTooltip>
                </>
              }
            </div>
          </div>
        </ContextMenuTrigger>
        <ContextMenu id={el.id}>
          <CustomMenu
            item={el}
            actions={[
              'open_new_tab'
            ]}
          />
        </ContextMenu>
      </>
    )
  }

  
  let activeList = state.activeSources?.items
  let inactiveList =  state.inactiveSources?.items
  let referenceList = state.referenceSources?.items;

  let loading, error;
  if(tabOptions[state.sourceTabState]==='ACTIVE'){
    loading = state.activeSourcesLoading
    error = state.activeSourcesError
  }
  if(tabOptions[state.sourceTabState]==='INACTIVE'){
    loading = state.inactiveSourcesLoading
    error = state.inactiveSourcesError
  }
  if(tabOptions[state.sourceTabState]==='REFERENCE'){
    loading = state.referenceSourcesLoading
    error = state.referenceSourcesError
  }
  
  return (
    <div className={classes.root}>
      {
        ['ACTIVE','INACTIVE'].includes(tabOptions[state.sourceTabState]) && 
        <div className={classes.infoBox}>
          <Typography className={classes.infoHeader}>About Sources</Typography>
          <div style={{display:'flex',alignItems:'flex-start'}}>
            <Typography className={classes.infoDescription}>
              K can connect to a range of identity stores (e.g. Active Directory), databases (e.g. Snowflake), and tools (e.g. Tableau). Sources can be integrated directly or be loaded from your own pipelines via source files. See our <span className={classes.focusTxt} onClick={()=>{window.open('https://kada-knowledge-library.scrollhelp.site/home/Integration-Guides.1888683066.html','_blank')}}>Integrations guides</span> for more details.
              {'\n\n'}
              For direct source connections, you can set a scheduled load time. Load errors will trigger a notification to the <span className={classes.focusTxt} onClick={()=>{window.open('/admin/k_platform_setup','_blank')}}>platform notification email</span>. Use the <span className={classes.focusTxt} onClick={()=>{window.open('/dashboard/data_load','_blank')}}>data load dashboard</span> to view the status of your scheduled source loads.
              {'\n\n'}
              For sources that are loaded via files, you can set the load frequency via the <span className={classes.focusTxt} onClick={()=>{window.open('/admin/platform_management?tabName=BATCH MANAGER','_blank')}}>batch manager</span> page. The same load frequency will apply to all source files.  
              {`\n\n`}
              We are always adding new source connectors to K. If you can’t find the source connector you are looking for, raise a new feature request via our K Support Portal.
            </Typography>
            <div>
              <Button className={classes.button} style={{width:205,marginBottom:16}} onClick={()=>dispatch({type:'set_tab_state',tabState:1})}>
                ADD SOURCE
              </Button>
              <Button className={classes.openMonitorButton} style={{width:205,marginBottom:16}} onClick={()=>history.push('/admin/platform_management?tabName=MONITOR')}>
                GO TO MONITOR
              </Button>
              <Button className={classes.openMonitorButton} style={{width:205}} onClick={()=>dispatch({type:'set_tab_state',tabState:6})}>
                DOWNLOAD COLLECTORS
              </Button>
            </div>
            
          </div>
        </div>
      }

      {
        ['REFERENCE'].includes(tabOptions[state.sourceTabState]) &&
        <div className={classes.infoBox}>
          <Typography className={classes.infoHeader}>About References</Typography>
          <div style={{display:'flex',alignItems:'flex-start'}}>
            <Typography className={classes.infoDescription}>
              Reference sources are sources that have not been onboarded to K via an integration (collector or direct connect). Reference sources can be added OR detected. 
              {'\n\n'}
              After adding a reference source, you can manually update lineage maps to include links to these sources. This allows you to build out a more comprehensive map for your data stakeholders to discovery. 
              {'\n\n'}
              Through K's automated intelligence, K will detect potential sources and will show these as <span className={classes.focusTxt} onClick={()=>{window.open('https://docs.kada.ai/home/understanding-reference-objects-databases-schema-t','_blank')}}>reference sources</span>. If you subsequently onboard a reference source, select the correct host during the onboarding process to merge the two. 
              {'\n\n'}
              To update the name and details of reference sources, click on the edit icon. 
            </Typography>
            <div>
              <Button className={classes.button} style={{width:190,marginBottom:16}} onClick={()=>dispatch({type:'set_tab_state',tabState:7})}>
                ADD Reference
              </Button>
            </div>
            
          </div>
        </div>
      }


      {
        tabOptions.length>0 && 
        <div style={{marginLeft:-52,marginBottom:16}}>
          <TabBar
            tabOptions={tabOptions}
            tabState={state.sourceTabState}
            setTabState={value => dispatch({ type: 'set_source_tab_state', sourceTabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        </div>
      }

      {
        tabOptions[state.sourceTabState]==='ACTIVE' && 
        <>
          <div style={{position:'sticky',paddingBottom:8,top:128,zIndex:9,background:theme.palette.background.main}}>
            <Typography style={{color:theme.palette.header.main,fontSize:20, letterSpacing:1}}>
              {`${state.activeSources?.total||''} ACTIVE SOURCE(S)`}
            </Typography>
            <Typography style={{color:theme.palette.primaryText.light,fontSize:12,marginBottom:16}}>
              Sources that are actively integrated with K
            </Typography>
          </div>
          
          {
            activeList?.length>0 && 
            <>
              {getColumnRow({activated:true})}
              {activeList.map(getSourceItem)}
            </>
          }
          {
            activeList?.length===0 && 
            <Typography>
              No active sources found
            </Typography>
          }
        </>
      }

      {
        tabOptions[state.sourceTabState]==='INACTIVE' && 
        <>
          <div style={{position:'sticky',paddingBottom:8,top:128,zIndex:9,background:theme.palette.background.main}}>
            <Typography style={{color:theme.palette.header.main,fontSize:20, letterSpacing:1}}>
              {`${state.inactiveSources?.total||''} INACTIVE SOURCE(S)`}
            </Typography>
            <Typography style={{color:theme.palette.primaryText.light,fontSize:12,marginBottom:24}}>
              Sources that are inactive
            </Typography>
          </div>
          {
            inactiveList?.length>0 &&
            <>
              {getColumnRow({})}
              {inactiveList.map(getSourceItem)}
            </>
          }
          {
            inactiveList?.length===0 && 
            <Typography>
              No inactive sources found
            </Typography>
          }
        </>
      }

      { 
        tabOptions[state.sourceTabState]==='REFERENCE' && 
        <>
          <div style={{position:'sticky',display:'flex',alignItems:"flex-start",paddingBottom:8,top:128,zIndex:9,background:theme.palette.background.main}}>
            <div style={{flexGrow:1,marginRight:16}}>
              <Typography style={{color:theme.palette.header.main,fontSize:20, letterSpacing:1}}>
                {`${state.referenceSources?.total||''} REFERENCE SOURCE(S)`}
              </Typography>
              <Typography style={{color:theme.palette.primaryText.light,fontSize:12,marginBottom:24}}>
                Reference sources
              </Typography>
            </div>
            <InputBase
              value={state.referenceSearchValue}
              onChange={event=>{
                onChangeRefSearch(event.target.value)
              }}
              className={classes.inputBase}
              placeholder='Search reference sources'
              endAdornment={
                <IconButton 
                  disabled={state.referenceSearchValue===''} 
                  onClick={()=>onChangeRefSearch('')}
                  style={{width:32,height:32,marginRight:6}}
                >
                  {getIconComponent({label:state.referenceSearchValue===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
              }
            />
          </div>
          {
            referenceList?.length>0 && 
            <>
              {getColumnRow({reference:true})}
              {referenceList.map(getSourceItem)}
             </> 
          }
          {
            referenceList?.length===0 && 
            <Typography>
              No reference sources found
            </Typography>
          }
        </>
      }


      {
        error && 
        <Typography>Error occurred loading sources</Typography>
      }

      <div ref={scrollRef} style={{display:'flex',justifyContent:'center',marginTop:16,marginBottom:24}}>
        {
          loading && 
          <CircularProgress color='secondary'/>
        }
      </div>

      <WarningConfirmModal
        description={
          <span style={{whiteSpace:'pre-wrap'}}>
            You are about to delete <span style={{fontWeight:700}}>{deleteModalOpen?.name}</span>. 
            {'\n\n'}
            This will permanentely delete the source and details added or linked to this source. 
            {'\n\n'}
            This cannot be undone
          </span>
        }
        setModalOpen={setDeleteModalOpen}
        modalOpen={deleteModalOpen}
        onConfirm={()=>onDelete(deleteModalOpen)}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
        alertMessage={alertMessage}
        confirmText={'DELETE'}
        disableConfirmButton={deleting}
      />

      <ManualLoad
        modalOpen={manualLoadModalOpen}
        setModalOpen={setManualLoadModalOpen}
        source={state.selectedSource}
        history={history}
      />
    </div>
  )
}

export default withTheme()(withStyles(styles)(Home));