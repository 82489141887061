import React, { useReducer, useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/core';
import Body from '../../components/BasicSearch/Body/Body'
import {  setInitialState, toTitleCase, getUserRoles, setHelpWdigetVisibility, formatNumber, removeUrlQueryArg } from '../../utilities'
import axiosSolr from '../../axios-solr'
import axiosCerebrum from '../../axios-cerebrum'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import 'url-search-params-polyfill';
import { globalListenerRef } from '../../GlobalListenerRef'
import { addHistory } from '../../HistoryManager';
import { useStore} from 'react-redux'
import { generateUrl, getCollectionObjectName, getCollectionTypeObjectName, getDefaultColumn, getSearchFilterList, getSearchParams, getSearchQuery, processFieldName, processObjectType, rangeFilterFileds } from '../../components/BasicSearch/MainSearch/Utils/Utils';
import { defaultFilterMap } from '../../components/BasicSearch/MainSearch/Utils/Utils';
import PropTypes from 'prop-types'
import { addToCartLimit } from '../../components/UI/CartAdder/CartAdder';
import useAlert from '../../hooks/useAlert';

const styles = theme => ({
});

const initialState = {
  view:'landing',
  searchMode:[],
  landingPageSelectedObject:[], // landing page, to select objects to search on
  searchValue:'',
  selectedObjectType:undefined,
  isSavedFilter:false,
  pageNum:1,
  searchResults:{}, // in the form of  { [pageNum]:[list] }
  mainSearchFilters:[], // using negative filter
  mainSearchQuery:'',
  mainSearchSort:'',
  tabGrouping:[],
  filterStatus:{}, // {[field_name]:{loading, error, values:[...all values] }},
  selectedColumns:['source_type_srt','trust','usage_srt','last_used_srt'],
  allColumns:[],
  allFilters:[],
  selectedFilters:[],
  negativeFilters:[],
  andFilters:[],
  cartItems:[],
  isSelectAll:false,
};


function reducer(state, action) {
  switch (action.type) {
    case 'set_view':
      return {
        ...state,
        view:action.view
      }
    //// landing page
    case 'set_search_value':
      return {
        ...state,
        searchValue:action.searchValue
      }
    case 'set_search_mode':
      return {
        ...state,
        searchMode:action.searchMode
      }
    case 'set_recommend_list_data':
      return {
        ...state,
        recommendListData:action.recommendListData,
        recommendListLoading:action.recommendListLoading,
        recommendListError:action.recommendListError
      }
    case 'set_landing_page_selected_object':
      return {
        ...state,
        landingPageSelectedObject:action.landingPageSelectedObject
      }
    case 'set_source_recommendation':{
      return {
        ...state,
        sourceRecommendation:action.sourceRecommendation
      }
    }
    case 'set_collection_recommendation':{
      return {
        ...state,
        collectionRecommendation:action.collectionRecommendation
      }
    }
    ///////
    /// main search 
    case 'set_hide_empty_filter_value':
      return {
        ...state,
        hideEmptyFilterValue:action.hideEmptyFilterValue
      }
    case 'set_is_saved_filter':
      return {
        ...state,
        isSavedFilter:action.isSavedFilter
      }
    case 'set_is_filter_or_column_changed':
      return {
        ...state,
        isFilterOrColumnChanged:action.isFilterOrColumnChanged
      }
    case 'set_tab_grouping':
      return {
        ...state,
        tabGrouping:action.tabGrouping
      }
    case 'set_main_search_query':
      return {
        ...state,
        mainSearchQuery:action.mainSearchQuery
      }
    case 'set_main_search_filters':
      return {
        ...state,
        mainSearchFilters:action.mainSearchFilters
      }
    case 'set_main_search_sort':
      return {
        ...state,
        mainSearchSort:action.mainSearchSort
      }
    case 'set_main_search_preset_filter_srt':
      return {
        ...state,
        mainSearchPresetFilterStr:action.mainSearchPresetFilterStr
      }
    case 'set_selected_object_chip':
      return {
        ...state,
        selectedObjectChip:action.selectedObjectChip
      }
    case 'set_selected_object_type':
      return {
        ...state,
        selectedObjectType:action.selectedObjectType
      }
    case 'set_search_results':
      return {
        ...state,
        searchResults:action.searchResults,
      }
    case 'set_search_tabs':
      return {
        ...state,
        searchTabs:action.searchTabs,
        searchTabsLoading:action.searchTabsLoading,
        searchTabsError:action.searchTabsError
      }
    case 'set_page_num':
      return {
        ...state,
        pageNum:action.pageNum
      }
    case 'set_results_total':
      return {
        ...state,
        resultsTotal:action.resultsTotal
      }
    case 'set_filter_status':
      return {
        ...state,
        filterStatus:action.filterStatus
      }
    case 'set_all_filters':
      return {
        ...state,
        allFilters:action.allFilters,
        allFiltersLoading:action.allFiltersLoading,
        allFiltersError:action.allFiltersError
      }
    case 'set_hide_filter':
      return {
        ...state,
        hideFilter:action.hideFilter
      }
    case 'set_available_filters':
      return {
        ...state,
        availableFilters:action.availableFilters,
        availableFiltersLoading:action.availableFiltersLoading
      }
    case 'set_selected_filters':
      return {
        ...state,
        selectedFilters:action.selectedFilters
      }
    case 'set_negative_filters':
      return {
        ...state,
        negativeFilters:action.negativeFilters
      }
    case 'set_and_filters':
      return {
        ...state,
        andFilters:action.andFilters
      }
    case 'set_selected_columns':
      return {
        ...state,
        selectedColumns:action.selectedColumns
      }
    case 'set_all_columns':
      return {
        ...state,
        allColumns:action.allColumns
      }
    case 'set_cart_items':
      return {
        ...state,
        cartItems:action.cartItems
      }
    case 'set_is_select_all':
      return {
        ...state,
        isSelectAll:action.isSelectAll
      }
    default:
      console.log(action)
      throw new Error("Reducer action not supported.", action);
  }
}

const BasicSearch = props => {

  const {
    customID,
    history,
    pageCache,
    storePageCache,
    propQuery,
    propFilter,
    propObjectType,
    propColumn,
    propSelectedFilters,
    propFilterlistId,
    propAndFilters,
    propSort,
    propCache,
    propStoreCache,
    forceGlobalFilter,
    alwaysOpenNewTab,
    initialView,
    removeContainerStyle,
    hiddenComponents = [],
    addtioonalComponents = [],
    customHeaderFormatter,
    resultItemVariant,
    isCreateFilter,
    indexName='search',
    customEmptyMsg,
    hideEmptyFilterValue,
    forceOnItemClick
  } = props;

  const isNotSearchPage = window.location.pathname!=='/basic_search'

  const urlSearch = new URLSearchParams(window.location.search);
  const query = urlSearch.get('query')
  const mode = urlSearch.get('searchMode')
  const andFilters = urlSearch.get('andFilters')

  const store = useStore();
  const sessionData = store.getState().auth.session_user;
  const roles = getUserRoles(sessionData.user_role)

  let defaultState; 
  if(isNotSearchPage){
    defaultState = propCache || initialState
  }else{
    defaultState = query?setInitialState(pageCache,initialState):initialState
  }
  if(initialView)defaultState.view = initialView;
  if(mode)defaultState.searchMode = mode.split(',');
  if(andFilters)defaultState.andFilters = andFilters.split(',')
  // defaultState.mainSearchSort = '';
  
  const [state, dispatch] = useReducer(reducer,defaultState)

  const [isInitiated, setIsInitiated] = useState(false)
  const [currentParam, setCurrentParam] = useState()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  let perPage = 10;

  const getDefaultParams = () => {
    if(indexName!=='search')return {}
    let params = {}
    try{
      params = JSON.parse(JSON.parse(localStorage.getItem('platformSettings')).items.find(el=>el.id===8000).value);
    }catch{

    }
    return params
  }

  let defaultParams = {}
  try{
    defaultParams = getDefaultParams()
    perPage = defaultParams.rows || 10;
  }catch{}


  const generateRawTabGrouping = () => {
    let groupingOrder = ['ALL','CONTENT','DATA','SOURCE','COLLECTIONS','CODE','PEOPLE','ISSUES','TAGS']
    if(roles.every(el=>el==='90') || roles.includes('40'))groupingOrder = ['ALL','CONTENT','DATA','SOURCE','COLLECTIONS','PEOPLE','ISSUES','TAGS','CODE']
    if(roles.includes('10'))groupingOrder = ['ALL','CONTENT','DATA','SOURCE','COLLECTIONS','PEOPLE','ISSUES','TAGS','CODE']

    return [
      {
        name:'ALL',
        tabs:['ALL']
      },
      {
        name:'CONTENT', 
        tabs:['CONTENT_APP','REPORT','SHEET','DATASET','DATASET_TABLE','DATASET_FIELD','DATA_PIPELINE','ML_MODEL','FILE','WORKSPACE','TOOL']
      },
      {
        name:'DATA', 
        tabs:['TABLE','COLUMN','SCHEMA','DATABASE'],
      },
      {
        name:'CODE', 
        // tabs:['CODE','DATA_QUALITY_TEST']
        tabs:['QUERY','MACRO','PROCEDURE','DATA_QUALITY_TEST']
      },
      {
        name:'SOURCE', 
        tabs:['SOURCE']
      },
      {
        name:'PEOPLE', 
        tabs:['TEAM','USER'],
      },
      {
        name:'COLLECTIONS',
        tabs:[],
        isCollectionType:true
      },
      {
        name:'ISSUES', 
        tabs:['ISSUE'],
      },
      {
        name:'TAGS', 
        tabs:['TAG'],
      },
    ].sort((a,b)=>groupingOrder.indexOf(a.name)-groupingOrder.indexOf(b.name))
  }

  useEffect(()=>{
    if(state.tabGrouping.length===0){
      dispatch({type:'set_tab_grouping',tabGrouping:generateRawTabGrouping()})
    }
    dispatch({type:'set_hide_empty_filter_value',hideEmptyFilterValue})
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    setHelpWdigetVisibility(true)
    return ()=>{
      setHelpWdigetVisibility(false)
      window.onpopstate = null;
    }
  // eslint-disable-next-line
  },[])

  window.onpopstate = () => {
    if(isNotSearchPage)return;
    if(state.view==='landing'){
      return;
    }
    dispatch({type:'set_view',view:'landing'})
  }
 
  useEffect(()=>{
    if(state.view==='landing' && !isNotSearchPage){
      addHistory({
        url:window.location.pathname, 
        iconLabel:'search', 
        title: 'Search Page', 
        subTitle: 'Search Page',
        type:'application',
      })
    }
  // eslint-disable-next-line
  },[state.view])

  useEffect(()=>{
    if(!state)return;
    if(isNotSearchPage){
      propStoreCache && propStoreCache({...state})
    }else{
      storePageCache({cacheID:window.location.href,...state})
    } 
  // eslint-disable-next-line
  },[state])

  const getDefaultFilter = objectType => {
    let filters = []
    if(indexName!=='search')return filters;
    if(['TABLE','SCHEMA','DATABASE','TOOL','USER','ALL'].includes(objectType)){
      filters.push('reference_srt:("NO")')
    }
    // if(['DATASET','DATASET_FIELD','DATASET_TABLE','DATA_PIPELINE','SCHEMA','DATABASE','TOOL','REPORT','SHEET','TABLE','COLUMN','CONTENT_APP','USER','TEAM','FILE'].includes(objectType)){
      filters.push('active_srt:("YES")')
    // }
    return filters
  }

  const getFilterByStr = (str, processOnly, andFilters=state.andFilters) => {
    const getFieldName = el => el.trim().split(':')[0];
    const getValue = el =>  el.trim().split(':').slice(1).join(':')
    const negativeFields = []
    let parts = str.split(' AND ').map(el=>{
      let fieldName = getFieldName(el);
      let concationWord = andFilters.includes(fieldName)?' AND ':' OR ';
      let isNegSelect = fieldName[0]==='-'
      let isRange = rangeFilterFileds.includes(fieldName)
      let isDate = state.allFilters?.find(f=>f.field===fieldName)?.type==='pdate'
      if(isNegSelect){
        negativeFields.push(fieldName.slice(1));
      }
      let values = getValue(el).split(',');
      let positiveValues = values.filter(v=>v==='NO' || v.split(' ')[0]!=='NO');
      let negativeValues = values.filter(v=>v!=='NO' && v.split(' ')[0]==='NO').map(v=>v.split(' ')[1]);
      let str;
      let shouldWrapQuotes = !['pint','plong','pdate'].includes(state.allFilters?.find(f=>f.field===fieldName)?.type)
      if(isRange){
        let rangeType = values[0]?.toLowerCase()
        if(rangeType==='between'){
          str = `${fieldName}:[${values[1]} TO ${values[2]}]`
        }
        if(!isNaN(values[1])){
          if(rangeType==='greater'){
            str = `${fieldName}:[${values[1]+1} TO *]`
          }
          if(rangeType==='greater or equal to'){
            str = `${fieldName}:[${values[1]} TO *]`
          }
          if(rangeType==='less'){
            str = `${fieldName}:[* TO ${values[1]-1}]`
          }
          if(rangeType==='less or equal to'){
            str = `${fieldName}:[* TO ${values[1]}]`
          }
        }
      }
      else if(isDate){
        if(values[1]==='*' || !values[1])str = '*:*'
        else if(values[1])str = `${fieldName}:${values[1]}`
        // else if(['from_today'].includes(values[0])){ // to handle old filter str
        //   str = `${fieldName}:[* TO *]`;
        // }
        else{str = `${fieldName}:${values[0]}`}
      }
      else if(isNegSelect){
        if(positiveValues.length>0){
          str = fieldName+':('+positiveValues.map(el=>shouldWrapQuotes?`"${el}"`:el).join(concationWord)+')'
          if(negativeValues.length>0){
            str = `(${str} OR (${fieldName.split('-')[1]}:[* TO *]))`
          }
        }else{
          str = `${fieldName.split('-')[1]}:[* TO *]`
        }
      }else{
        if(positiveValues.length>0){
          str = fieldName+':('+positiveValues.map(el=>shouldWrapQuotes?`"${el}"`:el).join(concationWord)+')'
          if(negativeValues.length>0){
            str = `(${str} OR (*:* -${fieldName}:[* TO *]))`
          }
        }else{
          str = `-${fieldName}:[* TO *]`
        }
      }
      return str
    })
    if(!processOnly)dispatch({type:'set_negative_filters',negativeFilters:[...negativeFields,...(state.negativeFilters.filter(el=>!negativeFields.includes(el)))]})
    return parts
  }
  
  
  const getDefaultFilterStatus = objectType => {
    let status = {}
    if(indexName!=='search')return status;
    if(['TABLE','SCHEMA','DATABASE','TOOL','USER','ALL'].includes(objectType)){
      status['reference_srt'] = {
        selected:["NO"]
      }
    }
    // if(['DATASET','DATASET_FIELD','DATASET_TABLE','DATA_PIPELINE','SCHEMA','DATABASE','TOOL','REPORT','SHEET','TABLE','COLUMN','CONTENT_APP','USER','TEAM','ML_MODEL','FILE'].includes(objectType)){
      status['active_srt'] = {
        selected:["YES"]
      }
    // }
    return status
  }

  const getFilterStatusByStr = str => {
    let parts = str.split(' AND ').map(el=>el.trim())
    let status = {};
    parts.forEach(el=>{
      let isRange = rangeFilterFileds.includes(el.split(':')[0])
      let isDate = state.allFilters?.find(f=>f.field===el.split(':')[0])?.type==='pdate'
      if(isRange){
        status[el.split(':')[0]] = {
          selected:[...el.split(':').slice(1).join(':').split(',')]
        }
        return;
      }
      if(isDate){
        let arr = el.split(':').slice(1).join(':').split(',');
        if(arr.length===1){
          if(['from_today'].includes(arr[0])){  // to handle old filter str
            arr = ['from_today','[* TO *]']
          }else{
            arr = ['from_today',arr[0]]
          }
        }
        status[el.split(':')[0]] = {
          selected:[...arr]
        }
        return;
      }
      if(el[0]==='-'){
        el = el.slice(1);
      }
      status[el.split(':')[0]] = {
        selected:el.split(':')[1].split(',').map(el=>el!=='NO' && el.split(' ')[0]==='NO'?null:el.toUpperCase())
      }
    })
    return status
  }

  const getPresetFilterbyStr = str => {
    return str.split(' AND ').map(el=>{
      let field = el.split(':')[0];
      if(field[0]==='-')field = field.slice(1);
      return field
    })
  }

  const loadColumnsAndFilters = () => {
    dispatch({type:'set_all_filters',allFiltersLoading:true,allFilters:[]})
    axiosSolr
      .get(
        `/solr/${indexName}/admin/luke`,
        {params:{
          wt:'json'
        }}
      )
      .then(response=>{
        let columns = [];
        let filters = []
        let ignoredRexList = [/.*_txt(s*)$/,/.*_engram(s*)$/,/^_.*_$/];
        let ignoredStringList = ['id','relevance_weight','source_id','name','description','parent_id_srt','trust_srt','issue_severity_srt','code']

        let fields = response.data.fields;
        if(!fields)return;
        Object.keys(fields).forEach(f=>{
          if(!ignoredRexList.some(r=>f.match(r)) && !ignoredStringList.some(s=>f===s)){
            // add columns
            if(!columns.includes(f))columns.push(f)
            // add filters
            if(!filters.find(el=>el.field===f)){
              if(f==='trust')filters.push({field:'trust',name:'Trust',type:'istring'})
              if(f==='issue_severity')filters.push({field:'issue_severity',name:'Issue Severity',type:'istring'})
              if(['istring','istrings','pdate','pint','plong'].includes(fields[f].type) && !['trust','issue_severity'].includes(f) ){
                filters.push({field:f,name:toTitleCase(processFieldName(f)),type:fields[f].type})
              }
            }
          }
        })
        dispatch({type:'set_all_filters',allFilters:filters.sort((a,b)=>a.name<b.name?-1:1)})
        dispatch({type:'set_all_columns',allColumns:columns})
      })
      .catch(error=>{
        dispatch({type:'set_all_filters',allFilters:[]})
        console.log(error)
      })
  }


  useEffect(()=>{
    if(state.allFilters.length===0){
      loadColumnsAndFilters();
    }
  // eslint-disable-next-line
  },[])


  const loadAvailableFilters = ({
    searchStr=state.mainSearchQuery, 
    fq,
    searchMode=state.searchMode
  }) => {
    dispatch({type:'set_available_filters',availableFilters:state.availableFilters,availableFiltersLoading:true})
    // get facet count for each filter, used to remove empty filters
    let filterFacet = {};
    state.allFilters.forEach(el=>{
      filterFacet[el.field] = {
        "type": "terms",
        "field": el.field,
        // "mincount": 1,
        "limit": 1
      }
    })

    filterFacet = JSON.stringify(filterFacet)

    axiosSolr
      .post(
        `/solr/${indexName}/select`,{params:{
          q:getSearchQuery({queryInput:searchStr, searchMode}),
          fq:fq,
          rows:0,
          'json.facet':filterFacet
        }}
      )
      .then(response=>{
        if(response.data.facets){
          let availableFilters = [];
          state.allFilters.forEach(el=>{
            if(response.data.facets[el.field] && response.data.facets[el.field].buckets.length>0){
              availableFilters.push(el.field)
            }
          });
          dispatch({
            type:'set_available_filters',
            availableFilters
          })
        }
      })
      .catch(error=>{
        console.log(error)
      })
  }

  useEffect(()=>{
    if(currentParam && state.allFilters.length>0){
      loadAvailableFilters({searchStr:currentParam.searchStr, fq:currentParam.fq, searchMode:state.searchMode})
      setCurrentParam()
    }
  // eslint-disable-next-line
  },[state.allFilters, currentParam, state.searchMode])



  useEffect(()=>{
    let searchFilters = getSearchFilterList({filterStatus:state.filterStatus, negativeFilters:state.negativeFilters});
    if(searchFilters.length>0){
      dispatch({
        type:'set_main_search_preset_filter_srt',mainSearchPresetFilterStr:searchFilters.join(' AND ')
      })
    }
    let newUrl = generateUrl({
      mainSearchQuery:state.mainSearchQuery,
      searchFilters,
      selectedObjectType:state.selectedObjectType,
      defaultColumns:getDefaultColumn({objectType:state.selectedObjectType,tabs:state.searchTabs}),
      selectedColumns:state.selectedColumns,
      searchMode:state.searchMode,
      andFilters:state.andFilters,
    }).newUrl;

    if(state.view!=='landing' && state.selectedObjectType && !isNotSearchPage && isInitiated && !state.isSavedFilter){
      if(window.location.search===''){
        history.push(newUrl)
      }else{
        window.history.replaceState(null, null, newUrl);
      }
    }

    if(customID){
      const onMsgReceived = (msg) => {
        if(msg.data[`${customID}_open_search`] ){
          window.open(newUrl, '_blank')
        }
      }
      window.removeEventListener('message',globalListenerRef[`search_${customID}_open_listener`]);
      globalListenerRef[`search_${customID}_open_listener`] = onMsgReceived;
      window.addEventListener("message", globalListenerRef[`search_${customID}_open_listener`]);
    }
   
    return (()=>{window.removeEventListener('message',globalListenerRef[`search_${customID}_open_listener`]);})
    
  // eslint-disable-next-line
  },[state.filterStatus, state.selectedObjectType, state.mainSearchQuery, state.selectedColumns, isInitiated, state.searchMode, state.andFilters])
  
  const loadSearchResults = ({ 
    page, 
    isReload, 
    objectType=state.selectedObjectType, 
    searchStr=state.mainSearchQuery, 
    filters=state.mainSearchFilters, 
    andFilters=state.andFilters,
    sort=state.mainSearchSort,
    tabs=state.searchTabs,
    searchMode=state.searchMode,
    presetFilter,
    isFromLandingPage=false,
    isAddToCart,
    isGenerateParamOnly,
    onLoaded,
  }) => {
    let isCollection = tabs && tabs.find(el=>el.objectType===objectType).isCollection;
    let isCollectionType = tabs && tabs.find(el=>el.objectType===objectType).isCollectionType;
    let isCodeType = tabs && tabs.find(el=>el.objectType===objectType).isCodeType;
    let collectionType;
    if(isCollection){
      collectionType = tabs && tabs.find(el=>el.objectType===objectType).collectionType;
    }
    if(isCollectionType){
      collectionType = tabs && processObjectType(tabs.find(el=>el.objectType===objectType).objectType);
    }
    
    let defaultFilter = defaultFilterMap[isCollection?'COLLECTION_INSTANCE':isCollectionType?'COLLECTION':objectType]||[];
    if(presetFilter)dispatch({type:'set_selected_filters',selectedFilters:[...defaultFilter,...presetFilter.filter(el=>!defaultFilter.includes(el))]})
    if(propSelectedFilters)dispatch({type:'set_selected_filters',selectedFilters:propSelectedFilters.split(',')})
    if(!isAddToCart){
      if(objectType!==state.selectedObjectType || isFromLandingPage){
        dispatch({type:'set_cart_items',cartItems:[]}) // clear cart when tab changes
        dispatch({type:'set_is_select_all',isSelectAll:false})
        if(!presetFilter)dispatch({type:'set_selected_filters',selectedFilters:defaultFilter})
        if(propSelectedFilters)dispatch({type:'set_selected_filters',selectedFilters:propSelectedFilters.split(',')})
      }
      dispatch({
        type:'set_search_results',
        searchResults: isReload?
                        {[page]:{loading:true}}:
                        {...state.searchResults,[page]:{loading:true}}
      })
    }
    let fq = '';
    if(indexName==='search'){
      fq =`object_type_srt:${processObjectType(objectType)}`;
      if(objectType==='ALL'){
        fq = `object_type_srt:*`
      }
      if(isCollection){
        fq = `collection_srt:"${processObjectType(objectType)}" AND collection_type_srt:"${collectionType}"  AND object_type_srt:COLLECTION_INSTANCE`
      }
      if(isCollectionType){
        fq = `collection_type_srt:"${collectionType}"  AND object_type_srt:COLLECTION`
      }
      if(isCodeType){
        fq = `code_type_srt:"${objectType}"  AND object_type_srt:CODE`
      }
      if(page===1 && !isAddToCart){
        setCurrentParam({searchStr, fq})
      }
      fq += ' AND -object_type_srt:(HOST OR GENERAL_EVENT)';
      if(filters?.length>0)fq += ` AND ${filters.join(' AND ')}`;
    }else{
      let fs = [...(filters||[])];
      if(forceGlobalFilter)fs.push(...getFilterByStr(forceGlobalFilter,true,andFilters))
      fq = fs?.length>0?fs.join(' AND '):'';
    }

    if(isGenerateParamOnly){
      return {
        q: getSearchQuery({queryInput:searchStr, searchMode}),
        fq
      }
    }
    let finalPerPage = isAddToCart?500:perPage;
    
    axiosSolr
      .get(
        `/solr/${indexName}/select`,{
          params:{
            q: getSearchQuery({queryInput:searchStr, searchMode}),
            fq,
            fl:isAddToCart?'id':undefined,
            sort:sort===''?undefined:sort,
            ...defaultParams,
            ...getSearchParams({queryInput:searchStr, searchMode}),
            rows:finalPerPage,
            start:(page-1)*finalPerPage
          }}
      )
      .then(response=>{
        if(isAddToCart){
          state.cartItems.push(...response.data.response.docs.map(el=>el.id).filter(id=>!state.cartItems.includes(id)))
          if(response.data.response.start+response.data.response.docs.length<response.data.response.numFound){
            if(state.cartItems.length>addToCartLimit){
              sendAlert({message:`A limit of ${formatNumber(addToCartLimit)} can be selected at a time. The first ${formatNumber(addToCartLimit)} ${toTitleCase(processObjectType(state.selectedObjectType))} has been selected.`,type:'info'})
              onLoaded && onLoaded()
              dispatch({type:'set_cart_items',cartItems:[...state.cartItems].slice(0,addToCartLimit)})
              dispatch({type:'set_is_select_all',isSelectAll:true})
            }else{
              loadSearchResults({
                page:page+1,
                isAddToCart:true,
                onLoaded
              })
            }
          }else{
            onLoaded && onLoaded()
            dispatch({type:'set_cart_items',cartItems:[...state.cartItems]})
            dispatch({type:'set_is_select_all',isSelectAll:true})
          }
          return;
        }

        onLoaded && onLoaded()
        let data = {data:response.data.response.docs,highlights:response.data.highlighting}
        dispatch({
          type:'set_search_results',
          searchResults:isReload?
                        {[page]:data}:
                        {...state.searchResults,[page]:data}
        })
        dispatch({
          type:'set_results_total',resultsTotal:response.data.response.numFound
        })
        if(!isNotSearchPage){
          addHistory({
            url:window.location.pathname+window.location.search+'&isReload=true', 
            iconLabel:'search', 
            title: searchStr, 
            subTitle:`${toTitleCase(objectType.replace(/_/g,' '))} Search Results, ${filters.length} filters applied`,
            type:'application',
          })
        }
        setIsInitiated(true)
      })
      .catch(error=>{
        onLoaded && onLoaded()
        console.log(error)
        if(isAddToCart){
          sendAlert({message:'Error occurred selecting items',type:'error'})
          return;
        }
        dispatch({
          type:'set_search_results',
          searchResults:isReload?
                        {[page]:{error:true}}:
                        {...state.searchResults,[page]:{error:true}}
        })
        setIsInitiated(true)
      })
  }

  const resetSearchResultStatus = ({keepCount}) => {
    dispatch({type:'set_page_num',pageNum:1})
    dispatch({type:'set_search_results',searchResults:{}})
    if(!keepCount)dispatch({type:'set_results_total',resultsTotal:0})
  }

  const pushToSearchHistory = ({searchStr, numFound=0}) => {
    if(indexName!=='search')return;
    if(numFound===0)return;
    axiosCerebrum
      .post(
        '/api/me/searches',
        {terms:searchStr,results:numFound}
      )
      .catch(error=>{
        console.log(error)
      })
  }


  const onSearch = async ({
    searchStr=state.mainSearchQuery, 
    objectType=state.selectedObjectType, 
    ignoreDefault=false, 
    presetFilterStr, 
    andFilters=state.andFilters,
    isFromLandingPage=false,
    sort,
    searchMode=state.searchMode
  }) => {
    if(state.isSavedFilter.solr_query?.q && state.isSavedFilter.solr_query?.q!==searchStr){
      dispatch({type:'set_is_filter_or_column_changed',isFilterOrColumnChanged:true})
    }
    dispatch({type:'set_tab_grouping',tabGrouping:generateRawTabGrouping()})
    let tempGrouping = generateRawTabGrouping()
    // update the search query, so we don't loose the query txt of this search, need to use it when tab changes etc
    dispatch({
      type:'set_main_search_query',
      mainSearchQuery:searchStr
    })

    resetSearchResultStatus({});

    let tabs = [];
    dispatch({
      type:'set_search_tabs',searchTabsLoading:true
    });

    let fq, facet;
    if(indexName==='search'){
      fq = [
        objectType==='COLLECTION_INSTANCE'?'object_type_srt:COLLECTION_INSTANCE AND -collection_type_srt:(AUTOMATED OR CHANGE OR KNOWLEDGE OR GLOSSARY)':undefined,
        objectType==='TERM'?'object_type_srt:COLLECTION_INSTANCE AND collection_type_srt:GLOSSARY':undefined,
        // 'active_srt:YES',
        '-object_type_srt:(HOST OR GENERAL_EVENT)'
      ].filter(el=>el).join(' AND ');

      facet = {
        "objects": {
          "type": "terms",
          "field": "object_type_srt",
          "mincount":1,
          limit:30
        },
        'codes':{
          "type":"query",
          'q':'object_type_srt:CODE',
          'facet':{
            "code_types": {
              "type": "terms",
              "field": "code_type_srt",
              "mincount":1,
              limit:100,
            }
          }
        },
        'collections':{
          "type":"query",
          'q':'object_type_srt:COLLECTION',
          'facet':{
            "collection_types": {
              "type": "terms",
              "field": "collection_type_srt",
              "mincount":1,
              limit:100,
            }
          }
        },
        'collection_instances':{
          "type":"query",
          'q':'object_type_srt:COLLECTION_INSTANCE',
          'facet':{
            "collection_types": {
              "type": "terms",
              "field": "collection_type_srt",
              "mincount":1,
              limit:100,
              'facet':{
                "collections":{
                  "type": "terms",
                  "field": "collection_srt",
                  "mincount":1,
                  limit:100,
                }
              }
            },
          }
        }
       }
    }

    await axiosSolr
      .get(
        `/solr/${indexName}/select`,{params:{
          q: getSearchQuery({queryInput:searchStr, searchMode:searchMode}),
          fq,
          ...defaultParams,
          ...getSearchParams({queryInput:searchStr, searchMode}),
          rows:0,
          'json.facet':facet,
        }}
      )
      .then(response=>{
        if(response.data.response.numFound!==0 && !state.selectedObjectChip){
          tabs.push({
            displayName:`ALL`,
            count:response.data.response.numFound,
            objectType:'ALL'
          })
        }
        dispatch({type:'set_selected_object_chip',selectedObjectChip:undefined})
        if(response.data.facets && response.data.facets.objects){
          response.data.facets.objects.buckets.forEach((el,index)=>{
            let name = el.val;
            let count = el.count
            if(name==='COLLECTION_INSTANCE')return;
            if(name==='COLLECTION')return;
            if(name==='CODE')return; ///// to split code types
            if(name==='DATA_PIPELINE')name='PIPELINE'
            if(name==='DATA_QUALITY_TEST')name='DQ TESTS'
            if(name==='CONTENT_APP')name='CONTENT APP'
            if(name==='DATASET_TABLE')name='DATASET TABLE'
            if(name==='DATASET_FIELD')name='DATASET FIELD'
            if(name==='ML_MODEL')name='ML MODEL'
            tabs.push({
              displayName:`${name}`,
              count:count,
              objectType:el.val,
            })
          })
        }
        const sortCollectionTypes = (a, b) => {
          let getOrder = type => {
            switch (type){
              case 'GLOSSARY':
                return 1;
              case 'KNOWLEDGE':
                return 2;
              case 'LIST':
                return 3;
              case 'DATA_MANAGEMENT':
                return 4;
              case 'DATA_GOVERNANCE':
                return 5;
              case 'COLLABORATION':
                return 6;
              case 'PLATFORM':
                return 7;
              default:
                return 8
            }
          }
          return getOrder(a.val) - getOrder(b.val)
        }
        if(response.data.facets && response.data.facets.codes && response.data.facets.codes.code_types){
          response.data.facets.codes.code_types.buckets.forEach((el,index)=>{
            let codeTypeName = el.val.replace(/_/g,' ');
            let objectType = el.val;
            tabs.push({
              displayName:codeTypeName,
              objectType:objectType,
              count:el.count,
              isCodeType:true,
            })
          })
        }

        if(response.data.facets && response.data.facets.collections && response.data.facets.collections.collection_types){
          response.data.facets.collections.collection_types.buckets.sort(sortCollectionTypes).forEach((el,index)=>{
            let collectionTypeName = el.val.replace(/_/g,' ');
            if(collectionTypeName==='GLOSSARY')collectionTypeName='TERM'
            let objectType = getCollectionTypeObjectName({collectionType:el.val})
            tabs.push({
              displayName:collectionTypeName,
              collectionType:el.val,
              objectType:objectType,
              count:el.count,
              isCollectionType:true,
            })
            tempGrouping.find(g=>g.name==='COLLECTIONS').tabs.push(objectType)
          })
        }
        if(response.data.facets && response.data.facets.collection_instances && response.data.facets.collection_instances.collection_types){
          response.data.facets.collection_instances.collection_types.buckets.sort(sortCollectionTypes).reverse().forEach((el,index)=>{
            let collectionTypeName = el.val.replace(/_/g,' ');
            if(collectionTypeName==='GLOSSARY')collectionTypeName='TERM'
            el.collections.buckets.forEach(c=>{
              let collectionName = c.val.replace(/_/g,' ');
              let count = c.count
              let objectType = getCollectionObjectName({collectionName:c.val,collectionType:el.val});
              tabs.push({
                displayName:`${collectionName}`,
                objectType:objectType,
                count:count,
                isCollection:true,
                collectionType:el.val,
              })
              if(!tempGrouping.find(g=>g.name===collectionTypeName)){
                tempGrouping.splice(4, 0, {name:collectionTypeName, tabs:[]});
              }
              tempGrouping.find(g=>g.name===collectionTypeName).tabs.push(objectType)
            })
          })
        }

        dispatch({type:'set_tab_grouping',tabGrouping:tempGrouping})
        pushToSearchHistory({searchStr,numFound:response.data.response.numFound});

        const tabOrdering = [];
        tempGrouping.forEach(el=>{
          tabOrdering.push(...el.tabs)
        })
        const getOrder = tab => {
          return tabOrdering.indexOf(tab.objectType)
        }

        tabs = tabs.sort((a,b)=>getOrder(a)-getOrder(b))
        
        dispatch({
          type:'set_search_tabs',searchTabs:tabs
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_search_tabs',searchTabsError:true
        })
      })
      
    if(tabs.length===0){
      setIsInitiated(true)
      return;
    }
    let activeTab = objectType;
    let objectTypeNotAvailable = !tabs.map(el=>el.objectType).includes(activeTab) && indexName==='search'
    if(!activeTab || objectTypeNotAvailable){
      activeTab = tabs.map(el=>el.objectType)[0];
      dispatch({
        type:'set_selected_object_type',selectedObjectType:activeTab
      })
    }
    
    if(isFromLandingPage){
      dispatch({type:'set_selected_columns',selectedColumns: getDefaultColumn({objectType:activeTab, tabs})}) 
    }

    let shouldKeepFilter = activeTab===state.selectedObjectType && !isFromLandingPage && !presetFilterStr;

    let filters = presetFilterStr && !objectTypeNotAvailable?getFilterByStr(presetFilterStr,undefined,andFilters):(ignoreDefault?[]:getDefaultFilter(activeTab))

    if(!shouldKeepFilter){
      dispatch({
        type:'set_filter_status',
        filterStatus:presetFilterStr && !objectTypeNotAvailable?getFilterStatusByStr(presetFilterStr):(ignoreDefault?{}:getDefaultFilterStatus(activeTab))
      })
      dispatch({
        type:'set_main_search_filters',
        mainSearchFilters: filters
      })
    }
    
    let presetFilter = presetFilterStr && !objectTypeNotAvailable?getPresetFilterbyStr(presetFilterStr):undefined

    loadSearchResults({
      objectType:activeTab, 
      page:1, 
      isReload:true, 
      searchStr,
      filters:shouldKeepFilter?undefined:filters, 
      // filters,
      tabs, 
      presetFilter:shouldKeepFilter?undefined:presetFilter,
      searchMode,
      // presetFilter:presetFilter,
      isFromLandingPage,
      sort
    })
  }

  const onChangeTab = objectType => {
    dispatch({type:'set_is_filter_or_column_changed',isFilterOrColumnChanged:true})
    loadSearchResults({objectType, page:1, isReload:true, filters:getDefaultFilter(objectType)})
    dispatch({type:'set_selected_columns',selectedColumns: getDefaultColumn({objectType, tabs:state.searchTabs})}) 
    dispatch({type:'set_selected_object_type',selectedObjectType:objectType})
    dispatch({type:'set_main_search_filters',mainSearchFilters:getDefaultFilter(objectType)});
    dispatch({type:'set_filter_status',filterStatus:getDefaultFilterStatus(objectType)})
    dispatch({type:'set_page_num',pageNum:1});
    window.scrollTo({top:0,left:0,behavior:'smooth'})
  }

  const onChangePage = pageNum => {
    loadSearchResults({ page:pageNum, isReload:false });
    dispatch({type:'set_page_num',pageNum});
    if(isNotSearchPage)return;
    window.scrollTo({top:0,left:0,behavior:'smooth'})
  }

  const onUpdateSort = field => {
    let newSortValue = '';
    // if(field==='trust_srt' && !state.mainSearchSort.includes(field)){
    //   newSortValue = `${field} desc`
    // }
    // else{
      if(state.mainSearchSort===`${field} asc`){
        newSortValue = `${field} desc`
      }else if(state.mainSearchSort===`${field} desc`){
        newSortValue = ''
      }else{
        newSortValue = `${field} asc`
      }
    // }
    resetSearchResultStatus({keepCount:true});
    loadSearchResults({page:1, isReload:true, keepCount:true ,sort:newSortValue})
    dispatch({type:'set_main_search_sort',mainSearchSort:newSortValue});
  }
  
  const onUpdateFilter = ({updatedFieldName, updatedSelected, negativeFilters=state.negativeFilters, andFilters=state.andFilters, onFinish, forcedAttributes}) => {
    dispatch({type:'set_is_filter_or_column_changed',isFilterOrColumnChanged:true})
    dispatch({type:'set_cart_items',cartItems:[]})
    dispatch({type:'set_is_select_all',isSelectAll:false})

    let concationWord = andFilters.includes(updatedFieldName)?' AND ':' OR ';

    let newFilterStatus = {
      ...state.filterStatus,
      [updatedFieldName]:{
        selected:updatedSelected,
        type:state.allFilters.find(f=>f.field===updatedFieldName)?.type
      }
    };
    
    let searchFilters = [];
    Object.keys(newFilterStatus).forEach(fieldName=>{
      if(
        newFilterStatus[fieldName] &&
        newFilterStatus[fieldName].selected.length!==0
      ){
        let type = newFilterStatus[fieldName]?.type || state.allFilters.find(f=>f.field===fieldName)?.type;
        let isRange = rangeFilterFileds.includes(fieldName);
        if(isRange){
          let selected = newFilterStatus[fieldName].selected.filter(el=>el!==null);
          let rangeType = selected[0]?.toLowerCase()
          if(rangeType==='between'){
            searchFilters.push(
              `${fieldName}:[${selected[1]} TO ${selected[2]}]`
            )
          }
          if(!isNaN(selected[1])){
            if(rangeType==='greater'){
              searchFilters.push(
                `${fieldName}:[${selected[1]+1} TO *]`
              )
            }
            if(rangeType==='greater or equal to'){
              searchFilters.push(
                `${fieldName}:[${selected[1]} TO *]`
              )
            }
            if(rangeType==='less'){
              searchFilters.push(
                `${fieldName}:[* TO ${selected[1]-1}]`
              )
            }
            if(rangeType==='less or equal to'){
              searchFilters.push(
                `${fieldName}:[* TO ${selected[1]}]`
              )
            }
          }
        }
        else if(['pdate'].includes(type)){ // don't need quotes for date data type
          if(newFilterStatus[fieldName].selected[1]!=='*'){
            searchFilters.push(
              `${fieldName}:(${newFilterStatus[fieldName].selected[1]})`
            )
          }
        }
        else{
          let str = ''
          if(negativeFilters.includes(fieldName)){
            let positiveSelect = newFilterStatus[fieldName].selected.filter(el=>el!==null);
            if(positiveSelect.length>0){
              if(['pint','plong'].includes(type)){
                str = `-${fieldName}:(${positiveSelect.map(el=>`${el}`).join(concationWord)})`
              }else{
                str = `-${fieldName}:(${positiveSelect.map(el=>`"${el}"`).join(concationWord)})`
              }
            }
            if(newFilterStatus[fieldName].selected.some(el=>el===null)){
              if(positiveSelect.length>0){
                str = `(${str} AND (${fieldName}:[* TO *]))`
              }else{
                str = `${fieldName}:[* TO *]`
              }
            }
          }else{
            let positiveSelect = newFilterStatus[fieldName].selected.filter(el=>el!==null);
            if(positiveSelect.length>0){
              if(['pint','plong'].includes(type)){
                str = `${fieldName}:(${positiveSelect.map(el=>`${el}`).join(concationWord)})`
              }else{
                str = `${fieldName}:(${positiveSelect.map(el=>`"${el}"`).join(concationWord)})`
              }
            }
            if(newFilterStatus[fieldName].selected.some(el=>el===null)){
              if(positiveSelect.length>0){
                str = `(${str} OR (*:* -${fieldName}:[* TO *]))`
              }else{
                str = `-${fieldName}:[* TO *]`
              }
            }
          }
          searchFilters.push(str)
        }
      }
    })
    
    Object.keys(newFilterStatus).forEach(el=>{
      if(!newFilterStatus[el])return;
      if(el!==updatedFieldName){
        newFilterStatus[el] = {selected:newFilterStatus[el].selected,type: newFilterStatus[el]?.type || state.allFilters.find(f=>f.field===el)?.type }
      }else{
        newFilterStatus[el] = {...state.filterStatus[el],...(forcedAttributes||{}),selected:newFilterStatus[el].selected}
      }
    })
    dispatch({type:'set_filter_status',filterStatus:newFilterStatus})
    dispatch({type:'set_and_filters',andFilters})
    onFinish && onFinish({filters:searchFilters})
    if(JSON.stringify(state.mainSearchFilters) === JSON.stringify(searchFilters))return;
    resetSearchResultStatus({});
    dispatch({type:'set_main_search_filters',mainSearchFilters:searchFilters});
    loadSearchResults({page:1, isReload:true, filters:searchFilters, andFilters})
  }

  const onResetAllFilters = () => {
    dispatch({type:'set_is_filter_or_column_changed',isFilterOrColumnChanged:true})
    if(propFilter){
      dispatch({type:'set_main_search_filters',mainSearchFilters:getFilterByStr(propFilter)})
      dispatch({type:'set_filter_status',filterStatus:getFilterStatusByStr(propFilter)})
      loadSearchResults({page:1, isReload:true, filters:getFilterByStr(propFilter)})
    }else{
      dispatch({type:'set_main_search_filters',mainSearchFilters:getDefaultFilter(state.selectedObjectType)});
      dispatch({type:'set_filter_status',filterStatus:getDefaultFilterStatus(state.selectedObjectType)})
      let isCollection = state.searchTabs && state.searchTabs.find(el=>el.objectType===state.selectedObjectType).isCollection;
      dispatch({type:'set_selected_filters',selectedFilters:defaultFilterMap[isCollection?'COLLECTION_INSTANCE':state.selectedObjectType]||[]})
      loadSearchResults({page:1, isReload:true, filters:getDefaultFilter(state.selectedObjectType)})
    }
  }

  useEffect(()=>{
    if(propColumn){
      dispatch({
        type:'set_selected_columns',selectedColumns:propColumn.split(',')
      })
    }
  },[propColumn])

  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.resetSearchListener)
    globalListenerRef.resetSearchListener = event=>{
      if(event.data.resetSearch){
        dispatch({type:'set_view',view:'landing'});
        dispatch({
          type:'set_search_value',searchValue:''
        })
        dispatch({
          type:'set_main_search_sort',mainSearchSort:''
        })
        dispatch({
          type:'set_selected_object_type',
          selectedObjectType:undefined
        })  
        dispatch({
          type:'set_selected_object_chip'
        })  
        dispatch({type:'set_is_saved_filter',isSavedFilter:false})
      }
    }
    window.addEventListener("message", globalListenerRef.resetSearchListener);
    // eslint-disable-next-line
  },[])

  const initialiseSearch = async () => {
    const urlSearch = new URLSearchParams(window.location.search);
    let query =  propQuery || urlSearch.get('query')
    let objectType = propObjectType || urlSearch.get('object')
    let presetFilter = propFilter || urlSearch.get('presetFilter')
    let presetColumn = propColumn || urlSearch.get('presetColumn')
    let filterListId = propFilterlistId || urlSearch.get('filterListId')
    let andFilters = propAndFilters || urlSearch.get('andFilters') || ''
    let presetSort = propSort || urlSearch.get('sort')
    let presetSearchMode = urlSearch.get('searchMode')?.split(',');

    if(filterListId){
      dispatch({
        type:'set_view',view:'main_search'
      })
      dispatch({type:'set_is_saved_filter',isSavedFilter:{id:filterListId}})
      await axiosCerebrum.get(
        `/api/filteredlists/${filterListId}`
      )
      .then(response=>{
        dispatch({type:'set_is_saved_filter',isSavedFilter:response.data})
        query = response.data.solr_query.q;
        presetFilter = decodeURIComponent(response.data.solr_query.fq);
        objectType = response.data.solr_query.object_type;
        presetColumn = response.data.solr_query.columns;
        andFilters = response.data.solr_query.and_filters || '';
        presetSearchMode = response.data.solr_query.search_mode;
        if(!response.data.solr_query.fq_raw || !response.data.solr_query.q_raw){
          dispatch({type:'set_is_filter_or_column_changed',isFilterOrColumnChanged:true})
        }
        if(presetFilter){
          dispatch({
            type:'set_selected_filters',selectedFilters:getPresetFilterbyStr(presetFilter)
          })
        }
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_is_saved_filter',isSavedFilter:false})
        dispatch({
          type:'set_view',view:'landing'
        })
        window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['filterListId']}));
        sendAlert({message:'Failed to load saved filter',type:'error'})
      })
    }else{
      dispatch({type:'set_is_saved_filter',isSavedFilter:false})
    }

    // to prevent firing search when cached
    if(
      query && 
      (
        state.mainSearchQuery!==query || 
        (objectType && state.selectedObjectType!==objectType) || 
        (presetFilter && decodeURIComponent(presetFilter).toLowerCase()!==decodeURIComponent(state.mainSearchPresetFilterStr).toLowerCase())
      )
    ){
      objectType = objectType?.toUpperCase()
      dispatch({
        type:'set_view',view:'main_search'
      })
      dispatch({
        type:'set_search_value',searchValue:query
      })
      dispatch({
        type:'set_main_search_query',mainSearchQuery:query
      })
      if(presetColumn){
        dispatch({
          type:'set_selected_columns',selectedColumns:presetColumn.split(',')
        })
      }else{
        dispatch({
          type:'set_selected_columns',selectedColumns:getDefaultColumn({objectType,tabs:state.searchTabs})
        })
      }
      if(objectType){
        dispatch({
          type:'set_selected_object_type',selectedObjectType:objectType
        })
      }
      if(presetFilter){
        dispatch({
          type:'set_main_search_preset_filter_srt',mainSearchPresetFilterStr:presetFilter?decodeURIComponent(presetFilter):undefined
        })
      }
      if(andFilters){
        dispatch({
          type:'set_and_filters',andFilters:andFilters.split(',')
        })
      }
      if(presetSearchMode){
        dispatch({
          type:'set_search_mode',searchMode:presetSearchMode
        })
      }
      if(presetSort){
        dispatch({
          type:'set_main_search_sort',mainSearchSort:presetSort||''
        })
      }
      setTimeout(()=>onSearch({searchStr:query,sort:presetSort,objectType:objectType, searchMode:presetSearchMode, andFilters, presetFilterStr:presetFilter?decodeURIComponent(presetFilter):undefined}))
    }else{
      setIsInitiated(true)
    }
  }

  // automatically fire search if query provided in url
  useEffect(()=>{
    if(state.allFilters.length===0)return;
    initialiseSearch()
  // eslint-disable-next-line
  },[state.allFilters])

  useEffect(()=>{
    if(isCreateFilter){
      dispatch({type:'set_view',view:'main_search'});
    }
  },[isCreateFilter])
  
  return (
    <Body 
      sessionData={sessionData}
      state={state} 
      dispatch={dispatch} 
      isNotSearchPage={isNotSearchPage}
      history={history}
      onSearch={onSearch}
      getDefaultParams={getDefaultParams}
      onChangeTab={onChangeTab}
      onChangePage={onChangePage}
      onUpdateFilter={onUpdateFilter}
      onUpdateSort={onUpdateSort}
      onResetAllFilters={onResetAllFilters}
      loadSearchResults={loadSearchResults}
      tabGrouping={state.tabGrouping}
      removeContainerStyle={removeContainerStyle}
      hiddenComponents={hiddenComponents}
      addtioonalComponents={addtioonalComponents}
      customHeaderFormatter={customHeaderFormatter}
      resultItemVariant={resultItemVariant}
      alwaysOpenNewTab={alwaysOpenNewTab}
      isCreateFilter={isCreateFilter}
      indexName={indexName}
      customEmptyMsg={customEmptyMsg}
      forceGlobalFilterStr={forceGlobalFilter?getFilterByStr(forceGlobalFilter, true).join(' AND '):undefined}
      forceOnItemClick={forceOnItemClick}
    />
  )
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

BasicSearch.propTypes = {
  customID: PropTypes.string,
  pageCache: PropTypes.object,
  storePageCache: PropTypes.func,
  propQuery: PropTypes.string,
  propFilter: PropTypes.string,
  propObjectType: PropTypes.string,
  propColumn: PropTypes.string,
  propSort: PropTypes.string,
  propAndFilters: PropTypes.array,
  propFilterlistId: PropTypes.string,
  propSelectedFilters: PropTypes.string,
  propCache: PropTypes.object,
  propStoreCache: PropTypes.func,
  history: PropTypes.object,
  initialView: PropTypes.oneOf(['landing','main_search']),
  removeContainerStyle: PropTypes.bool,
  hiddenComponents: PropTypes.arrayOf(
    PropTypes.oneOf(
      ['header', 'searchBar', 'tab', 'filter','filterEdit','filterReset','resultHeader','cartButton','downloadButton','columnSelector']
    )
  ),
  addtioonalComponents: PropTypes.arrayOf(
    PropTypes.oneOf(
      ['filterHide']
    )
  ),
  resultItemVariant:PropTypes.oneOf(['default','simplified']),
  customHeaderFormatter: PropTypes.func,
  alwaysOpenNewTab: PropTypes.bool,
  isCreateFilter: PropTypes.bool,
  indexName: PropTypes.string,
  customEmptyMsg: PropTypes.string,
  forceOnItemClick: PropTypes.func,
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BasicSearch));
