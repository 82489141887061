import React, { useEffect, useRef } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, Select, MenuItem} from '@material-ui/core';
import { formatNumber, getIconComponent, isInViewport, toTitleCase } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum'
import moment from 'moment';
import KTooltip from '../../UI/KTooltip/KTooltip';
import { generateScheduleText } from '../../OnBoardSource/utils';

const styles = theme => ({
  listItem:{
    display:'flex',
    alignItems:'center',
    height:56,
    overflow:'hidden',
    cursor:'pointer',
    borderBottom:`${theme.palette.listItemDivider.main} 1px solid `,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  listItemText:{
    color:theme.palette.primaryText.main,
    fontSize:13.75,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    width:'max-content',
    maxWidth:'100%'
  },
  columnHeader:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.primaryText.main
  },
  selector:{
    ...theme.components.selector,
    width:180
  }
})

const Platform = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    history
  } = props;

  const scrollRef = useRef()

  const loadSources = ({page=1, sort=state.sourceLoadSort}) => {
    dispatch({
      type:'set_platform_sources',
      platformSourcesLoading:true,
      platformSources:state.platformSources
    });
    axiosCerebrum 
      .get(
        `/api/sources`,
        {params:{
          per_page:20,
          page,
          types:'DATABASE,TOOL,INTERNAL,IDENTITY_STORE',
          internal_flag:false,
          active_flag:true,
          sort:sort
        }}
      )
      .then(response=>{
        dispatch({
          type:"set_platform_sources",
          platformSources:page===1?response.data:{...response.data,items:[...state.platformSources.items,...response.data.items]}
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_platform_sources',
          platformSourcesError:true,
        });
      })
  }
  
  useEffect(()=>{
    if(!state.platformSources && !state.platformSourcesLoading){
      loadSources({})
    }
  // eslint-disable-next-line
  },[] )

  const shouldLoadMore = () => {
    return isInViewport(scrollRef) && state.platformSources && state.platformSources.page<state.platformSources.pages && !state.platformSourcesLoading
  }

  window.onscroll = () => {
    if(shouldLoadMore())loadSources({page:state.platformSources.page+1})
  }

  useEffect(()=>{
    if(shouldLoadMore())loadSources({page:state.platformSources.page+1})
  // eslint-disable-next-line
  },[state.platformSources,state.platformSourcesLoading])

  const getLoadSetting = el => {
    if(el.scheduled_flag===false){
      if(el.job_mode==='LOAD_ONLY')return 'N/A'
      return 'Not scheduled'
    }else{
      return generateScheduleText(el)
      // if(el.job_mode==='EXTRACT_AND_LOAD')return 'Scheduled (Direct connect)'
      // if(el.job_mode==='LOAD_ONLY')return 'Scheduled (Load from file)'
    }
  }

  const getLoadStatusChip = el => {
    if(!el.load_status)return <span className={classes.listItemText}>N/A</span>
    let fontColour = theme.palette.primaryText.main;
    let background = theme.palette.chip.main;
    let borderColour = theme.palette.chipBorder.main;
    let tooltip;
    if(el.load_status==='EXTRACTING'){
      tooltip = 'Metadata extraction in progress'
    }
    if(el.load_status==='EXTRACTED'){
      tooltip = 'Extracted metadata waiting to be loaded into K.'
    }
    if(el.load_status==='LOADING'){
      tooltip = 'Metadata currently being loaded into K'
    }
    if(el.load_status==='FAILED'){
      fontColour = theme.palette.background.main;
      background = theme.palette.error.main;
      borderColour = theme.palette.error.main;
      tooltip = 'Metadata source has failed to extract or load. See Monitor for more details'
    }
    if(el.load_status==='LOADED'){
      fontColour = theme.palette.background.main;
      background = theme.palette.success.main;
      borderColour = theme.palette.success.main;
      tooltip = 'Metadata successfully loaded data into K'
    }
    return (
      <KTooltip title={tooltip}>
        <div style={{height:24,borderRadius:12,display:'flex',alignItems:'center',padding:'0 8px',background,border:`1px solid ${borderColour}`,color:fontColour,width:'max-content',maxWidth:'100%',boxSizing:'border-box',overflow:'hidden',fontSize:13}}>
          {toTitleCase(el.load_status)}
        </div>
      </KTooltip>
      
    )
  }

  const getStatusChip = loadTime => {
    // check if load time is 2 days ago 
    let diff = formatNumber(moment().diff(moment(loadTime?.split('T')?.[0]),'days'),undefined,true)
    return (
      <div style={{height:24,width:'max-content',display:'flex',justifyContent:'center',alignItems:'center',padding:'0px 8px',borderRadius:12,color:theme.palette.primaryText.main,border:`1px solid ${theme.palette.chipBorder.main}`,background:theme.palette.chip.main}}>
        {!loadTime?'N/A':diff+' day(s)'}
      </div>
    )
  }

  if(state.platformSources && state.platformSources.items.length===0){
    return  <Typography style={{color:theme.palette.primaryText.main,fontSize:13.75,marginBottom:24}}>No sources available</Typography>
  }

  return (
    <div className={classes.root}>
      <div style={{display:'flex',paddingBottom:24,alignItems:'center',background:theme.palette.background.main,position:'sticky',top:110}}>
        <div style={{flexGrow:1,marginRight:16}}>
          <Typography style={{color:theme.palette.header.main,fontSize:20,}}>{`${state.platformSources?state.platformSources.total+' ':''}SOURCE(S)`}</Typography>
          <Typography style={{color:theme.palette.primaryText.light,fontSize:13.75}}>Select a source to see more operations</Typography>
        </div>
        <Select
          className={classes.selector}
          value={state.sourceLoadSort}
          data-test-id="source-load-sort"
          onChange={event => {
            loadSources({sort:event.target.value})
            dispatch({type:'set_source_load_sort',sourceLoadSort:event.target.value}) 
          }}
        >
          {
            [
              {dispName:'A-Z',value:'ALPHABETICAL'},
              {dispName:'LAST LOADED AT',value:'LAST_LOADED_AT_DESC'},
            ].map(el => (
              <MenuItem  className={classes.menuItem} value={el.value}>
                {el.dispName}
              </MenuItem>
            ))
          }
        </Select>
      </div>
      {
        state.platformSources && 
        <div style={{display:'flex',marginBottom:16}}>
          <div style={{flex:'0 0 24px',marginLeft:16,marginRight:16}}/>
          <div style={{flex:'1 1 250px',marginRight:16}} className={classes.columnHeader}>NAME</div>
          <div style={{flex:'0 1 250px',marginRight:16}} className={classes.columnHeader}>TYPE</div>
          <div style={{flex:'0 1 160px',marginRight:16}} className={classes.columnHeader}>LOAD TYPE</div>
          <div style={{flex:'0 1 250px',marginRight:16}} className={classes.columnHeader}>LAST LOAD STATUS</div>
          <div style={{flex:'0 1 250px',marginRight:16}} className={classes.columnHeader}>
            LAST SUCCESSFULL LOAD            
            <KTooltip title="Last loaded is captured for Collectors and Extractors only. Sources loaded via API are currently excluded">
              <span style={{position:'relative',bottom:-3,marginLeft:6}}>{getIconComponent({label:'info',size:16,colour:theme.palette.primaryText.light})}</span>
            </KTooltip>
          </div>
          <div style={{flex:'0 1 220px',marginRight:16}} className={classes.columnHeader}>DAYS SINCE LAST LOADED</div>
          <div style={{flex:'0 1 300px'}} className={classes.columnHeader}>SCHEDULE</div>
        </div>
      }
      {
        state.platformSources && state.platformSources.items.map(el=>(
          <div 
            className={classes.listItem}
            onClick={()=>{
              history.push(`/admin/platform_management?tabName=MONITOR&sourceIdFilter=${el.id}`)
            }}
          >
            <div style={{flex:'0 0 24px',marginLeft:16,marginRight:16}}>
              {
                getIconComponent({label:el.source_template.name==='REFERENCE'?'SOURCE':el.source_template.name,size:24,colour:el.source_template.name==='REFERENCE'?theme.palette.primaryText.light:theme.palette.primary.main}) || 
                getIconComponent({label:'source',size:24,colour:theme.palette.primary.main})
              }
            </div>
            <div style={{flex:'1 1 250px', marginRight:16,overflow:'hidden'}}>
              <KTooltip title={el.name}>
                <Typography className={classes.listItemText} style={{color:theme.palette.primaryText.main,flex:'1 0 250px',marginRight:16}}>{el.name}</Typography>
              </KTooltip>
              <KTooltip title={el.host}>
                <Typography className={classes.listItemText} style={{color:theme.palette.primaryText.light,flex:'1 0 250px',marginRight:12}}>{el.host}</Typography>
              </KTooltip>
            </div>
            <KTooltip title={el.source_template.name}>
              <Typography className={classes.listItemText} style={{flex:'0 1 250px',marginRight:16}}>
                {el.source_template.name}
              </Typography>
            </KTooltip>
            <Typography className={classes.listItemText} style={{flex:'0 1 160px',marginRight:16}}>
              {el.job_mode==='LOAD_ONLY'?'Load from File':'Direct connect'}
            </Typography>
            <div style={{flex:'0 1 250px', marginRight:16,overflow:'hidden'}}>
              {getLoadStatusChip(el)}
            </div>
            <Typography className={classes.listItemText} style={{flex:'0 1 250px',marginRight:16}}>
              {el.last_loaded_at?moment(el.last_loaded_at).format('llll'):'N/A'}
            </Typography>
            <Typography className={classes.listItemText} style={{flex:'0 1 220px',marginRight:16}}>
              {getStatusChip(el.last_loaded_at)}
            </Typography>
            <KTooltip title={getLoadSetting(el)}>
              <Typography className={classes.listItemText} style={{flex:'0 1 300px'}}>{getLoadSetting(el)}</Typography>
            </KTooltip>
          </div>
        ))
      }
      {
        state.platformSourcesError && 
        <Typography style={{color:theme.palette.primaryText.main,marginTop:16}}>Error occurred loading sources</Typography>
      }
      <div ref={scrollRef} style={{textAlign:'center',marginTop:8}}>
        {
          state.platformSourcesLoading &&
          <CircularProgress color='secondary'/>
        }
      </div>
    </div>
  )
}

export default withTheme()(withStyles(styles)(Platform));