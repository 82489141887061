import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import SearchListStateless from './SearchListStateless';
import { onClickResultItem,  } from '../../../utilities';
import axiosSolr from '../../../axios-solr';



const styles = theme => ({
  root: {
    //padding: '0 16px',
    width:'100%',
    paddingBottom:12,
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: 400
  },
  subText: {
    color: theme.palette.primaryText.light,
    fontSize: '0.75rem',
    marginBottom: '3rem'
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
})

function RelatedSearch(props) {
  const {
    classes,
    target,
    newSolrField,
    history,
    targetLabel,
    relatedContentData,
    setRelatedContentData,
    additionalTab
  } = props;
  
  let targetFullName = target.location.replace('://','.').split('/').join('.')

  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading]=useState(false);
  const [error, setError] = useState(false)
  const [anchor, setAnchor] = useState();
  const [listActionOpen, setListActionOpen] = useState(false);


  const getRelatedContentPayload = ({ sort, showDeleted}) => ({
    params: {
      rows:50,
      fl: "*",
      q: '*',
      fq:`object_type_srt:(REPORT OR SHEET OR DATASET OR DATA_PIPELINE  OR STORAGE OR FILE) AND ${newSolrField}:"${targetFullName}" ${showDeleted?'':'AND active_srt:YES'}`,
      'group.field':'object_type_srt',
      'group':'true',
      'group.limit':50,
      'sort':sort,
    },
  })

  const getRelatedCodePayload = ({ sort, showDeleted}) => ({
    params: {
      rows:50,
      fl: "*",
      q: '*',
      fq:`object_type_srt:(CODE) AND ${newSolrField}:"${targetFullName}" ${showDeleted?'':'AND active_srt:YES'}`,
      'group.field':'code_type_srt',
      'group':'true',
      'group.limit':50,
      'sort':sort,
    },
  })

  const loadData = async ({sort=relatedContentData?.sort||'total_usage_srt desc', showDeleted = relatedContentData?.showDeleted || false}) => {
    setLoading(true);
    setRelatedContentData();
    let data = {};
    let tabs = []
    try{
      await axiosSolr
        .get(
          '/solr/search/select',
          getRelatedContentPayload({sort,showDeleted})
        )
        .then(response=>{
          if(response.data.grouped && response.data.grouped.object_type_srt && response.data.grouped.object_type_srt.groups ){
            let groups = response.data.grouped.object_type_srt.groups;
            groups.forEach(el=>{
              tabs.push(el.groupValue);
              data[el.groupValue] = el.doclist;
            })
          }
        })

      await axiosSolr
        .get(
          '/solr/search/select',
          getRelatedCodePayload({sort,showDeleted})
        )
        .then(response=>{
          if(response.data.grouped && response.data.grouped.code_type_srt && response.data.grouped.code_type_srt.groups ){
            let groups = response.data.grouped.code_type_srt.groups;
            groups.forEach(el=>{
              tabs.push(el.groupValue);
              data[el.groupValue] = el.doclist;
            })
          }
        })

      setRelatedContentData({
        data,
        tabs,
        sort,
        showDeleted
      })
      
      setLoading(false)
    }catch(error){
      console.log(error);
      setError(true);
      setLoading(false)
    }

  }

  useEffect(()=>{
    if(relatedContentData)return;
    loadData({});
  // eslint-disable-next-line
  },[])
  
  const handleSeeMore = () => {
    let presetFilter;
    if(targetLabel==='column')presetFilter = `columns_full_name_msrt:${targetFullName} AND source_id_srt:${target.source_id}`;
    if(targetLabel==='table')presetFilter = `tables_full_name_msrt:${targetFullName} AND source_id_srt:${target.source_id}`;
    history.push(`/basic_search?query=*&presetFilter=${presetFilter}&object=${relatedContentData.tabs[activeTab]}`)
  }

  const onListActionClick = event => {
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}

  const onClickShowDeleted = deleted => {
    loadData({showDeleted:deleted})
    setListActionOpen(false);
  }
  
  if (loading) return (
    <div style={{ textAlign: 'center', marginTop: '20%' }}>
      <CircularProgress color="secondary" />
      <Typography className={classes.normalText}>Loading</Typography>
    </div>
  );

  if (error || !relatedContentData)return <Typography className={classes.normalText}>Failed to load related content</Typography>

  if (relatedContentData && relatedContentData.tabs.length === 0 && !additionalTab) {
    return (
      <Typography className={classes.normalText}>No related items found</Typography>
    )
  }
  return (
    <div className={classes.root}>
      <SearchListStateless
        data={relatedContentData}
        onClickItem={onClickResultItem}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        history={history}
        showSeeMore={true}
        targetLabel={targetLabel}
        handleSeeMore={() => handleSeeMore()}
        additionalTab={additionalTab}
        sort={relatedContentData?.sort||'total_usage_srt desc'}
        setSort={(value)=>{
          loadData({sort:value})
        }}
        onListActionClick={onListActionClick}
        anchor={anchor}
        listActionOpen={listActionOpen}
        setListActionOpen={setListActionOpen}
        showDeleted={relatedContentData?.showDeleted || false}
        onClickShowDeleted={onClickShowDeleted}
      />
    </div>
  )
}

RelatedSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  solrField: PropTypes.string.isRequired,
  relatedContentData: PropTypes.object,
  setRelatedContentData: PropTypes.func
}

export default withStyles(styles)(RelatedSearch);